import { useAuth } from '@agentnet/auth';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import { postSignature, putSignature } from 'api/admin/signature-api';
import { content } from 'features/serviceorder/constants';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import RadioGroup from 'ui-kit/components/radios/RadioGroup';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import { addSignatureMsg, agreementMsg, cautionText, uploadSignatureMsg } from '../constants';
import SignatureUploader from './SignatureUploder';

interface EsignatureProps {
  isDrawerOpen: boolean;
  closeDrawer: any;
  sliderSignatureCtx: any;
  saveSuccess?: any;
}

const AddSignatureSliderFC: React.FC<EsignatureProps> = ({
  isDrawerOpen,
  closeDrawer,
  sliderSignatureCtx,
  saveSuccess,
}: EsignatureProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        padding: '0 0 24px 0',
      },
      sigContent: {
        border: '1px solid #D9E0E5',
        padding: 20,
      },
      paintArea: { borderBottom: '1px solid #D9E0E5' },
      clrBtn: {
        padding: '24px 0 0 24px',
        textAlign: 'end',
      },
      checkBoxStyle: {
        marginLeft: '-20px',
        marginBottom: '10px',
        display: 'flex',
        marginTop: theme.spacing(2),
        paddingLeft: '24px',
      },
      buttonStyle: { display: 'inline-box' },
      radioButton: {
        paddingBottom: '20px',
      },
      baseButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'static',
        border: `1px dashed ${theme.palette.secondary[200]}`,
        boxSizing: 'border-box',
        borderRadius: 6,
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 1,
      },
      contentSmSize: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: 'auto',
      },
      iconContainer: {
        position: 'static',
        width: 24,
        height: 22,
        left: 0,
        top: 0,
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: theme.spacing(0, 2, 0, 0),
      },
      uploadIcon: {
        color: theme.palette.secondary[700],
      },
      uploadIconText: {
        position: 'static',
        width: '100%',
        left: 0,
        top: 0,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 0.1,
      },
      uploadIconTextSmSize: {
        fontSize: 12,
      },
      inputLoad: {
        display: 'none',
      },
    }),
  );
  const [sign, setSign] = useState<any>();
  const [isSearching, setSearchFlag] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [checkBoxValue, setCheckBox] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();
  const [isDraw, setisDraw] = useState(true);
  const [Uploadedsignature, setUploadedsignature] = useState<any>();

  //  Handle clearing signature
  const handleClear = () => {
    sign.clear();
  };
  const handleImageUpload = (data: string) => {
    setUploadedsignature(data);
  };
  const saveSignatureRequest = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    if (sliderSignatureCtx.firmESignatureId == 0) {
      const signatureReq = {
        firmId: sliderSignatureCtx.firmId,
        signatoryId: sliderSignatureCtx.signatoryId,
        fullName: sliderSignatureCtx.fullName,
        eSignature: sliderSignatureCtx.eSignature,
        isESignatureEnable: true,
      } as any;
      return postSignature(signatureReq, token);
    } else {
      const signatureReq = {
        firmId: sliderSignatureCtx.firmId,
        signatoryId: sliderSignatureCtx.signatoryId,
        fullName: sliderSignatureCtx.fullName,
        eSignature: sliderSignatureCtx.eSignature,
        isESignatureEnable: true,
        firmESignatureId: sliderSignatureCtx.firmESignatureId,
      } as any;
      return putSignature(signatureReq, token);
    }
  };

  const {
    execute: executeSaveSignature,
    value: saveSignatureValue,
    status: saveSignatureStatus,
    errors: saveSignatureError,
  } = useAsync<any>(saveSignatureRequest, false);

  useEffect(() => {
    if (saveSignatureValue) {
      if (sliderSignatureCtx && sliderSignatureCtx.firmESignatureId == 0) {
        addSnackbarMessage({
          message: 'Signature Added Successfully',
          type: 'success',
        });
      } else {
        addSnackbarMessage({
          message: 'Signature Updated Successfully',
          type: 'success',
        });
      }
    }
  }, [saveSignatureValue]);

  useEffect(() => {
    setDisplayError(saveSignatureStatus === 'error');
  }, [saveSignatureStatus]);

  useEffect(() => {
    const errorMessages = saveSignatureError?.length ? saveSignatureError : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (sliderSignatureCtx) {
      sliderSignatureCtx.isESignatureEnable ? setCheckBox(true) : setCheckBox(false);
    }
  }, [sliderSignatureCtx]);

  useEffect(() => {
    if (sliderSignatureCtx) {
      if (checkBoxValue && sliderSignatureCtx.isESignatureEnable) {
        setCheckBoxDisable(true);
      }
    }
  }, [sliderSignatureCtx, checkBoxValue]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (saveSignatureStatus === 'success') {
      closeDrawer();
      saveSuccess(true);
      setSearchFlag(false);
    }
  });
  const radioOption = [
    { name: 'Draw', value: 'Draw', dataTestId: 'Draw', label: 'Draw' },
    { name: 'Upload', value: 'Upload', dataTestId: 'esigUpload', label: 'Upload' },
  ];

  const selectedRadioOption = radioOption?.find((option) => option.value === (isDraw ? 'Draw' : 'Upload'));

  const saveSignature = () => {
    // setBinarySign(sign.getTrimmedCanvas().toDataURL('image/jpeg'));
    if (isDraw && !sign.isEmpty()) {
      //Add padding to original canvas
      const originalCanvas = sign.getCanvas();
      const padding = 70; // Space to add on top and bottom
      const newCanvas = document.createElement('canvas');
      newCanvas.width = originalCanvas.width;
      newCanvas.height = originalCanvas.height + 2 * padding;

      const newContext = newCanvas.getContext('2d');
      if (newContext != null) {
        newContext.fillStyle = '#fff'; // Optional: Set background color if needed
        newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
        newContext.drawImage(originalCanvas, 0, padding);
      }

      // Save data to API
      // const binSign = sign.getTrimmedCanvas().toDataURL().split(',')[1];
      //const binSign = sign.getSignaturePad().toDataURL().split(',')[1];
      const binSign = newCanvas.toDataURL().split(',')[1];
      // update Binary Signature in the array
      sliderSignatureCtx.eSignature = binSign;
      executeSaveSignature().then();
    } else if (!isDraw && Uploadedsignature) {
      sliderSignatureCtx.eSignature = Uploadedsignature.split(',')[1];
      executeSaveSignature().then();
    }
  };
  return (
    <>
      <div className="paper-layout contained">
        <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
      </div>
      <FormDrawerComponent
        title={
          sliderSignatureCtx && sliderSignatureCtx.firmESignatureId == 0 ? 'Add New eSignature' : 'Edit eSignature'
        }
        open={isDrawerOpen}
        primaryActionLabel="Save"
        onPrimaryAction={saveSignature}
        disabled={!checkBoxValue}
        onDismissAction={() => closeDrawer()}
        width={770}
      >
        <>
          <Grid container>
            <Grid className={classes.radioButton} sm={12}>
              <RadioGroup
                options={radioOption}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // handleUpdateMyReportsRequest('reportFormat', e.target.value);
                  setisDraw(e.target.value === 'Draw');
                }}
                selected={selectedRadioOption}
                label=""
                data-testid="MyReportFormat"
              />
            </Grid>
            <Grid className={classes.content}>
              {isDraw ? (
                <Typography color="textSecondary">{addSignatureMsg}</Typography>
              ) : (
                <Typography color="textPrimary">{uploadSignatureMsg}</Typography>
              )}

              {/* <Typography color={isDraw ? 'textSecondary' : 'textPrimary'}>
                {isDraw ? addSignatureMsg : uploadSignatureMsg}
              </Typography> */}
            </Grid>
            {isDraw ? (
              <Grid className={classes.sigContent}>
                <Grid>
                  <Grid item className={classes.paintArea} sm={12}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 680, height: 185, className: 'sigCanvas' }}
                      ref={(data: any) => setSign(data)}
                    />
                  </Grid>
                  <Grid item className={classes.clrBtn} sm={12}>
                    <AgentNetButton
                      onClick={handleClear}
                      variant="text"
                      data-testid="clearSignature"
                      color="primary"
                      size="small"
                    >
                      Clear
                    </AgentNetButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <SignatureUploader
                  maxSize={0}
                  onImageUpload={handleImageUpload}
                  acceptTypes={{
                    'image/jpg': ['.JPG'],
                    'image/png': ['.PNG'],
                    'image/gif': ['.GIF'],
                  }}
                ></SignatureUploader>
              </Grid>
            )}
          </Grid>
          <Grid>
            <Grid className={classes.checkBoxStyle}>
              <AgentNetCheckBox
                label={agreementMsg}
                name={'IsAggreed'}
                value={checkBoxValue}
                checkHandler={(val: any) => {
                  setCheckBox(val);
                }}
                required={true}
                disabled={checkBoxDisable}
              />
              <Grid className={classes.buttonStyle}>
                <AgentNetButton
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => setShowMore(!showMore)}
                  endIcon={showMore ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                >
                  {showMore ? 'Show less' : 'Show more'}
                </AgentNetButton>
                <br />
              </Grid>
            </Grid>
            {showMore ? (
              <Typography variant="body1">
                {/* <br /> */}
                <strong>{content}</strong>
                <br />
                {cautionText}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </>
      </FormDrawerComponent>
    </>
  );
};

export default AddSignatureSliderFC;
