import { useAuth } from '@agentnet/auth';
import { getPayInvoiceData, getRateAndFeeData } from 'api/reports/api';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import useAsync from 'hooks/useAsync';
import { useContext, useEffect, useState } from 'react';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { pay, review } from './reportsListConfig';

const useRatesAndFee = (firmId: string | number, tabName: string = review) => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { setFileData } = fileDataCtx;
  const [drawerData, setDrawerData] = useState<any>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();

  const getRatesFees = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getRateAndFeeData(token, drawerData?.fileId ?? '', 'rates-fees');
  };

  const {
    execute: rateAndFeeExecute,
    status: rateAndFeeStatus,
    value: rateAndFeeValue,
    errors: rateAndFeeErrors,
  } = useAsync<any>(getRatesFees, false);

  const getCalcCriteriaData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getRateAndFeeData(token, drawerData?.fileId ?? '', 'calc-criteria');
  };

  const {
    execute: calcCriteriaExecute,
    status: calcCriteriaStatus,
    value: calcCriteriaValue,
    errors: calcCriteriaErrors,
  } = useAsync<{ result: any; errorCode?: string | number; message?: string }>(getCalcCriteriaData, false);

  const getPayAndInvoiceData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const ignoreOrderNumbers = ['Order in Process'];
    const payload = {
      ...(drawerData?.orderDetails?.orderNumber && !ignoreOrderNumbers.includes(drawerData?.orderDetails?.orderNumber)
        ? {
            orders: [
              {
                orderNumber: drawerData?.orderDetails?.orderNumber,
                invoiceType: drawerData?.orderDetails?.invoiceType ?? 'Remittance',
              },
            ],
          }
        : {}),
      ...(drawerData?.orderDetails?.fileOrderIds?.length > 0
        ? { fileOrderIds: [...drawerData.orderDetails.fileOrderIds] }
        : {}),
      ...(firmId ? { firmId: firmId } : {}),
    };
    return await getPayInvoiceData(payload, token);
  };

  const {
    execute: payAndInvoiceExecute,
    status: payAndInvoiceStatus,
    value: payAndInvoiceValue,
    errors: payAndInvoiceErrors,
  } = useAsync<{ result: any; errorCode?: string | number; message?: string }>(getPayAndInvoiceData, false);

  useEffect(() => {
    if (drawerData?.orderDetails) {
      payAndInvoiceExecute().then();
    }
  }, [drawerData?.orderDetails]);

  useEffect(() => {
    const isError = rateAndFeeStatus === 'error' || calcCriteriaStatus === 'error' || payAndInvoiceStatus === 'error';
    setDisplayError(isError);
    if (isError) {
      setDrawerData((prevData: any) => ({
        ...prevData,
        ...(rateAndFeeStatus === 'error' ? { rateAndFeeData: [], rateAndFeeStatus: rateAndFeeStatus } : {}),
        ...(calcCriteriaStatus === 'error' ? { calcCriteriaData: [], calcCriteriaStatus: calcCriteriaStatus } : {}),
        ...(payAndInvoiceStatus === 'error' ? { payAndInvoiceData: [], payAndInvoiceStatus: payAndInvoiceStatus } : {}),
      }));
    }
  }, [rateAndFeeStatus, calcCriteriaStatus, payAndInvoiceStatus]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(rateAndFeeErrors ?? []),
        ...(calcCriteriaErrors ?? []),
        ...(payAndInvoiceErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    if (rateAndFeeValue) {
      if (rateAndFeeStatus)
        setFileData &&
          setFileData((prevFileData: any) => ({
            ...prevFileData,
            serviceStatus: {
              ...prevFileData.serviceStatus,
              isPricingValid: rateAndFeeValue?.isPricingValid,
            },
          }));
      setDrawerData((prevData: any) => ({
        ...prevData,
        rateAndFeeStatus: rateAndFeeStatus,
        rateAndFeeData: rateAndFeeValue,
        noRateAndFeeExists:
          (rateAndFeeValue?.rateFeeLineItems?.length ?? 0) + (rateAndFeeValue?.remittedLineItems?.length ?? 0) === 0,
      }));
    }
  }, [rateAndFeeValue, rateAndFeeStatus]);

  useEffect(() => {
    if (calcCriteriaValue) {
      setDrawerData((prevData: any) => ({
        ...prevData,
        calcCriteriaStatus: calcCriteriaStatus,
        calcCriteriaData: calcCriteriaValue,
      }));
    }
  }, [calcCriteriaValue, calcCriteriaStatus]);

  useEffect(() => {
    if (payAndInvoiceValue) {
      setDrawerData((prevData: any) => ({
        ...prevData,
        payAndInvoiceStatus: payAndInvoiceStatus,
        payAndInvoiceData: payAndInvoiceValue,
      }));
    }
  }, [payAndInvoiceValue, payAndInvoiceStatus]);

  useEffect(() => {
    if (drawerData?.fileId && tabName !== pay) {
      rateAndFeeExecute().then();
      calcCriteriaExecute().then();
    }
  }, [drawerData?.fileId, tabName]);

  const handleDrawerOpen = (event: any, type: string) => {
    const data =
      type === 'pay'
        ? {
            orderDetails: event.data,
            payAndInvoiceStatus: 'loading',
          }
        : {
            name: `${event.data.fileNumber}`,
            eventData: event.data,
            fileId: event.data.fileId,
            tab: type === 'review' ? 1 : 0,
            rateAndFeeStatus: 'loading',
            calcCriteriaStatus: 'loading',
            resource: type,
          };

    setDrawerData({ ...data });
    setOpenDrawer(true);
  };

  return {
    drawerData,
    setDrawerData,
    openDrawer,
    setOpenDrawer,
    handleDrawerOpen,
    rateAndFeeExecute,
  };
};

export default useRatesAndFee;
