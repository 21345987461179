import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { HistoryOutlined } from '@material-ui/icons';
import { ReactNode, useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.main,
      fontSize: pxToRem(13),

      '&:hover': {
        color: theme.palette.primary.main,

        '& .button-text': {
          display: 'block',
        },

        '& .timer': {
          display: 'none',
        },
      },

      '& .button-text': {
        display: 'none',
      },
    },

    clock: {
      marginLeft: theme.spacing(1),
    },
  }),
);

type TimeTrackerProps = {
  children: ReactNode;
  isUpdated: boolean;
  onClick: () => void;
};

const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    return 'a few seconds ago';
  } else if (seconds < 120) {
    return 'a minute ago';
  } else if (seconds < 3600) {
    return `${Math.floor(seconds / 60)} minutes ago`;
  }
  return `${Math.floor(seconds / 3600)} hours ago`;
};

export function TimeTracker({ children, isUpdated = false, onClick }: TimeTrackerProps) {
  const classes = useStyles();
  const [timeElapsed, setTimeElapsed] = useState<string>('');
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isUpdated) {
      const startTime: number = Date.now();
      intervalId.current = setInterval(() => {
        setTimeElapsed(formatTime(Math.floor((Date.now() - startTime) / 1000)));
      }, 1000);
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        setTimeElapsed(formatTime(0));
      }
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [isUpdated]);

  return (
    <>
      {timeElapsed !== '' ? (
        <Button className={classes.root} color="primary" variant="text" onClick={onClick}>
          <div className="timer">{timeElapsed}</div>
          <div className="button-text">{children}</div>
          <HistoryOutlined className={classes.clock} />
        </Button>
      ) : null}
    </>
  );
}
