import { Box, Grid, Typography } from '@material-ui/core';
import { fileInfoType, propertyType } from 'api/file/interfaces/get-file';
import { CoveredParties } from 'features/cpl/cpl-list/constants';
import { CreateCPLType, partyMaxLength, partyRequirement } from 'features/cpl/types';
import DomesticAddressField from 'features/files/file-create/DomesticAddress';
import { IGetBuyerSellerResponse } from 'features/files/parties/interfaces/CreateBuyerSellerRequest';
import {
  IPersonalIdentificationContactFields,
  IPersonalIdentificationTaxContactFields,
} from 'features/files/parties/interfaces/PersonalInformation';
import React, { useEffect, useState } from 'react';
import { AddressFields } from 'ui-kit/form/interfaces/AddressModal';
import { AgentNetDropdownSelector } from 'ui-kit/inputs';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import { buyerSchema, domesticAddressSchemaRequired, sellerSchema } from 'utilities/validation/schemas/cpl-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

interface PartyInformationNonLenderProps {
  index?: number;
  disabled: boolean;
  buyerAddressOptions?: Array<DropdownItem>;
  sellerAddressOptions?: Array<DropdownItem>;
  isRequired?: boolean;
  sellerRequirements: partyRequirement;
  buyerRequirements: partyRequirement;
  buyerNames: string;
  sellerNames: string;
  fileData: fileInfoType | null | undefined;
  setCplFormData: (data: IBuyerSellerFormData, key: string) => void;
  initialBuyerNames?: string;
  initialSellerNames?: string;
  initialCustomBuyerAddress?: AddressFields;
  initialCustomSellerAddress?: AddressFields;
  showValidation: boolean;
  validationErrors: FieldValidationError[];
  setBuyerAddressValid: (val: boolean) => void;
  setSellerAddressValid: (val: boolean) => void;
  sellerFieldsType?: partyMaxLength;
  buyerFieldsType?: partyMaxLength;
  getFilteredErrors: (errs: FieldValidationError[], fields: string[]) => void;
  setEditedPartyName?: (val: string) => void;
  cplData: CreateCPLType | null;
}
interface DropdownItem {
  name: string;
  value: string;
}
export interface IBuyerSellerFormData extends IGetBuyerSellerResponse {
  stateOrProvince?: string;
  postalCode?: string;
  address2?: string;
  city?: string;
  address1?: string;
  addressSelection?: number;
  names?: string;
  primary?: {
    entity?: IPersonalIdentificationTaxContactFields;
    individual?: IPersonalIdentificationContactFields;
    trustEstate?: IPersonalIdentificationTaxContactFields;
    couple?: {
      maritalStatus?: string;
      spouse1?: IPersonalIdentificationContactFields;
      spouse2?: IPersonalIdentificationContactFields;
    };
  };
}

const PartyInformationNonLender: React.FC<PartyInformationNonLenderProps> = ({
  index,
  disabled = false,
  buyerAddressOptions = [],
  buyerRequirements,
  buyerNames,
  sellerRequirements,
  sellerNames,
  sellerAddressOptions = [],
  fileData,
  setCplFormData,
  initialBuyerNames,
  initialSellerNames,
  initialCustomBuyerAddress,
  initialCustomSellerAddress,
  showValidation,
  validationErrors,
  setBuyerAddressValid,
  setSellerAddressValid,
  sellerFieldsType,
  buyerFieldsType,
  getFilteredErrors,
  setEditedPartyName,
  cplData,
}: PartyInformationNonLenderProps) => {
  const [buyerId, setBuyerId] = useState<string>(buyerAddressOptions?.[0]?.value);
  const [customBuyerAddress, setCustomBuyerAddress] = useState<AddressFields>({
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    stateOrProvince: '',
  });
  const [buyerName, setBuyerName] = useState<string>(buyerNames);
  const [sellerId, setSellerId] = useState<string>(sellerAddressOptions?.[0]?.value);
  const [customSellerAddress, setCustomSellerAddress] = useState<AddressFields>({
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    stateOrProvince: '',
  });
  const [sellerName, setSellerName] = useState<string>(sellerNames);
  const normalizeAddress = (property: propertyType | undefined): AddressFields => {
    return property
      ? {
          address1: property?.address1,
          address2: property?.address2,
          city: property?.city,
          postalCode: property?.postalCode,
          stateOrProvince: property?.stateOrProvince || property?.state,
        }
      : {
          address1: '',
          address2: '',
          city: '',
          postalCode: '',
          stateOrProvince: '',
        };
  };
  const primePropAddress = normalizeAddress(fileData?.properties?.[0]);

  useEffect(() => {
    setBuyerName(buyerNames);
    setSellerName(sellerNames);
    setBuyerId(buyerAddressOptions?.[0]?.value);
    setSellerId(sellerAddressOptions?.[0]?.value);
  }, [buyerNames, sellerNames]);

  useEffect(() => {
    if (initialBuyerNames) setBuyerName(initialBuyerNames);
    if (initialSellerNames) setSellerName(initialSellerNames);
    if (Object.keys(initialCustomBuyerAddress ?? {}).length) {
      setCustomBuyerAddress(initialCustomBuyerAddress ?? {});
      setBuyerId('Custom');
    }
    if (Object.keys(initialCustomSellerAddress ?? {}).length) {
      setCustomSellerAddress(initialCustomSellerAddress ?? {});
      setSellerId('Custom');
    }
  }, [initialBuyerNames, initialSellerNames, initialCustomBuyerAddress, initialCustomSellerAddress]);

  useEffect(() => {
    const buyerAddress =
      buyerId === 'Custom'
        ? customBuyerAddress
        : normalizeAddress(
            /^property_/.test(buyerId)
              ? fileData?.properties?.[0]
              : fileData?.buyers?.find((buyer) => buyer.partyId === buyerId)?.address,
          );
    setCplFormData({ ...buyerAddress, names: buyerName }, 'buyer');
    doValidate({ buyerName }, buyerSchema(buyerFieldsType?.name, buyerRequirements.name)).then(
      (errs: FieldValidationError[]) => {
        getFilteredErrors(errs, ['buyerName']);
      },
    );
    if (buyerId !== 'Custom') setBuyerAddressValid(true);
  }, [buyerName, buyerId, customBuyerAddress, buyerRequirements.name]);

  useEffect(() => {
    const sellerAddress =
      sellerId === 'Custom'
        ? customSellerAddress
        : normalizeAddress(
            /^property_/.test(sellerId)
              ? fileData?.properties?.[0]
              : fileData?.sellers?.find((seller) => seller.partyId === sellerId)?.address,
          );
    setCplFormData({ ...sellerAddress, names: sellerName }, 'seller');
    doValidate({ sellerName }, sellerSchema(sellerFieldsType?.name, sellerRequirements.name)).then(
      (errs: FieldValidationError[]) => {
        getFilteredErrors(errs, ['sellerName']);
      },
    );
    if (sellerId !== 'Custom') setSellerAddressValid(true);
  }, [sellerName, sellerId, customSellerAddress, sellerRequirements.name]);

  return (
    <>
      {buyerRequirements.displayName ? (
        <>
          <Box my={3}>
            <Typography variant="h3">Buyer/Borrower</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <AgentNetTextInput
                name="buyerName"
                showValidation={showValidation}
                errs={validationErrors}
                variant="outlined"
                fullWidth
                label="Borrower Names"
                data-testid={index ? `CPL${index}BuyerName` : 'CPLBuyerName'}
                value={buyerName}
                required={buyerRequirements.name}
                onChange={(e) => {
                  setBuyerName(e.target.value);
                  if (
                    cplData?.cpl?.coveredParty === CoveredParties.borrrower ||
                    cplData?.cpl?.coveredParty === CoveredParties.buyer ||
                    cplData?.cpl?.coveredParty === CoveredParties.buyerBorrower
                  )
                    setEditedPartyName && setEditedPartyName(e.target.value);
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item sm={6}>
              {buyerRequirements.displayAddress ? (
                <AgentNetDropdownSelector
                  name="buyerAddress"
                  required={buyerRequirements.address}
                  label="Address"
                  data-testid={index ? `CPL${index}BuyerAddressList` : 'CPLBuyerAddressList'}
                  options={buyerAddressOptions}
                  value={buyerId}
                  id="address"
                  dropdowntype="outlined"
                  menuOption={(value) => {
                    setBuyerId(value);
                    return false;
                  }}
                  disabled={disabled}
                />
              ) : null}
            </Grid>
            {buyerRequirements.displayAddress && buyerId === 'Custom' ? (
              <Grid item xs={12}>
                <DomesticAddressField
                  qaAttributePrefix={index ? `CPL${index}Buyer` : 'CPLBuyer'}
                  showAllValidation={buyerRequirements.address && showValidation}
                  schema={domesticAddressSchemaRequired(buyerFieldsType?.address, buyerRequirements.address)}
                  displayRequiredAddressFields={buyerRequirements.address}
                  value={customBuyerAddress}
                  onChange={(val) => setCustomBuyerAddress(val)}
                  disabled={disabled}
                  setIsValid={setBuyerAddressValid}
                  isOutsideJurisdiction={true}
                />
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}
      {sellerRequirements.displayName ? (
        <>
          <Box my={3}>
            <Typography variant="h3">Seller/Owner</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <AgentNetTextInput
                data-testid={index ? `CPL${index}SellerName` : 'CPLSellerName'}
                name="sellerName"
                showValidation={showValidation}
                errs={validationErrors}
                variant="outlined"
                fullWidth
                label="Seller Names"
                value={sellerName}
                required={sellerRequirements.name}
                onChange={(e) => {
                  setSellerName(e.target.value);
                  if (cplData?.cpl?.coveredParty === CoveredParties.seller)
                    setEditedPartyName && setEditedPartyName(e.target.value);
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item sm={6}>
              {sellerRequirements.displayAddress ? (
                <AgentNetDropdownSelector
                  name="sellerAddress"
                  required={sellerRequirements.address}
                  label="Address"
                  data-testid={index ? `CPL${index}SellerAddressList` : 'CPLSellerAddressList'}
                  options={sellerAddressOptions}
                  value={sellerId}
                  id="address"
                  dropdowntype="outlined"
                  menuOption={(value) => {
                    setSellerId(value);
                    return false;
                  }}
                  disabled={disabled}
                />
              ) : null}
            </Grid>
            {sellerRequirements.displayAddress && sellerId === 'Custom' ? (
              <Grid item xs={12}>
                <DomesticAddressField
                  qaAttributePrefix={index ? `CPL${index}Seller` : 'CPLSeller'}
                  displayRequiredAddressFields={sellerRequirements.address}
                  value={customSellerAddress}
                  onChange={(val) => setCustomSellerAddress(val)}
                  disabled={disabled}
                  showAllValidation={sellerRequirements.address && showValidation}
                  schema={domesticAddressSchemaRequired(sellerFieldsType?.address, sellerRequirements.address)}
                  setIsValid={setSellerAddressValid}
                  isOutsideJurisdiction={true}
                />
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default PartyInformationNonLender;
