import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CSSProperties, FC } from 'react';

interface DetailRowProps {
  label: string;
  value: string | number;
  isLast?: boolean;
  isEnd?: boolean;
  isAmount?: boolean;
  valueStyle?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  detail: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  detailLast: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  detailEnd: {
    marginBottom: 0,
  },
  item: {
    width: 176,
    whiteSpace: 'pre-wrap',
    textTransform: 'capitalize',
  },
  info: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(2),
  },
  infoAmount: {
    textAlign: 'right',
  },
}));

export const DetailRow: FC<DetailRowProps> = ({
  label,
  value,
  isLast,
  isEnd,
  isAmount,
  valueStyle,
}: DetailRowProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.detail, isLast && classes.detailLast, isEnd && classes.detailEnd)}>
      <Typography variant="h6" className={classes.item} color="textSecondary">
        {label}
      </Typography>
      <div className={clsx(classes.info, isAmount && classes.infoAmount)}>
        <Typography variant="body2" color="textPrimary" style={valueStyle}>
          {value}
        </Typography>
      </div>
    </div>
  );
};
