import { Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import { SaveAdditionalDocumentResponse, UnderwritingDocument, UnderwritingRequest } from './types';

import { useAuth } from '@agentnet/auth';
import { getDecryptedFileId, saveAdditionalDocuments } from 'api/underwriting-api';
import useAsync from 'hooks/useAsync';
import { FileWithPath } from 'react-dropzone';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import { Notification } from 'ui-kit/components/notification/Notification';
import Uploader from 'ui-kit/components/uploader/Uploader';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  fileId?: string;
  accountNumber?: number;
  isDrawerOpen: boolean;
  closeDrawer: any;
  updateUnderwriting: any;
}
const UnderwritingDocumentsSliderFC: React.FC<UnderwritingProps> = ({
  uwr,
  fileId,
  accountNumber,
  isDrawerOpen,
  closeDrawer,
  updateUnderwriting,
}: UnderwritingProps) => {
  const auth = useAuth();

  const [documents, setDocuments] = useState<UnderwritingDocument[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [documentErrors, setDocumentErrors] = useState<any[]>([]);

  const fetchDecryptedFileId = async (): Promise<number> => {
    const token = await auth.getAccessToken();
    return getDecryptedFileId(fileId ?? '', token);
  };

  const postAdditionalDocuments = async () => {
    const token = await auth.getAccessToken();
    return saveAdditionalDocuments(
      decryptedFileId ?? 0,
      uwr?.RequestInformation.RequestId ?? 0,
      accountNumber ?? 0,
      documents,
      token,
    );
  };

  const { execute: executeFetchDecryptedFileId, value: decryptedFileId } = useAsync<number>(
    fetchDecryptedFileId,
    false,
  );

  const {
    execute: executePostAdditionalDocuments,
    value: postAdditionalDocumentsValue,
    status: postAdditionalDocumentsStatus,
    errors: postAdditionalDocumentsErrors,
  } = useAsync<SaveAdditionalDocumentResponse>(postAdditionalDocuments, false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mb2: { marginBottom: theme.spacing(2) },
    }),
  );

  const styles = useStyles();

  useEffect(() => {
    executeFetchDecryptedFileId().then();
    setIsDisabled(
      uwr?.RequestInformation.RequestStatus === '' ||
        uwr?.RequestInformation.RequestStatus === 'Prep' ||
        uwr?.RequestInformation.RequestStatus === 'In Progress' ||
        uwr?.RequestInformation.RequestStatus === 'Submitted'
        ? false
        : true,
    );
  }, []);

  useEffect(() => {
    if (isSubmitted && postAdditionalDocumentsValue && (postAdditionalDocumentsErrors?.length ?? 0) === 0) {
      setIsSubmitted(false);
      updateUnderwriting('Documents', postAdditionalDocumentsValue.UnderwritingDocuments);
      closeDrawer();
      setDocuments([]);
    }
  }, [postAdditionalDocumentsValue]);

  useEffect(() => {
    if (postAdditionalDocumentsErrors?.length > 0) {
      setIsSubmitted(false);
      setDocumentErrors(postAdditionalDocumentsErrors);
    }
  }, [postAdditionalDocumentsErrors]);

  useEffect(() => {
    if (isDrawerOpen == false) {
      setDocumentErrors([]);
      setIsSubmitted(false);
      setDocuments([]);
    }
  }, [isDrawerOpen]);

  // const randomId = () => (Math.random() + 1).toString(36).substring(7);

  async function getAsByteArray(file: Blob) {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  }

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const handleDocuments: any = (uploads: FileWithPath[]) => {
    setUploadedFiles(uploads);
    setDocumentErrors([]);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      const localDocumentList: UnderwritingDocument[] = [];
      const filePathsPromises: any[] = [];
      uploadedFiles.forEach((file) => {
        const document: UnderwritingDocument = {
          Id: 0,
          Name: file.name,
          CreatedDate: new Date(),
          FileType: file.name.split('.').pop() ?? '',
          Type: 'Other',
          Size: '',
          Binary: '',
          IsUserUploaded: true,
        };

        localDocumentList.push(document);
        filePathsPromises.push(getAsByteArray(file));
      });
      Promise.all(filePathsPromises).then((filePaths) => {
        filePaths.forEach((e, i) => {
          localDocumentList[i].Binary = e;
        });
        setDocuments([...documents, ...localDocumentList]);
      });
    }
  }, [uploadedFiles]);

  const deleteDocument: any = async (file?: any) => {
    const documentList: UnderwritingDocument[] = documents ?? [];

    const filteredDocuments = documentList.filter((uwrdocument: UnderwritingDocument) => uwrdocument.Name != file.name);
    setDocuments(filteredDocuments);
    setDocumentErrors([]);
  };

  const renderErrorNotification = () => {
    return documentErrors.map((error, index) => (
      <Grid item sm={12} key={`AdditionalDocumentNotification_${index}`} className={styles.mb2}>
        <Notification inline severity="warning" data-testid={'additionalDocumentError' + index}>
          {error}
        </Notification>
      </Grid>
    ));
  };

  const renderDocumentsUploader = () => {
    return (
      <Grid container>
        <Grid item sm={12}>
          <Uploader
            handleFiles={(files) => handleDocuments(files)}
            onDeleteItem={deleteDocument}
            listTitle="Uploaded Document(s)"
            hideInfo
            maxSize={45}
            acceptTypes={{
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/x-excel': ['.xls'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.ms-powerpoint': ['.ppt'],
              'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
              'application/rtf': ['.rtf'],
              'application/txt': ['.txt'],
              'application/msg': ['.msg'],
              'image/jpg': ['.JPG'],
              'application/gif': ['.GIF'],
              'image/tif': ['.tif'],
              'image/tiff': ['.tiff'],
              'application/png': ['.png'],
              'audio/mpeg': ['.mp3'],
              'video/mp4': ['.mp4'],
              'audio/x-ms-wma': ['.wma'],
            }}
            drawerMode
            data-testid={'UnderwritingDocumentUpload'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormDrawerComponent
      title={'Add Document(S)'}
      open={isDrawerOpen}
      primaryActionProps={{
        loading: isSubmitted,
        disabled: (isSubmitted && postAdditionalDocumentsErrors.length == 0) || documents.length == 0 ? true : false,
        'data-testid': 'AddUwrDocument',
        size: 'small',
      }}
      dismissActionProps={{
        disabled: false,
        'data-testid': 'AddUwrDocumentCancel',
        size: 'small',
      }}
      crossQAPrefix="AddUwrDocument"
      primaryActionLabel="Save Update"
      onPrimaryAction={() => {
        if (documents.length > 0) {
          setIsSubmitted(true);
          executePostAdditionalDocuments().then();
        }
      }}
      onDismissAction={() => closeDrawer()}
      testId="scrollable-form-drawer"
      width={720}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <AgentNetDivider title={'Supporting Documents'} typoVariant="h2" disableButton={false} disablePaddingX />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid container>{renderErrorNotification()}</Grid>
          {renderDocumentsUploader()}
        </Grid>
      </Container>
    </FormDrawerComponent>
  );
};

export default UnderwritingDocumentsSliderFC;
