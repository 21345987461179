import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useOrderInfo } from 'hooks/OrderInfoContext';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { DetailRow } from './DetailRow';
import { numberValueFormatter } from './fileListConfig';
import { orderType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  orderDetailsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
  },
  subtitle: {
    whiteSpace: 'nowrap',
    width: 93,
  },
}));

const OrderInfoPanel: React.FC = () => {
  const location = useLocation<{ order: orderType }>();
  const orderData = location.state?.order;
  const { selectedFilesAmount } = useOrderInfo();
  const classes = useStyles();

  const noInfo = '-';
  const calculatedNet = selectedFilesAmount?.calculatedNet || 0;
  const reportedNet = orderData?.reportedAmount || 0;
  const netDifference = parseFloat((calculatedNet - reportedNet).toFixed(2));
  const netDifferenceColor = netDifference !== 0 ? 'red' : 'green';

  return (
    <div>
      <Grid container className={classes.orderDetailsContainer}>
        <Grid item sm={12}>
          {/* Order Info Title */}
          <div className={classes.title}>
            <Typography variant="h4" className={classes.subtitle} color="textSecondary">
              Order Info
            </Typography>
          </div>

          <DetailRow label="Firm Name:" value={orderData?.firm?.firmName || noInfo} />
          <DetailRow
            label="Order Date:"
            value={orderData?.orderDate ? moment(orderData.orderDate).format('MM/DD/YYYY') : noInfo}
          />
          <DetailRow label="Lockbox Number:" value={orderData?.lockboxNumber?.name || noInfo} />
          <DetailRow label="Batch/Ticket Reference No:" value={orderData?.batchNumber || noInfo} />
          <DetailRow label="No. of Checks:" value={orderData?.numberOfChecks || 0} isLast />

          {/* Summary Title */}
          <div className={classes.title}>
            <Typography variant="h4" className={classes.subtitle} color="textSecondary">
              Summary
            </Typography>
          </div>

          <DetailRow
            label="Calculated Gross:"
            value={numberValueFormatter(selectedFilesAmount?.calculatedGross || 0, true) || noInfo}
            isAmount
          />
          <DetailRow
            label="Reported Net:"
            value={numberValueFormatter(orderData?.reportedAmount || 0, true) || noInfo}
            isAmount
          />
          <DetailRow label="Calculated Net:" value={numberValueFormatter(calculatedNet, true) || noInfo} isAmount />
          <DetailRow
            label="Net Difference:"
            value={numberValueFormatter(netDifference, true) || noInfo}
            isAmount
            isEnd
            valueStyle={{ color: netDifferenceColor }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderInfoPanel;
