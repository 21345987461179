import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Tooltip, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ErrorSection from '../../../features/files/file-create/ErrorSection';
import { FieldValidationError } from '../../../utilities/validation/validation';
import './RadioGroup.scss';

export type RadioProps = {
  label: string;
  value: string;
  dataTestId?: string;
  disabled?: boolean;
  tooltipText?: string;
};

export type RadioGroupProps = {
  options: Array<RadioProps>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  selected?: any;
  row?: boolean;
  border?: boolean;
  compact?: boolean;
  errs?: FieldValidationError[];
  className?: string;
  name?: string;
  required?: boolean;
  showValidation?: boolean;
  removeMarginTop?: boolean;
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: pxToRem(13),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.8),
  },
  required: {
    color: '#AC1209',
    fontSize: pxToRem(14),
    fontWeight: 600,
  },
  removeMarginTop: {
    marginTop: '-12px',
  },
}));

export default function RadioGroup({
  options,
  onChange,
  label,
  selected,
  disabled = false,
  row = true,
  border = true,
  compact,
  errs,
  className,
  name,
  required,
  showValidation,
  removeMarginTop,
  ...props
}: RadioGroupProps): JSX.Element {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(selected ? selected.value : '');
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setChecked(e.target.value);
  };

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  return (
    <FormControl component="fieldset" className={clsx({ [classes.removeMarginTop]: removeMarginTop })}>
      {label && (
        <FormLabel component="legend">
          <Typography variant="body1" className={classes.label}>
            {label} {required && <span className={classes.required}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <RGroup
        className="radio-group-wrap"
        row={row}
        aria-label="position"
        name="position"
        defaultValue="top"
        value={checked}
        onChange={handleChange}
      >
        {options.map((option: RadioProps) => (
          <Tooltip key={'tooltip' + option.value} title={option.tooltipText ?? ''}>
            <FormControlLabel
              className={`radio-group-button ${option.value === checked ? 'active' : ''} ${
                disabled ? 'disabled' : ''
              } ${border ? 'border' : ''} ${compact ? 'compact' : ''} ${className && className}`}
              data-testid={option.dataTestId}
              key={option.value}
              value={option.value}
              disabled={disabled || option.disabled}
              control={<Radio color="primary" disableRipple disableFocusRipple />}
              label={
                <Typography className="radio-group-button-label" variant="body1">
                  {option.label}
                </Typography>
              }
            />
          </Tooltip>
        ))}
      </RGroup>
      {errors && displayErrors && <ErrorSection errs={errs} field={name ?? ''} />}
    </FormControl>
  );
}
