import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@fluentsms/agentnet-web-components';

import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';

export interface ReportsListInterface {
  contentClassName?: string;
}

const Dashboard = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <AgentNetDivider title="Order Management" typoVariant="h1" />
      </div>
    </div>
  );
};

export default Dashboard;
