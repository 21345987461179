import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Accordion, AccordionSummary, Grid, Hidden, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import OverflowTip from '../utility/OverflowTip';

interface InvoiceProps {
  status: string;
  invoiceNumber: string;
  agentFileNumber: number;
  office: number;
  propertyAddress: string;
  buyers: string;
  date: string;
  invoicedAmount: number;
  invoiceBalance: number;
  premium: boolean;
}

interface OrderProps {
  invoices?: Array<InvoiceProps>;
  nested?: boolean;
  isTitleRequired?: boolean;
  modalView?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InvoiceSummaryTable: {
      width: '100%',
    },
    InvoiceSummaryTableHeader: {
      backgroundColor: '#F2F5F6',
      borderTop: `2px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontWeight: 600,
      fontSize: pxToRem(12),
      padding: theme.spacing(1.25),
    },
    tableRow: {
      boxShadow: 'none',
      width: '100%',
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    tableRowGrid: {
      alignItems: 'center',
    },
    tableRowSummary: {
      minHeight: 'auto',
      height: 40,
      paddingLeft: 0,
      paddingRight: pxToRem(12),
      '&.Mui-expanded': {
        minHeight: 'auto',
        height: 40,
        backgroundColor: theme.palette.primary.light,
      },
      '& .MuiAccordionSummary-content': {
        height: '100%',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
        height: '100%',
      },
    },
    currencyCol: {
      textAlign: 'right',
      padding: pxToRem(10, 20, 10, 10),
    },
    tableCellRight: {
      textAlign: 'right',
    },
    totalsRow: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    fileNumberCell: {
      paddingLeft: pxToRem(12),
    },
    totalsCell: {
      padding: pxToRem(10, 20, 10, 10),
    },
    weight700: {
      fontWeight: 700,
    },
    textRight: {
      textAlign: 'right',
      paddingRight: pxToRem(10),
    },
    amountDueCell: {
      textWrap: 'nowrap',
    },
    marginCol: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const ServiceOrderInvoiceSummaryTable = ({ invoices }: OrderProps) => {
  const classes = useStyles();
  const [grandTotal, setGrandTotal] = useState({
    originalAmount: 0,
    amountDue: 0,
  });

  useEffect(() => {
    setGrandTotal(calculateTotals());
  }, [invoices]);

  const calculateTotals: any = () => {
    let originalAmount = 0;
    let amountDue = 0;
    invoices &&
      invoices.forEach((prod) => {
        prod.invoicedAmount && (originalAmount += prod?.invoicedAmount);
        prod.invoiceBalance && (amountDue += prod.invoiceBalance);
      });

    return { originalAmount: originalAmount, amountDue: amountDue };
  };

  const dollarize = (str: number, icon?: boolean) => str && str.toLocaleString('en-US', { minimumFractionDigits: 2 });
  const AccordionRow = ({ data }: any) => {
    return (
      <Accordion key={data.id} className={classes.tableRow} square>
        <AccordionSummary className={classes.tableRowSummary} aria-controls="panel1c-content" id="panel1c-header">
          <Grid container key={data.id} spacing={2} className={clsx(classes.tableRowGrid, 'parentRow')}>
            <Grid item sm={4} md={2} lg={3}>
              <Typography className={classes.fileNumberCell}>
                <OverflowTip>{data.invoiceNumber}</OverflowTip>
              </Typography>
            </Grid>
            <Grid item sm={2} md={3} lg={3} className={classes.marginCol}>
              <OverflowTip>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  {data.fileNumber}
                </Typography>
              </OverflowTip>
            </Grid>
            <Grid item sm={4} md={3} lg={4} className={classes.marginCol}>
              <OverflowTip>
                <Typography variant="body2">{data.propertyAddress} </Typography>
              </OverflowTip>
            </Grid>
            <>
              <Hidden smDown>
                <Grid
                  item
                  sm={2}
                  md={2}
                  lg={1}
                  className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
                >
                  <Typography style={{ textAlign: 'center' }}>{'$' + dollarize(data.invoicedAmount)}</Typography>
                </Grid>
              </Hidden>
              <Grid
                item
                sm={2}
                md={2}
                lg={1}
                className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
              >
                <Typography style={{ textAlign: 'center' }}>{'$' + dollarize(data.invoiceBalance)}</Typography>
              </Grid>
            </>
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  };

  return (
    <>
      {invoices && invoices.length > 0 && (
        <div className={classes.InvoiceSummaryTable}>
          <Grid container className={classes.InvoiceSummaryTableHeader}>
            <Grid item sm={4} md={2} lg={3}>
              <OverflowTip>Invoice Number</OverflowTip>
            </Grid>
            <Grid item sm={2} md={3} lg={3}>
              <OverflowTip>File Number</OverflowTip>
            </Grid>
            <Grid item sm={4} md={3} lg={4} className={classes.marginCol}>
              <OverflowTip>Property Address</OverflowTip>
            </Grid>
            <>
              <Hidden smDown>
                <Grid item sm={2} md={2} lg={1} className={clsx({ [classes.textRight]: true })}>
                  <OverflowTip>Original Amount</OverflowTip>
                </Grid>
              </Hidden>
              <Grid item sm={2} md={2} lg={1} className={clsx({ [classes.textRight]: true })}>
                <OverflowTip>Amount Due</OverflowTip>
              </Grid>
            </>
          </Grid>
          <Grid container>
            {invoices.map((e: InvoiceProps, i) => (
              <AccordionRow data={e} key={i} />
            ))}
            <Grid container className={classes.totalsRow}>
              <Grid item lg={10} md={8} sm={10} className={classes.marginCol}></Grid>
              <>
                <Hidden smDown>
                  <Grid
                    item
                    sm={2}
                    md={2}
                    lg={1}
                    className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
                  >
                    <Typography className={classes.weight700} style={{ textAlign: 'center' }}>
                      {'$' + dollarize(grandTotal.originalAmount)}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  sm={2}
                  md={2}
                  lg={1}
                  className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
                >
                  <Typography className={classes.weight700} style={{ textAlign: 'center' }}>
                    {'$' + dollarize(grandTotal.amountDue)}
                  </Typography>
                </Grid>
              </>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ServiceOrderInvoiceSummaryTable;
