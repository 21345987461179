export enum UserRoles {
  Agent = 1,
  User = 2,
  SystemAdministrator = 3,
  OnBehalfOf = 5,
  TitleEscrowUser = 7,
  TitleUser = 10,
  EscrowUser = 11,
  CPLOnlyUser = 12,
  StaffAdministrator = 13,
}

export enum UserRoleTypes {
  Agent = 100,
  Staff = 101,
}

export enum ContactType {
  LENDER = 'Lender',
  CLOSING_ATTORNEY = 'Closing Attorney',
}

export const addSignatureMsg = 'Click and drag your mouse to create your signature in the area below';
export const agreementMsg = ' I accept the terms and conditions for the AgentNet eSignature Agreement';
export const cautionText =
  'The affixing of an electronic signature by a title agent upon a policy form is done by direction' +
  ' of the agent and is the responsibility of the agent. No liability is assumed by First American or' +
  ' its affiliates regarding regulatory compliance.';
export const uploadSignatureMsg =
  'Please sign your name on a blank white piece of paper and take a photo or scan it. Upload the image in JPG, PNG, GIF format below.';
