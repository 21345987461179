import { useAuth } from '@agentnet/auth';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { Notification } from 'ui-kit/components/notification/Notification';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import PdfIcon from 'ui-kit/icons/PDF';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { searchSdnName } from '../../../api/sdn/sdn-api';
import ofacPdf from '../../../static/NA_Standard_OFAC_Underwriting_Communication_Agents.pdf';
import { openDocument } from '../../../utilities/utilities';
import {
  addRowsMaxCount,
  DocumentType,
  KBLinks,
  Notice,
  OFACPdfWindowName,
  SdnAlertDuration,
  SdnErrorAlert,
  SdnPdfWindowName,
  SdnRegex,
  SdnWarningAlert,
  WindowFeatures,
} from '../../constants';
import './SdnContent.scss';
import SdnResultComponent from './SdnResultComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  ofac: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1),
      fontSize: '20px',
    },
  },
}));

const SdnContent = (): JSX.Element => {
  const [isSearching, setSearchFlag] = useState(false);
  const [addedRowsCount, addRowCount] = useState(0);
  const [isAddSearchDisabled, setIsAddSearchDisabled] = useState(false);
  const { getAccessToken } = useAuth();
  const classes = useStyles();
  const notice = Notice;
  const qaAttrPrefix = 'SDNSearchName';
  const openOfacPDF = () => {
    window.open(ofacPdf, OFACPdfWindowName, WindowFeatures);
  };
  const openSdnPDF = (pdf: string) => {
    openDocument(pdf, DocumentType, SdnPdfWindowName, WindowFeatures);
  };
  const [showBottomAppBar, setShowBottomAppBar] = useState(false);
  const defaultList = ['', '', '', '', '', '', '', ''];
  const newSearchFields = ['', '', '', ''];
  const [searchNameList, setSearchNameList] = useState(defaultList);
  const previousSearchFields = React.useRef(searchNameList).current;
  const addSearchFields = () => {
    if (addedRowsCount < addRowsMaxCount) {
      const updatedList = [...searchNameList].concat(newSearchFields);
      setSearchNameList(updatedList);
      addRowCount(addedRowsCount + 1);
      setIsAddSearchDisabled(false);
      if (addedRowsCount == addRowsMaxCount - 1) {
        setIsAddSearchDisabled(true);
      }
    } else {
      setIsAddSearchDisabled(true);
    }
  };
  const resetInputField = () => {
    setSdnSearch(false);
    let resetFields = [...defaultList];
    for (let i = 0; i < addedRowsCount; i++) {
      resetFields = resetFields.concat(newSearchFields);
    }
    setSearchNameList(resetFields);
  };
  React.useEffect(() => {
    if (JSON.stringify(previousSearchFields) !== JSON.stringify(searchNameList)) {
      setShowBottomAppBar(true);
    }
  }, [searchNameList]);
  const keyPress = (e: React.KeyboardEvent) => {
    const regex = new RegExp(SdnRegex);
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };
  const setSearchName = (name: string, index: number) => {
    const tempSearchNameList = [...searchNameList];
    tempSearchNameList[index] = name;
    setSearchNameList(tempSearchNameList);
  };
  const [sdnResult, setSdnResult] = useState({
    id: 0,
    names: '',
    result: '',
    searchDate: '',
    document: '',
    isMatched: false,
    partiesResult: [{ partyName: '', isMatched: false }],
  });
  const showErrorAlert = () => {
    setErrorNotification(true);
    setTimeout(function () {
      setErrorNotification(false);
    }, SdnAlertDuration);
  };
  const showWarningAlert = () => {
    setWarningNotification(true);
    setTimeout(function () {
      setWarningNotification(false);
    }, SdnAlertDuration);
  };
  const searchName = async () => {
    setSearchFlag(true);
    const token = await getAccessToken();
    const names = searchNameList.filter((element) => {
      return element !== '';
    });

    if (names.length <= 0) {
      showWarningAlert();
      setSdnSearch(false);
      setSearchFlag(false);
      return;
    }

    const res = await searchSdnName(names, token).catch((error) => {
      console.error('Error in search names:', error);
      showErrorAlert();
    });

    if (res != null) {
      setSdnResult({
        id: res.id,
        document: res.document,
        isMatched: res.isMatched,
        names: res.names,
        result: res.result,
        searchDate: res.searchDate,
        partiesResult: res.partiesResult,
      });
      setSdnSearch(true);
    } else {
      setSdnSearch(false);
    }
    setSearchFlag(false);
  };
  const resetForm = () => {
    addRowCount(0);
    setSearchNameList(defaultList);
    setSearchFlag(false);
    setSdnSearch(false);
    setIsAddSearchDisabled(false);
  };

  const backToSearch = () => {
    setSearchFlag(false);
    setSdnSearch(false);
  };

  const [sdnSearch, setSdnSearch] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [warningNotification, setWarningNotification] = useState(false);

  return (
    <ContentContainer fullWidth={sdnSearch}>
      <Grid container justify="center">
        {errorNotification ? (
          <Grid item xs={12} sm={12}>
            <Notification
              msg={SdnErrorAlert}
              severity="error"
              alertClass="sdn-search-alert"
              handleClose={() => setErrorNotification(false)}
            />
          </Grid>
        ) : null}
        {warningNotification ? (
          <Grid item xs={12} sm={12}>
            <Notification
              msg={SdnWarningAlert}
              severity="warning"
              alertClass="sdn-search-alert"
              handleClose={() => setWarningNotification(false)}
            />
          </Grid>
        ) : null}
      </Grid>
      {isSearching ? <LoadingSpinner status={'pending'} variant="linear" /> : null}
      {sdnSearch ? (
        <>
          <SdnResultComponent sdnResult={sdnResult} />
          <BottomAppBar
            primaryActionLabel="Start New Search"
            primaryActionDataQa="SDNStartNewSearch"
            onPrimaryClick={resetForm}
            secondaryActionLabel="View PDF"
            secondaryActionDataQa="SDNViewPdf"
            secondaryFilled
            onSecondaryClick={() => openSdnPDF(sdnResult.document)}
            cancelLabel="Modify Search"
            cancelDataQa="SDNModifySearch"
            onCancel={backToSearch}
          />
        </>
      ) : (
        <>
          <div className=" sdn-search-container">
            <PageHeader
              title="SDN Search"
              subtitle={
                <>
                  <strong>IMPORTANT NOTICE:</strong> As part of the United States Department of Treasury, the Office of
                  Foreign Assets Control publishes a list of individuals and entities owned, controlled by or acting
                  for, or on behalf of, targeted countries. It also lists individuals, groups, and entities, such as
                  terrorists and narcotics traffickers designated under programs that are not country-specific.
                  Collectively, these individuals and entities are called &quot;Specially Designated Nationals&quot; or
                  &quot;SDNs.&quot; Their assets are blocked and U.S. persons are generally prohibited from dealing with
                  them. The SDN search page is provided as an accommodation. For open title orders, AgentNet provides
                  default SDN searches for the buyers, sellers and lenders and retains the result in the file. For
                  information on (i) searching mandates, and (ii) clearing and documenting possible &quot;hits&quot;,
                  please review the applicable underwriting bulletins in{' '}
                  <a href="https://knowledge.agentnet.firstam.com/" target="_blank" rel="noreferrer">
                    AgentNet Knowledge
                  </a>
                  , contact your counsel or visit the federal SDN web site at:{' '}
                  <a href="http://www.treasury.gov/sdn" target="_blank" rel="noreferrer">
                    http://www.treasury.gov/sdn
                  </a>
                </>
              }
              contentRight={
                <AgentNetButton
                  variant="outlined"
                  color="primary"
                  startIcon={<PdfIcon />}
                  onClick={openOfacPDF}
                  data-testid="SDNOFACComplianceMandatesPDF"
                  size="small"
                >
                  OFAC Compliance Mandates
                </AgentNetButton>
              }
              menuItems={[
                {
                  label: 'Knowledge Base',
                  link: KBLinks.sdn,
                },
              ]}
            />
            <div className="sdn-search-content">
              <Grid container spacing={3} className="search-fields">
                {searchNameList.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={3} key={index} className="fields">
                      <AgentNetTextInput
                        margin={'normal'}
                        variant="outlined"
                        label="Search Name"
                        inputProps={{ maxLength: 300 }}
                        onKeyPress={keyPress}
                        data-testid={qaAttrPrefix + [index + 1]}
                        value={searchNameList[index]}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setSearchName(e.target.value, index);
                            searchName();
                          }
                        }}
                        onChange={(e) => {
                          setSearchName(e.target.value, index);
                        }}
                        fullWidth
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          {showBottomAppBar && (
            <BottomAppBar
              primaryActionLabel="Search"
              primaryActionDataQa="SDNSearch"
              secondaryActionLabel="Clear All"
              secondaryActionDataQa="SDNClearAll"
              cancelLabel="Add Search Fields"
              cancelDataQa="SDNAddSearchFields"
              onCancel={addSearchFields}
              onPrimaryClick={searchName}
              onSecondaryClick={resetInputField}
              disableCancel={isAddSearchDisabled}
            />
          )}
        </>
      )}
    </ContentContainer>
  );
};

export default SdnContent;
