import { EmptyMessages } from '@fluentsms/agentnet-web-icons';

import { createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    heading: {
      textTransform: 'none',
    },
  }),
);

export function NoMessages() {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="no-messages">
      <EmptyMessages width={250} />
      <Typography className={classes.heading} color="textPrimary" variant="h4">
        No Messages Yet
      </Typography>
      <Typography color="textSecondary" variant="body1">
        Add your first update below to keep everyone in the loop.
      </Typography>
    </div>
  );
}
