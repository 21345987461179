import resolveEnv, {
  CERT,
  DEV,
  DEV1,
  DEV2,
  IP,
  LOCAL,
  PROD,
  QA,
  QA1,
  QA2,
  STAGE,
  SUPPORT,
  TEST,
  UAT,
} from '@agentnet/utils';
import { Configuration, LogLevel } from '@azure/msal-browser';

const config: {
  [env: string]: {
    clientId: string;
    tenant: string;
    domain: string;
    redirectUri: string;
    msalScope: string[];
    apiScope: string[];
  };
} = {
  [LOCAL]: {
    clientId: 'e9f855b1-39d9-4e87-854e-4560bc8b0fd6',
    domain: 'dev.login.firstam.com',
    tenant: 'MyIDaaSDev.onmicrosoft.com',
    redirectUri: 'http://localhost:3000/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSDev.onmicrosoft.com/48847198-c786-4457-a6c8-70bd2fd263a2/API.Access'],
  },
  [DEV]: {
    clientId: 'e0928382-10b9-4b5c-97a8-48d0fdd3d0cf',
    domain: 'dev.login.firstam.com',
    tenant: 'MyIDaaSDev.onmicrosoft.com',
    redirectUri: 'https://dev.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSDev.onmicrosoft.com/48847198-c786-4457-a6c8-70bd2fd263a2/API.Access'],
  },
  [DEV1]: {
    clientId: 'e0928382-10b9-4b5c-97a8-48d0fdd3d0cf',
    domain: 'dev.login.firstam.com',
    tenant: 'MyIDaaSDev.onmicrosoft.com',
    redirectUri: 'https://env1.dev.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSDev.onmicrosoft.com/48847198-c786-4457-a6c8-70bd2fd263a2/API.Access'],
  },
  [DEV2]: {
    clientId: 'e0928382-10b9-4b5c-97a8-48d0fdd3d0cf',
    domain: 'dev.login.firstam.com',
    tenant: 'MyIDaaSDev.onmicrosoft.com',
    redirectUri: 'https://env2.dev.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSDev.onmicrosoft.com/48847198-c786-4457-a6c8-70bd2fd263a2/API.Access'],
  },
  [QA]: {
    clientId: '687c7244-65eb-4e14-819a-2959ad41ec64',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://qa.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [QA1]: {
    clientId: '687c7244-65eb-4e14-819a-2959ad41ec64',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://env1.qa.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [QA2]: {
    clientId: '687c7244-65eb-4e14-819a-2959ad41ec64',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://env2.qa.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [TEST]: {
    clientId: 'dd482903-42ad-41be-b027-290f1ccc65eb',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://test.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [UAT]: {
    clientId: '954add13-725c-4755-a89d-0192ba18c0fb',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://uat.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [CERT]: {
    clientId: '25b4d115-0bcd-44db-a0fb-fcf0b78ae5e2',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://cert.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [IP]: {
    clientId: 'dd482903-42ad-41be-b027-290f1ccc65eb',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://ip.agentnetportal.firstam.com/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [SUPPORT]: {
    clientId: '20d7c2ec-9051-42ee-ae64-ad38fe314219',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://support.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [STAGE]: {
    clientId: '20d7c2ec-9051-42ee-ae64-ad38fe314219',
    domain: 'uat.login.firstam.com',
    tenant: 'MyIDaaSUat.onmicrosoft.com',
    redirectUri: 'https://stage.agentnetportal.firstam.net/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://MyIDaaSUat.onmicrosoft.com/27e289e8-a781-4c20-9e37-cb75c124ec57/API.Access'],
  },
  [PROD]: {
    clientId: '088804a2-1c78-4a12-a855-e4f77adbd275',
    domain: 'login.firstam.com',
    tenant: 'FirstAmericanIdentity.onmicrosoft.com',
    redirectUri: 'https://agentnet.firstam.com/',
    msalScope: ['openid', 'profile'],
    apiScope: ['https://FirstAmericanIdentity.onmicrosoft.com/4eb05b66-8dab-4d35-b4d0-4a04bc7885d9/API.Access'],
  },
};

export default config[resolveEnv()];

const environmentConfig = config[resolveEnv()];

let b2cPolicies: any;

if (
  environmentConfig.redirectUri === 'http://localhost:3000/' ||
  environmentConfig.redirectUri === 'https://env1.dev.agentnetportal.firstam.net/' ||
  environmentConfig.redirectUri === 'https://env1.qa.agentnetportal.firstam.net/' ||
  environmentConfig.redirectUri === 'https://ip.agentnetportal.firstam.com/'
) {
  b2cPolicies = {
    names: {
      signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
      signUp: 'B2C_1A_FA_EIDv2_Signup',
      forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
      forgotPassword: 'B2C_1A_FA_EIDv2_ForgotPassword',
      editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
      editPhone: 'B2C_1A_FA_EIDv2_PhoneEdit',
      editEmail: 'B2C_1A_FA_EIDv2_EmailEdit',
      changePassword: 'B2C_1A_FA_EIDv2_ResetPassword',
      employeeSignIn: 'b2c_1a_b2e_signin',
    },
    authorities: {
      signUpSignIn: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_Signin`,
      },
      signUp: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_Signup`,
      },
      forgotUsername: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_ForgotUsername`,
      },
      forgotPassword: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_ForgotPassword`,
      },
      editProfile: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_ProfileEdit`,
      },
      editPhone: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_PhoneEdit`,
      },
      editEmail: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_EmailEdit`,
      },
      changePassword: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_ResetPassword`,
      },
      employeeSignIn: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/b2c_1a_b2e_signin`,
      },
    },
  };
} else {
  b2cPolicies = {
    names: {
      signUpSignIn: 'b2c_1a_fa_eid_signinagentnet',
      signUp: 'b2c_1a_fa_eid_signupagentnet',
      forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
      forgotPassword: 'b2c_1_reset',
      editProfile: 'B2C_1A_FA_EID_ProfileEditAgentNet',
      editEmail: 'B2C_1A_FA_EID_ProfileEditAgentNet',
      editPhone: 'B2C_1A_FA_EID_EditAuthenticationPhoneAgentNet',
      changePassword: 'B2C_1A_FA_EID_ResetPasswordAgentNet',
      employeeSignIn: 'b2c_1a_b2e_signin',
    },
    authorities: {
      signUpSignIn: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/b2c_1a_fa_eid_signinagentnet`,
      },
      signUp: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/b2c_1a_fa_eid_signupagentnet`,
      },
      forgotUsername: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EIDv2_ForgotUsername`,
      },
      forgotPassword: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/b2c_1_reset`,
      },
      editProfile: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EID_ProfileEditAgentNet`,
      },
      editEmail: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EID_ProfileEditAgentNet`,
      },
      editPhone: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EID_EditAuthenticationPhoneAgentNet`,
      },
      changePassword: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/B2C_1A_FA_EID_ResetPasswordAgentNet`,
      },
      employeeSignIn: {
        authority: `https://${environmentConfig.domain}/tfp/${environmentConfig.tenant}/b2c_1a_b2e_signin`,
      },
    },
  };
}

export { b2cPolicies };

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const msalConfig: Configuration = {
  auth: {
    clientId: environmentConfig.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [environmentConfig.domain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
