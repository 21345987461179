import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { ColDef } from 'ag-grid-enterprise';
import { addDocuments, deleteDocument, getDocumentBytes, getDocuments } from 'api/documents/documents-api';
import { getFileInfoProps } from 'api/file/file-api';
import clsx from 'clsx';
import { DocumentType, WindowFeatures, newWindow } from 'features/constants';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import useAsync from 'hooks/useAsync';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import Uploader from 'ui-kit/components/uploader/Uploader';
import { Document, DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';
import { openDocument } from 'utilities/utilities';
import { getDecryptedFileId } from '../../api/serviceorder-api';
import { useViewState } from '../../hooks/ViewStateContext';
import { Files } from '../services/policyupload/type';
import { filterParams } from '../statements/statementListConfig';
export type OrderDocument = Files;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.common.white,
    paddingTop: 0,
    paddingBottom: 0,
  },
  paper: {
    color: theme.palette.common.white,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableStyles: {
    fontFamily: theme.typography.fontFamily,
    height: 'calc(100vh - 300px)',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginBottom: '300px',
    marginLeft: '10px',
  },
  buttonDiv: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  Docuploader: {
    width: '95%',
    marginLeft: pxToRem(32),
    marginBottom: theme.spacing(10),
  },
  upload: {
    width: '100%',
  },
  title: {
    width: '95%',
    marginLeft: pxToRem(8),
  },
}));

const documents: React.FC = () => {
  const classes = useStyles();
  const { fileId } = useParams<getFileInfoProps>();
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<any>([]);
  const [allFiles, setAllFiles] = useState<any>([]);
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const [showContent, setShowContent] = useState(true);
  const { addSnackbarMessage } = useSnackBars();
  const [documentId, setdocID] = useState<null>();
  const [deleteDocId, setDeleteDocId] = useState<null>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const { addGlobalMsg } = useGlobalMessages();
  const [errMsgs, setErrMsgs] = useState([]);
  const [isdeleteDocument, setIsDeleteDocument] = useState(false);
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue]);

  const fetchDecryptedFileId = async () => {
    const token = await getAccessToken();
    return getDecryptedFileId(fileId ?? '', token);
  };

  const {
    execute: executeGetDecrypteFileId,
    value: getDecryptedFileIdValue,
    status: getDecryptedFileIdStatus,
  } = useAsync<number>(fetchDecryptedFileId, false);

  useEffect(() => {
    setShowContent(false);
    executeGetDecrypteFileId().then();
  }, []);

  useEffect(() => {
    if (fileData?.accountId && getDecryptedFileIdValue) {
      executeGetDocument().then();
    }
  }, [getDecryptedFileIdValue]);

  const getDocument = async () => {
    const token = await getAccessToken();
    const documentResponse = await getDocuments(getDecryptedFileIdValue, token);
    setRowData(documentResponse);
    setShowContent(true);
  };

  const { execute: executeGetDocument, status: executeGetDocumentStatus } = useAsync<any>(getDocument, false);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const getDocumentData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    if (documentId) {
      return await getDocumentBytes(getDecryptedFileIdValue, documentId, token);
    }
    return { result: null };
  };

  const deleteDoc = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    if (deleteDocId) {
      return await deleteDocument(getDecryptedFileIdValue, deleteDocId, token);
    }
    return { result: null };
  };

  const { execute: executeDeleteDocument, value: deleteDocumentValue } = useAsync<any>(deleteDoc, false);

  useEffect(() => {
    if (deleteDocumentValue) {
      addSnackbarMessage({
        message: `Document deleted successfully`,
        type: 'success',
      });
      setIsDeleteDocument(false);
      setRowData(rowData.filter((row: any) => row.DocumentId !== deleteDocId));
    }
  }, [deleteDocumentValue]);

  const onViewPdfClick = (documentId: any) => {
    setdocID(documentId);
    addSnackbarMessage({
      message: `Generating the document...`,
      type: 'success',
    });
  };

  const onDeleteDocClick = (documentId: any) => {
    setIsDeleteDocument(true);
    setDeleteDocId(documentId);
  };

  const cancelDeleteDocument = () => {
    setIsDeleteDocument(false);
  };

  const deleteSingleDocument = () => {
    executeDeleteDocument().then();
  };

  const {
    execute: executeGetDocumentData,
    status: getDocumentDataStatus,
    value: documentDataResults,
    errors: getDocumentDataErrors,
  } = useAsync<any>(getDocumentData, false);

  useEffect(() => {
    if (documentId) {
      executeGetDocumentData().then();
      setTimeout(() => setdocID(null), 3000);
    }
  }, [documentId]);

  useEffect(() => {
    if (documentDataResults?.Content.length > 0) {
      const orderDocumentBytes = documentDataResults?.Content;
      let docType = '';

      switch (documentDataResults.MimeType.toUpperCase()) {
        case 'XML':
          docType = 'application/xml';
          break;
        case 'PDF':
          docType = DocumentType;
          break;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          docType = 'image/jpeg';
          break;
        case 'GIF':
          docType = 'image/gif';
          break;
        case 'BMP':
          docType = 'image/bmp';
          break;
        case 'TIFF':
        case 'TIF':
          docType = 'image/tiff';
          break;
        case 'DOC':
          docType = 'application/msword';
          break;
        case 'RTF':
          docType = 'application/msword';
          break;
        case 'TXT':
          docType = 'text/plain';
          break;
        case 'XLS':
          docType = 'application/vnd.ms-excel';
          break;
        case 'XPS':
          docType = 'application/vnd.ms-xpsdocument';
          break;
        case 'WPS':
          docType = 'application/vnd.ms-works';
          break;
        case 'DOCX':
          docType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'XLSX':
          docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'ODT':
          docType = 'application/vnd.oasis.opendocument.formula';
          break;
        case 'WPD':
          docType = 'application/wordperfect';
          break;
        case 'ESP':
          docType = 'application/octet-stream';
          break;
        case 'HTM':
          docType = 'text/html';
          break;
        case 'HTML':
          docType = 'text/html';
          break;
        case 'MSG':
          docType = 'application/vnd.ms-outlook';
          break;
        case 'JSON':
          docType = 'application/json';
          break;
        default:
          docType = DocumentType;
          break;
      }
      openDocument(orderDocumentBytes, docType, newWindow, WindowFeatures);
    }
  }, [getDocumentDataStatus]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [...(getDocumentDataErrors ?? [])];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    const isError = getDocumentDataStatus === 'error';
    setDisplayError(isError);
  }, [getDocumentDataStatus]);

  function generateIcon(fileType?: any) {
    if (fileType === 'pdf') {
      return <DocumentPDF />;
    }
    if (fileType === 'xlsx' || fileType === 'xls') {
      return <DocumentXLS />;
    }
    if (fileType === 'docx') {
      return <Document />;
    }
    return <Document />;
  }

  const statementsColumnsConfig: ColDef[] = [
    {
      headerName: 'Name',
      field: 'DocumentFileName',
      filter: 'agTextColumnFilter',
      tooltipField: 'DocumentFileName',
    },
    {
      headerName: 'Type',
      field: 'DocumentDescription',
      filter: 'agTextColumnFilter',
      sort: 'desc',
      tooltipField: 'DocumentDescription',
    },
    {
      headerName: 'Date',
      field: 'CreatedDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      tooltipField: 'CreatedDate',
      comparator: (date1: string, date2: string) => {
        const date1Parts = date1.split('/');
        const date2Parts = date2.split('/');
        const date1Obj = new Date(Number(date1Parts[2]), Number(date1Parts[0]) - 1, Number(date1Parts[1]));
        const date2Obj = new Date(Number(date2Parts[2]), Number(date2Parts[0]) - 1, Number(date2Parts[1]));
        return date1Obj.getTime() - date2Obj.getTime();
      },
      cellRenderer: (params: any) => {
        return params?.data?.CreatedDate.length > 0 ? params?.data?.CreatedDate : '-';
      },
    },
    {
      headerName: '',
      filter: '',
      field: 'DocumentId',
      tooltipField: 'file',
      cellRenderer: (params: any) => {
        return (
          <span>
            <AgentNetButton style={{ marginBottom: '15px' }} onClick={() => onViewPdfClick(params?.data?.DocumentId)}>
              {generateIcon(params?.data?.MIMETypeIdentifier)}
            </AgentNetButton>
            <DeleteForeverOutlined
              style={{ marginTop: '10px' }}
              fontSize="small"
              htmlColor="#B3150A"
              onClick={() => onDeleteDocClick(params?.data?.DocumentId)}
            ></DeleteForeverOutlined>
          </span>
        );
      },
    },
  ];
  const [documents, setDocuments] = useState<Files[]>([]);
  const [disableConfirmUpload, setDisableConfirmUpload] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [savingDocuments, setSavingDocuments] = useState<boolean>(false);

  const refreshUpload = () => {
    setDocuments([]);
    setDisableConfirmUpload(false);
    executeGetDocument().then();
    setAllFiles([]);
  };
  const cancelUpload = () => {
    setClearAll(true);
    refreshUpload();
  };

  function readFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(file);
    });
  }

  const getAsByteArray = async (file: Blob) => {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  };

  const handleDocuments: any = async (uploads: FileWithPath[]) => {
    setLoading(true);
    setDisableConfirmUpload(true);
    setUploadedFiles(uploads);
  };

  useEffect(() => {
    const documentList: any = documents ?? [];
    const localDocumentList: any = [];
    const filePathsPromises: any[] = [];
    let i = 0;
    uploadedFiles.forEach((file) => {
      const documentObj = {
        documentName: '',
      };
      //documentObj.documentName = file.name.split('.').slice(0, -1).join('.');
      documentObj.documentName = file.name;
      localDocumentList.push(documentObj);
      filePathsPromises.push(getAsByteArray(file));
    });
    Promise.all(filePathsPromises).then((filePaths) => {
      filePaths.forEach((e) => {
        localDocumentList[i].documentData = e;
        ++i;
      });
      setDocuments([...documentList, ...localDocumentList]);
    });
    setDisableConfirmUpload(false);
  }, [uploadedFiles]);

  const uploadDocuments = async () => {
    if (documents) executeUploadDocument().then();
  };
  const uploadDocument = async () => {
    const token = await getAccessToken();
    const reqData: any = {};
    reqData['fileId'] = getDecryptedFileIdValue;
    reqData['documents'] = documents;
    if (documents.length > 0) {
      setSavingDocuments(true);
      const documentResponse = await addDocuments(reqData, token);
      executeGetDocument().then();
      setAllFiles([]);
      setSavingDocuments(false);
      if (executeGetDocumentStatus == 'success') {
        addSnackbarMessage({
          message: 'Documents Uploaded Successfully',
          type: 'success',
        });
      }
    } else {
      addSnackbarMessage({
        message: 'Please Upload Valid Documents',
        type: 'error',
      });
    }
  };

  const {
    execute: executeUploadDocument,
    status: executeUploadDocumentStatus,
    value: uploadDocumentsResults,
    errors: uploadDocumentsErrors,
  } = useAsync<any>(uploadDocument, false);

  const deleteDocumentConfirm: any = async (file?: any) => {
    const documentList: any = documents ?? [];
    const filteredDocuments = documentList.filter((doc: any) => doc.documentName != file.name);
    if ('file' in file) {
      setAllFiles(allFiles.filter((f: any) => f.name != file.file.name));
    } else {
      setAllFiles(allFiles.filter((f: any) => f.name != file.name));
    }
    setDocuments(filteredDocuments);
  };

  // useEffect(() => {
  //   if (documents.length == 0) {
  //     setDisableConfirmUpload(true);
  //   }
  // }, []);

  useEffect(() => {
    if (documents?.length == 0) {
      setClearAll(true);
      setDisableConfirmUpload(true);
    } else {
      setClearAll(false);
    }
    setLoading(false);
  }, [documents]);

  const handleUpload: any = async () => {
    setRowData([]);
    setDocuments([]);
    setAllFiles([]);
    setDisableConfirmUpload(true);
  };

  useEffect(() => {
    setAllFiles(allFiles);
  }, [allFiles]);

  return (
    <>
      {executeGetDocumentStatus == 'pending' && (
        <div className={showContent ? '' : 'loading-container'}>
          <LoadingSpinner status={showContent ? '' : 'pending'} size={30} />
        </div>
      )}
      {isLoading == true && (
        <div>
          <LoadingSpinner variant="linear" status={isLoading == true ? 'pending' : ''} size={30} />
        </div>
      )}
      {showContent && rowData.length > 0 ? (
        <div className={classes.root}>
          <Grid item sm={12} className={classes.paper}>
            <AgentNetDivider
              title={'Documents'}
              typoVariant="h2"
              primaryButtonName="Upload Documents"
              onClickPrimary={handleUpload}
              disablePaddingX
            />
          </Grid>
          <div className={clsx('ag-theme-alpine container', classes.tableStyles)}>
            <DataTable
              rowData={rowData}
              columnDefs={statementsColumnsConfig}
              defaultColDef={defaultColDef}
              gridOptions={{ suppressRowClickSelection: true }}
            />
          </div>
        </div>
      ) : (
        getDecryptedFileIdStatus != 'pending' &&
        executeGetDocumentStatus != 'pending' && (
          <>
            <div className={classes.upload}>
              <PageHeader title="Upload Documents" />
              <Grid item sm={12} className={classes.Docuploader}>
                <Uploader
                  handleFiles={handleDocuments}
                  allFiles={allFiles}
                  onDeleteItem={deleteDocumentConfirm}
                  listTitle="Uploaded Document(s)"
                  maxSize={75}
                  clearAll={clearAll}
                  acceptTypes={{
                    'application/pdf': ['.pdf'],
                    'image/tif': ['.tif'],
                    'image/tiff': ['.tiff'],
                    'image/jpg': ['.JPG'],
                    'application/msword': ['.doc'],
                    'application/x-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/rtf': ['.rtf'],
                    'application/txt': ['.txt'],
                    'application/gif': ['.GIF'],
                    'application/msg': ['.msg'],
                    'application/htm': ['.htm'],
                    'application/png': ['.png'],
                    'application/xml': ['.xml'],
                  }}
                  data-testid={'DocumentUpload'}
                />
              </Grid>
              <BottomAppBar
                onCancel={() => {
                  cancelUpload && cancelUpload();
                }}
                primaryActionLabel={'Confirm Upload'}
                primaryActionDataQa={'ConfirmUpload'}
                //primaryLabel={`${documents.length}/${allFiles.length} files`}
                cancelDataQa={'UploadCancel'}
                onPrimaryClick={() => uploadDocuments && uploadDocuments()}
                disableCancel={false}
                showPrimary={true}
                disablePrimary={disableConfirmUpload || savingDocuments}
                primaryButtonLoader={savingDocuments}
                accordionView
              />
            </div>
          </>
        )
      )}
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationDocumentDelete"
          onConfirm={deleteSingleDocument}
          open={isdeleteDocument}
          onDismissAction={cancelDeleteDocument}
          dialogTitle="Delete Document"
          dialogBtnContent="Delete Document"
          dialogText="Are you sure you want to delete this document? You will not be able to recover deleted documents."
        />
      </>
    </>
  );
};

export default documents;
