import { Grid } from '@material-ui/core';
import { PartyTypeValue } from 'api/file/constants';
import React, { useState } from 'react';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import { nameSchema } from 'utilities/validation/schemas';
import AgentNetDropdownSelector from '../../../ui-kit/inputs/AgentNetDropdownSelector';
import { doValidate, FieldValidationError } from '../../../utilities/validation/validation';
import { PartyGroupType } from '../parties/enums/PartyTypeCategory';
import { IGetBuyerSellerResponse } from '../parties/interfaces/CreateBuyerSellerRequest';
import PersonalIdForm from './PersonalIdForm';

type CreateBuyerSellerProps = {
  partyGroupType: PartyGroupType;
  partyTypeValue: IGetBuyerSellerResponse;
  updatePartyTypeValue: (partyTypeVal: IGetBuyerSellerResponse) => void;
  validationErrors?: FieldValidationError[];
  showAllValidation?: boolean;
  setIsFormValid?: (isValid: boolean) => void;
  type?: string;
  qaAttr?: string;
};

const CreateBuyerSeller: React.FC<CreateBuyerSellerProps> = ({
  partyTypeValue,
  updatePartyTypeValue,
  showAllValidation,
  setIsFormValid,
  type = '',
  qaAttr = '',
}: CreateBuyerSellerProps) => {
  /* Handle Validation */
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [isPersonalIdFormValid, setIsPersonalIdFormValid] = useState<boolean>(false);
  const [isSpousePersonalIdFormValid, setIsSpousePersonalIdFormValid] = useState<boolean>(false);
  const onChangeWithValidation = async (party: IGetBuyerSellerResponse) => {
    if (party.partyType === PartyTypeValue.default) {
      setIsFormValid && setIsFormValid(true);
      setValidationErrors([]);
    } else if (party.partyType === PartyTypeValue.trust || party.partyType === PartyTypeValue.entity) {
      const nameValue = party.partyType === PartyTypeValue.entity ? { ...party.entity } : { ...party?.trustEstate };
      const errs = (await doValidate(nameValue, nameSchema)) ?? [];
      setValidationErrors(errs);
      setIsFormValid && setIsFormValid(errs.length === 0);
    } else {
      const isValid =
        party.partyType === PartyTypeValue.individual
          ? isPersonalIdFormValid
          : isPersonalIdFormValid && isSpousePersonalIdFormValid;
      setIsFormValid && setIsFormValid(isValid);
    }
    if (updatePartyTypeValue) updatePartyTypeValue(party);
  };

  const handlePartyStatus = (selectedValue: PartyTypeValue) => {
    const { entity, trustEstate, couple, individual, ...newPartyTypeValue } = partyTypeValue;

    onChangeWithValidation({ ...newPartyTypeValue, partyType: selectedValue });
  };

  const evaluateTrustOrEntityName = () => {
    if (partyTypeValue.partyType === PartyTypeValue.entity) {
      return partyTypeValue.entity?.name ?? '';
    }
    if (partyTypeValue.partyType === PartyTypeValue.trust) {
      return partyTypeValue.trustEstate?.name ?? '';
    }
  };

  const handleOnChangeName = (newName: string) => {
    if (partyTypeValue.partyType === PartyTypeValue.entity) {
      onChangeWithValidation({ ...partyTypeValue, entity: { ...partyTypeValue.entity, name: newName } });
    }
    if (partyTypeValue.partyType === PartyTypeValue.trust) {
      onChangeWithValidation({
        ...partyTypeValue,
        trustEstate: { ...partyTypeValue.trustEstate, name: newName },
      });
    }
  };

  const selectPartyType = () => {
    if (partyTypeValue.partyType === PartyTypeValue.married) {
      return (
        <>
          <PersonalIdForm
            showAllValidation={showAllValidation}
            isCreateFile={true}
            setIsValid={setIsPersonalIdFormValid}
            value={partyTypeValue?.couple?.spouse1 ?? {}}
            onChange={(spouse1) =>
              onChangeWithValidation({ ...partyTypeValue, couple: { ...partyTypeValue.couple, spouse1: spouse1 } })
            }
            qaAttrPrefix={qaAttr}
          />
          <div className="spouse-personal-information">
            <PersonalIdForm
              setIsValid={setIsSpousePersonalIdFormValid}
              showAllValidation={showAllValidation}
              isCreateFile={true}
              value={partyTypeValue?.couple?.spouse2 ?? {}}
              onChange={(spouse2) =>
                onChangeWithValidation({ ...partyTypeValue, couple: { ...partyTypeValue.couple, spouse2: spouse2 } })
              }
              qaAttrPrefix={`${qaAttr}Spouse`}
            />
          </div>
        </>
      );
    } else if (partyTypeValue.partyType === PartyTypeValue.individual) {
      return (
        <div>
          <PersonalIdForm
            setIsValid={setIsPersonalIdFormValid}
            showAllValidation={showAllValidation}
            isCreateFile={true}
            value={partyTypeValue?.individual ?? {}}
            qaAttrPrefix={qaAttr}
            onChange={(person) => onChangeWithValidation({ ...partyTypeValue, individual: person })}
          />
        </div>
      );
    } else if (
      partyTypeValue.partyType === PartyTypeValue.entity ||
      partyTypeValue.partyType === PartyTypeValue.trust
    ) {
      return (
        <Grid item xs={6}>
          <AgentNetTextInput
            errs={validationErrors}
            showValidation={showAllValidation}
            fullWidth
            variant="outlined"
            required
            id="name"
            name={'name'}
            label={'Name'}
            value={evaluateTrustOrEntityName()}
            onChange={(e) => handleOnChangeName(e.target.value)}
            data-testid={`${qaAttr}Name`}
          />
        </Grid>
      );
    }
  };
  const partyTypeSelectedOptions = () => {
    return (Object.keys(PartyTypeValue) as Array<keyof typeof PartyTypeValue>)
      .filter((partyType) => PartyTypeValue[partyType] !== PartyTypeValue.toBeDetermined)
      .map((partyType) => {
        return {
          value: PartyTypeValue[partyType],
          name: `${PartyTypeValue[partyType].charAt(0).toUpperCase()}${PartyTypeValue[partyType].slice(1)}`,
        };
      });
  };

  return (
    <Grid
      item
      xs={12}
      className={
        partyTypeValue.partyType
          ? 'individual-party-item-container open-party'
          : 'individual-party-item-container closed-party'
      }
    >
      <Grid item sm={6} className="individual-party-type">
        <AgentNetDropdownSelector
          className="party-type-selector"
          name="partyType"
          id="partyType"
          label="Party Type"
          options={partyTypeSelectedOptions()}
          value={partyTypeValue.partyType ?? ''}
          dropdowntype="outlined"
          menuOption={(option) => handlePartyStatus(option as PartyTypeValue)}
          data-testid={`${qaAttr}PartyType`}
          isClearable
        />
      </Grid>

      {selectPartyType()}
    </Grid>
  );
};
export default CreateBuyerSeller;
