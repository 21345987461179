import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import AgentNetButton from '../button/AgentNetButton';

import './ConfirmationDialog.scss';

export type ConfirmationDialogProps = {
  open: boolean;
  onDismissAction?: MouseEventHandler;
  onExitButtonAction?: () => void;
  onConfirm?: MouseEventHandler;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  options?: RadioProps[];
  selectedOptions?: RadioProps;
  dialogText?: string;
  dialogTextHTML?: React.ReactNode;
  dialogTitle?: string;
  dialogBtnContent?: string;
  dialogCancelBtnContent?: string;
  secondaryActionBtnContent?: string;
  showSecondaryActionBtn?: boolean;
  dialogReverseAction?: boolean;
  dialogActionBtnJustifyContent?: 'space-between' | 'flex-start' | 'flex-end';
  disabled?: boolean;
  qaAttrPrefix?: string;
  disableBackDropClick?: boolean;
  disableCrossIconClick?: boolean;
  disablePrimaryBtn?: boolean;
  textArea?: boolean;
  size?: false | undefined | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const useStyles = makeStyles((theme) => ({
  confirmationDialogContainer: {
    height: 'auto',
  },
  modalContent: {
    width: '100%',
    color: theme.palette.text.primary,
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.brand.sky25,
  },
}));

export const AgentNetConfirmationDialog = ({
  open,
  onDismissAction: handleCloseDialog,
  onConfirm,
  dialogText,
  dialogTextHTML,
  dialogTitle,
  dialogBtnContent,
  secondaryActionBtnContent,
  showSecondaryActionBtn = true,
  onExitButtonAction,
  dialogCancelBtnContent = secondaryActionBtnContent ? secondaryActionBtnContent : 'Cancel',
  disableBackDropClick,
  disabled,
  disableCrossIconClick = false,
  disablePrimaryBtn,
  textArea,
  size,
  qaAttrPrefix,
  dialogActionBtnJustifyContent = 'space-between',
}: ConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog
      className={clsx(classes.confirmationDialogContainer, 'confirmation-dialog-container')}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackDropClick) return;
        if (onExitButtonAction) return onExitButtonAction;
        return handleCloseDialog;
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
      maxWidth={size}
    >
      <div className="alert-dailog-title-container">
        <Typography variant="h3" color="textSecondary" id="alert-dialog-title" data-testid="alert-title">
          {dialogTitle}
        </Typography>
        <IconButton
          disabled={disableCrossIconClick}
          onClick={onExitButtonAction ? onExitButtonAction : handleCloseDialog}
          data-testid={qaAttrPrefix + 'Close'}
        >
          <CloseIcon className="" fontSize="large" />
        </IconButton>
      </div>
      <Divider />
      <DialogContent>
        {!dialogTextHTML ? (
          !textArea ? (
            <DialogContentText
              id="alert-dialog-description"
              className={classes.modalContent}
              style={{ whiteSpace: 'pre-line' }}
            >
              {dialogText}
            </DialogContentText>
          ) : (
            <AgentNetTextInput value={dialogText} multiline label="Description" fullWidth />
          )
        ) : (
          dialogTextHTML
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: dialogActionBtnJustifyContent }} className={classes.ctaContainer}>
        {showSecondaryActionBtn && (
          <AgentNetButton
            variant="outlined"
            color="primary"
            onClick={handleCloseDialog}
            aria-label="cancel-delete"
            disabled={disabled}
            data-testid={qaAttrPrefix + 'Cancel'}
            size="small"
          >
            {dialogCancelBtnContent}
          </AgentNetButton>
        )}
        <AgentNetButton
          disabled={disablePrimaryBtn ? disablePrimaryBtn : disabled}
          loading={disabled}
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm-delete"
          data-testid={qaAttrPrefix + 'Submit'}
          size="small"
        >
          {dialogBtnContent}
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export const AgentNetDialog = ({
  open,
  onDismissAction: handleCloseDialog,
  onExitButtonAction,
  onConfirm,
  dialogText,
  dialogTitle,
  dialogTextHTML,
  dialogBtnContent,
  dialogCancelBtnContent = 'Cancel',
  dialogActionBtnJustifyContent = 'flex-end',
  disabled,
  qaAttrPrefix = 'ANConfirmationDialog',
  disableBackDropClick,
  textArea,
  size,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      className="confirmation-dialog-container-with-message"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackDropClick) return;
        if (onExitButtonAction) return onExitButtonAction;
        return handleCloseDialog;
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
      maxWidth={size}
    >
      <div className="alert-dailog-title-container">
        <Typography variant="h3" color="textPrimary" id="alert-dialog-title" data-testid="alert-title">
          {dialogTitle}
        </Typography>
        <IconButton onClick={onExitButtonAction ? onExitButtonAction : handleCloseDialog} disabled={disabled}>
          <CloseIcon className="" fontSize="large" />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className="modal-content">
        {!dialogTextHTML ? (
          !textArea ? (
            <DialogContentText id="alert-dialog-description">{dialogText}</DialogContentText>
          ) : (
            <AgentNetTextInput value={dialogText} multiline label="Description" fullWidth />
          )
        ) : (
          dialogTextHTML
        )}
      </DialogContent>
      <Divider />
      <DialogActions className="modal-action-btn-container" style={{ justifyContent: dialogActionBtnJustifyContent }}>
        <AgentNetButton
          data-testid={qaAttrPrefix + 'Cancel'}
          onClick={handleCloseDialog}
          color="primary"
          aria-label="cancel action"
          variant="outlined"
          disabled={disabled}
        >
          {dialogCancelBtnContent}
        </AgentNetButton>
        <AgentNetButton
          data-testid={qaAttrPrefix + 'Submit'}
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm action"
          loading={disabled}
          disabled={disabled}
        >
          {dialogBtnContent}
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export const AgentNetConfirmationDialogWithMessage = ({
  open,
  onDismissAction: handleCloseDialog,
  onConfirm,
  dialogText,
  dialogTextHTML,
  dialogTitle,
  dialogBtnContent,
  dialogCancelBtnContent = 'Cancel',
  dialogActionBtnJustifyContent = 'space-between',
  onChange,
  value,
  onExitButtonAction,
  disabled,
  qaAttrPrefix = 'ANConfirmationDialog',
  disableBackDropClick,
  size,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      className="confirmation-dialog-container-with-message"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackDropClick) return;
        return handleCloseDialog;
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
      maxWidth={size}
    >
      <div className="alert-dailog-title-container">
        <Typography variant="h3" color="textPrimary" id="alert-dialog-title" data-testid="alert-title">
          {dialogTitle}
        </Typography>
        <IconButton onClick={onExitButtonAction ? onExitButtonAction : handleCloseDialog} disabled={disabled}>
          <CloseIcon className="" fontSize="large" />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className="modal-content">
        {dialogTextHTML ? (
          dialogTextHTML
        ) : (
          <DialogContentText color="textPrimary" id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        )}
        <AgentNetTextInput
          variant="outlined"
          fullWidth
          value={value}
          onChange={onChange}
          label="Comments"
          multiline
          required
          disabled={disabled}
        />
      </DialogContent>
      <Divider />
      <DialogActions className="modal-action-btn-container" style={{ justifyContent: dialogActionBtnJustifyContent }}>
        <AgentNetButton
          data-testid={qaAttrPrefix + 'Cancel'}
          onClick={handleCloseDialog}
          color="primary"
          aria-label="cancel action"
          variant="outlined"
          disabled={disabled}
        >
          {dialogCancelBtnContent}
        </AgentNetButton>
        <AgentNetButton
          data-testid={qaAttrPrefix + 'Submit'}
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          disabled={!value || disabled}
          aria-label="confirm action"
          loading={disabled}
        >
          {dialogBtnContent}
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export const AgentNetDuplicateCPLDialog = ({
  open,
  onDismissAction: handleCloseDialog,
  onConfirm,
  dialogText,
  dialogTitle,
  dialogBtnContent,
  dialogCancelBtnContent = 'Cancel',
  dialogActionBtnJustifyContent = 'space-between',
  selectedOptions,
  options = [],
  onChange,
  disabled,
  disableBackDropClick,
  size,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      data-testid="CPLDuplicateAdditional"
      className="confirmation-dialog-container-with-message"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackDropClick) return;
        return handleCloseDialog;
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={size}
    >
      <div className="alert-dailog-title-container">
        <Typography variant="h3" color="textPrimary" data-testid="alert-title">
          {dialogTitle}
        </Typography>
        <IconButton onClick={handleCloseDialog} disabled={disabled} data-testid="CPLDuplicateClose">
          <CloseIcon className="" fontSize="large" />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className="modal-content">
        <DialogContentText color="textPrimary" id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
        <div data-testid="CPLDuplicateOptions">
          <RadioGroup
            border={false}
            selected={selectedOptions}
            options={options}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) onChange(e);
            }}
            row={false}
          />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions className="modal-action-btn-container" style={{ justifyContent: dialogActionBtnJustifyContent }}>
        <AgentNetButton
          data-testid="CPLDuplicateCancel"
          onClick={handleCloseDialog}
          color="primary"
          aria-label="cancel action"
          variant="outlined"
          disabled={disabled}
        >
          {dialogCancelBtnContent}
        </AgentNetButton>
        <AgentNetButton
          data-testid="CPLDuplicateSubmit"
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm action"
          loading={disabled}
          disabled={disabled}
        >
          {dialogBtnContent}
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};
