import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { Notification } from 'ui-kit/components/notification/Notification';
import { fileInfoType } from '../../../api/file/interfaces/get-file';
import { IGetBuyerSellerResponse } from './interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from './interfaces/CreateLenderRequest';
import { ILenderFirm } from './interfaces/LenderModels';

import { MAX_BUYERS, MAX_LENDERS, MAX_SELLERS } from 'api/file/constants';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import PartiesAccordian from './Parties/PartiesAccordian';
import PartyNotCreated from './PartyNotCreated/PartyNotCreated';

const MAX_PARTIES = 20;

type partiesProps = {
  defaultValue?: any;
  setFileInfoError?: any;
  lenderList?: {
    name: string;
    value: string;
    createdDate: number;
  }[];
  getLenderStatus: string;
  lenderFirmList: ILenderFirm[];
  isAddPartyDisabled: boolean;
  isFileUpdated: boolean;
  setFileUpdated: any;
  setPartiesUpdated: any;
  showAllValidation?: boolean;
  checkPartiesUpdated: any;
  setIsFormValid: any;
  handleSubmitted: any;
  handleParties: any;
  handleMaxPartyLimit: any;
  showMaxPartyError: any;
  showToaster: any;
  openPartiesAccordian: any;
  handleOpenAccordian: any;
  partiesRefs: any;
  maxPartyErrorRef: any;
  sdnResultsMessage?: React.ReactNode;
  openSdnPDF?: () => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAlert-icon': {
        color: '#B3150A',
      },
      '& .partyErrorHeading': {
        fontWeight: 500,
        fontSize: '16px',
      },
      '& .partyErrorList': {
        paddingLeft: '24px',
        margin: 0,
        fontSize: '14px',
      },
      marginBottom: '16px',
    },
  }),
);
export const Baseparties = ({
  defaultValue,
  setFileInfoError,
  lenderList,
  getLenderStatus,
  lenderFirmList,
  setFileUpdated,
  isFileUpdated,
  isAddPartyDisabled,
  setPartiesUpdated,
  checkPartiesUpdated,
  showAllValidation,
  setIsFormValid,
  handleSubmitted,
  handleParties,
  handleMaxPartyLimit,
  showMaxPartyError,
  showToaster,
  openPartiesAccordian,
  handleOpenAccordian,
  maxPartyErrorRef,
  partiesRefs,
  sdnResultsMessage,
  openSdnPDF,
}: partiesProps): JSX.Element => {
  const { addSnackbarMessage } = useSnackBars();
  const classes = useStyles();
  const [partiesDeleted, setPartiesDeleted] = useState<boolean>(false);
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { partyUpdated, setPartyUpdated, fileData, setFileData, loadFileOrderInsightsData } = fileDataCtx;
  const [tempParties, setTempParties] = useState<any>([]);
  const [savedParties, setSavedParties] = useState<any>([]);

  useEffect(() => {
    partiesDeleted &&
      addSnackbarMessage({
        message: `Party Deleted Successfully`,
        type: 'success',
        onClose: () => {
          setPartiesDeleted(false);
        },
      });
  }, [partiesDeleted]);

  const showPartiesDeletionMsg = useCallback(() => {
    showToaster(setPartiesDeleted);
  }, []);

  const updateSavedParties = useCallback((newValueOrFunction) => {
    setSavedParties((prevValue: any) => {
      if (typeof newValueOrFunction === 'function') {
        return newValueOrFunction(prevValue);
      } else {
        return newValueOrFunction;
      }
    });
  }, []);

  const updateCurrentParties = useCallback((newValueOrFunction) => {
    setTempParties((prevValue: any) => {
      if (typeof newValueOrFunction === 'function') {
        return newValueOrFunction(prevValue);
      } else {
        return newValueOrFunction;
      }
    });
  }, []);

  const fileInfo = useMemo(() => {
    return {
      buyers: fileData?.buyers || [],
      sellers: fileData?.sellers || [],
      lenders: fileData?.lenders || [],
      fileNumber: fileData?.fileNumber,
      officeId: fileData?.officeId,
      status: fileData?.fileStatus,
      loanAmount: fileData?.loanAmount,
      isReopenedByProduct: fileData?.serviceStatus?.isReopenedByProduct,
    };
  }, [fileData]);

  useEffect(() => {
    if (fileData?.loanAmount !== undefined) {
      const { lenders } = getPartiesDetails(tempParties);

      if (lenders.length > 0 && lenders[0].loanAmount !== fileData?.loanAmount) {
        updateCurrentParties((prevParties: any) => {
          let isLenderFound = false;
          const tempPartyList =
            prevParties?.map((partiesValue: any) => {
              if (partiesValue.partyMethod === 'lender' && !isLenderFound) {
                isLenderFound = true;
                return { ...partiesValue, loanAmount: fileData?.loanAmount };
              }
              return { ...partiesValue };
            }) ?? [];
          handleChange(tempPartyList, false, false);
          return [...tempPartyList];
        });
      }
    }
  }, [fileData?.loanAmount]);

  useEffect(() => {
    if (!isAddPartyDisabled && (!partyUpdated || isFileUpdated)) {
      setFileUpdated(false);
      setPartyUpdated && setPartyUpdated(true);
      const buyers = (fileData?.buyers ?? []).map((item: IGetBuyerSellerResponse) => {
        item.partyMethod = 'buyer';
        item.expand = item.expand ?? false;
        return item;
      });
      const sellers = (fileData?.sellers ?? []).map((item: IGetBuyerSellerResponse) => {
        item.partyMethod = 'seller';
        item.expand = item.expand ?? false;
        return item;
      });
      const lenders = (fileData?.lenders ?? []).map((item: IGetLenderResponse) => {
        item.partyMethod = 'lender';
        item.partyType = 'Lender';
        item.expand = item.expand ?? false;
        return item;
      });
      handleParties({
        buyers,
        sellers,
        lenders,
      });
      handleMaxPartyLimit({
        buyers: buyers?.length > MAX_BUYERS,
        sellers: sellers?.length > MAX_SELLERS,
        lenders: lenders?.length > MAX_LENDERS,
      });
      updateCurrentParties([...buyers, ...sellers, ...lenders]);
      setSavedParties([...buyers, ...sellers, ...lenders]);
      setIsFormValid({});
    }
  }, [isFileUpdated, isAddPartyDisabled, fileData?.buyers, fileData?.sellers, fileData?.lenders, partyUpdated]);

  const getPartiesDetails = (data: IGetBuyerSellerResponse[] & IGetLenderResponse[]) => {
    const buyers = data.filter((party: any) => party.partyMethod === 'buyer');
    const sellers = data.filter((party: any) => party.partyMethod === 'seller');
    const lenders = data.filter((party: any) => party.partyMethod === 'lender');
    const remainingParties = data.filter((party: any) => !party.partyMethod);
    return { buyers, sellers, lenders, remainingParties };
  };

  const updateFileData = useCallback(
    _.debounce((buyers, sellers, lenders, remainingParties) => {
      setFileData &&
        setFileData((prevFileData: any) => {
          const isLenderExist =
            Array.isArray(prevFileData?.lenders) && prevFileData?.lenders && prevFileData?.lenders?.length > 0;
          const tempFileData = {
            ...(prevFileData ?? ({} as fileInfoType)),
            buyers,
            sellers,
            lenders,
            remainingParties,
          };
          if (isLenderExist) {
            tempFileData.loanAmount = lenders[0]?.loanAmount ?? 0;
          } else if (lenders?.length === 1) {
            tempFileData.lenders[0].loanAmount = prevFileData?.loanAmount ?? 0;
          }
          return tempFileData as fileInfoType;
        });
    }, 1000),
    [],
  );

  const handleChange = React.useCallback(
    (
      parties: IGetBuyerSellerResponse[] & IGetLenderResponse[],
      updateDefaultParties?: (IGetBuyerSellerResponse[] & IGetLenderResponse[]) | boolean,
      checkLimit?: boolean,
    ) => {
      const { buyers, sellers, lenders, remainingParties } = getPartiesDetails(parties);

      if (updateDefaultParties && defaultValue) {
        const {
          buyers: defaultBuyers,
          sellers: defaultSellers,
          lenders: defaultLenders,
        } = getPartiesDetails(updateDefaultParties as IGetBuyerSellerResponse[] & IGetLenderResponse[]);

        defaultValue.buyers = _.cloneDeep(defaultBuyers);
        defaultValue.sellers = _.cloneDeep(defaultSellers);
        defaultValue.lenders = _.cloneDeep(defaultLenders).map((item) => {
          if (item.partyType) {
            delete item.partyType;
          }
          return item;
        });
        defaultValue.loanAmount = defaultValue?.lenders?.length > 0 ? lenders[0]?.loanAmount : 0;

        handleMaxPartyLimit({
          buyers: defaultBuyers?.length > MAX_BUYERS,
          sellers: defaultSellers?.length > MAX_SELLERS,
          lenders: defaultLenders?.length > MAX_LENDERS,
        });
      }
      handleParties({
        buyers,
        sellers,
        lenders,
        remainingParties,
      });

      if (checkLimit) {
        handleMaxPartyLimit({
          buyers: buyers?.length > MAX_BUYERS,
          sellers: sellers?.length > MAX_SELLERS,
          lenders: lenders?.length > MAX_LENDERS,
        });
      }
      if (remainingParties.length > 0) {
        setPartiesUpdated(true);
      } else {
        checkPartiesUpdated(defaultValue?.buyers || [], defaultValue?.sellers || [], defaultValue?.lenders || [], [
          ...parties,
        ]);
      }
      updateFileData(buyers, sellers, lenders, remainingParties);
    },
    [defaultValue],
  );

  const handleAddPartyInformation = () => {
    const defaultParty = {
      uuid: Date.now(),
      partyMethod: '',
      expand: true,
    };
    if (tempParties) {
      setPartiesUpdated(true);
      setIsFormValid((prev: any) => ({ ...prev, [defaultParty.uuid]: false }));
      updateCurrentParties([...tempParties, defaultParty]);
      handleSubmitted(false);
    }
  };

  const isPartiesExist = React.useMemo(() => tempParties && tempParties.length > 0, [tempParties]);

  const [partiesExpand, setPartiesExpand] = useState(false);
  const handleExpand = () => {
    setPartiesExpand(!partiesExpand);
    updateCurrentParties((prevParties: any) => {
      const tempPartyList =
        prevParties?.map((partiesValue: any) => {
          return { ...partiesValue, expand: partiesExpand };
        }) ?? [];
      return [...tempPartyList];
    });
  };

  useEffect(() => {
    if (openPartiesAccordian?.length > 0) {
      handleOpenAccordian([]);
      updateCurrentParties((prevParty: any) => {
        const tempParty = prevParty.map((item: any) => {
          const id = item.partyId ?? item.lenderId ?? item.uuid;
          if (openPartiesAccordian.includes(String(id))) {
            return { ...item, expand: true };
          }
          return item;
        });
        handleChange(tempParty, false, true);
        return tempParty;
      });
    }
  }, [openPartiesAccordian]);

  const renderAddPartyButton = (variant: 'contained' | 'text' | 'outlined') => {
    return (
      <AgentNetButton
        className="add-party-btn"
        color="primary"
        size="small"
        data-testid="FileInfoPartyAddBottom"
        variant={variant}
        onClick={handleAddPartyInformation}
        disabled={
          isAddPartyDisabled ||
          tempParties?.length === MAX_PARTIES ||
          fileData?.fileStatus !== 'Open' ||
          fileInfo.isReopenedByProduct
        }
        plusIcon
      >
        Add Party
      </AgentNetButton>
    );
  };

  const handleDeleteConfirmation = useCallback(
    (options: any) => {
      const {
        id,
        executeDeleteLender,
        setOpenDeleteModal,
        setIsSubmitted,
        handleSetFormValid,
        currentIndex,
        lenderId,
        executeDeletePartytype,
      } = options;
      if (id) {
        if (lenderId) {
          executeDeleteLender().then(() => {
            setOpenDeleteModal(false);
          });
        } else {
          executeDeletePartytype().then(() => {
            setOpenDeleteModal(false);
          });
        }
        setIsSubmitted(true);
      } else {
        updateCurrentParties((prevParty: any) => {
          const tempParty = [...prevParty].filter(
            (partiesValue: any, partiesIndex: any) => partiesIndex !== currentIndex,
          );
          handleChange(tempParty, false, true);
          return tempParty;
        });
        showToaster(setPartiesDeleted);
        setIsSubmitted(true);
        setOpenDeleteModal(false);
        handleSetFormValid(true);
      }
    },
    [defaultValue],
  );

  const handleDeleteSuccess = useCallback((getUpdatedParties: any, value: any) => {
    loadFileOrderInsightsData && loadFileOrderInsightsData(fileData?.fileId ?? '');
    let tempParty: any[] = [];
    updateCurrentParties((prevParty: any) => {
      const tempPartyItem = getUpdatedParties(prevParty, value);
      tempParty = _.cloneDeep(tempPartyItem);
      return tempPartyItem;
    });
    return tempParty;
  }, []);

  const updateParty = useCallback(
    (newPartyData: any, index: number) => {
      updateCurrentParties((prevParty: any) => {
        const tempParty = [...prevParty];
        tempParty[index] = newPartyData;
        handleChange(tempParty);
        return tempParty;
      });
    },
    [defaultValue],
  );

  const renderErrorMessage = useCallback((errors: any) => {
    return (
      <Notification alertClass="align-error" className={classes.root} inline severity="error">
        <b className={'partyErrorHeading'}>Max No. of Parties Exceeded</b>
        <ul className={'partyErrorList'}>
          {errors.buyers && (
            <li>
              You have more than {MAX_BUYERS} Buyer/Borrowers. Please delete Buyer/Borrowers until {MAX_BUYERS} or fewer
            </li>
          )}
          {errors.sellers && (
            <li>
              You have more than {MAX_SELLERS} Seller/Owners. Please delete Seller/Owners until {MAX_SELLERS} or fewer
            </li>
          )}
          {errors.lenders && (
            <li>
              You have more than {MAX_LENDERS} Lenders. Please delete Lenders until {MAX_LENDERS} or fewer
            </li>
          )}
        </ul>
      </Notification>
    );
  }, []);

  const PartyAccordianMemoise = useMemo(() => {
    return tempParties?.map((party: any, index: number) => {
      return (
        <PartiesAccordian
          key={party.partyId ?? party.lenderId ?? party.uuid}
          keyValue={party.partyId ?? party.lenderId ?? party.uuid}
          currentIndex={index}
          value={party}
          partiesRefs={partiesRefs}
          updateCurrentParties={updateCurrentParties}
          handleDeleteSuccess={handleDeleteSuccess}
          handleDeleteConfirmation={handleDeleteConfirmation}
          updateParty={updateParty}
          showAllValidation={showAllValidation}
          setFileInfoError={setFileInfoError}
          onChanges={handleChange}
          lenderList={lenderList}
          getLenderStatus={getLenderStatus}
          lenderFirmList={lenderFirmList}
          setIsFormValid={setIsFormValid}
          fileInfo={fileInfo}
          updateSavedParties={updateSavedParties}
          savedParties={savedParties}
          showPartiesDeletionMsg={showPartiesDeletionMsg}
          openSdnPDF={openSdnPDF && openSdnPDF}
          onShiftClickExpand={handleExpand}
        />
      );
    });
  }, [tempParties, showAllValidation, fileInfo, lenderList, getLenderStatus, lenderFirmList, savedParties]);

  return (
    <section className="parties-card-container">
      <PageHeader
        title="Parties"
        titleVariant="h3"
        subtitle={sdnResultsMessage && isPartiesExist ? sdnResultsMessage : undefined}
        disableMargin="x"
        divider={false}
        contentRight={
          isPartiesExist && (
            <AgentNetButton
              variant="contained"
              color="primary"
              onClick={handleAddPartyInformation}
              disabled={
                isAddPartyDisabled ||
                tempParties?.length === MAX_PARTIES ||
                fileData?.fileStatus !== 'Open' ||
                fileInfo.isReopenedByProduct
              }
              data-testid="FileInfoPartyAddTop"
              plusIcon
              size="small"
            >
              Add Party
            </AgentNetButton>
          )
        }
      />
      <div ref={maxPartyErrorRef}>
        {(showMaxPartyError.buyers || showMaxPartyError.sellers || showMaxPartyError.lenders) &&
          renderErrorMessage(showMaxPartyError)}
      </div>
      {isPartiesExist ? PartyAccordianMemoise : <PartyNotCreated>{renderAddPartyButton('contained')}</PartyNotCreated>}
      {tempParties && tempParties.length > 1 && <Box mt={3}>{renderAddPartyButton('outlined')}</Box>}
    </section>
  );
};

export const Parties = React.memo(Baseparties);
