import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ColDef } from 'ag-grid-enterprise';
import { getDocument, getHistory } from 'api/policyupload/policyupload-api';
import { DocumentType, KBLinks, WindowFeatures, newWindow } from 'features/constants';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useState } from 'react';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import PDFIcon from 'ui-kit/icons/PDF';
import { openDocument } from 'utilities/utilities';
import './PolicyUpload.scss';
import {
  GetPolicyUploadHistoryRequest,
  GetPolicyUploadHistoryResponse,
  ViewDocumentRequest,
  ViewDocumentResponse,
} from './type';

interface HistoryProps {
  firmId: string;
}
const History: React.FC<HistoryProps> = ({ firmId }: HistoryProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: '100%',
      },
      uploadsWrap: {
        padding: pxToRem(0, 24, 24),
      },
      contentClass: {
        margin: '0 auto auto',
      },
      tableDefault: {
        fontFamily: theme.typography.fontFamily,
        height: 'calc(100vh - 296px)',
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  );

  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [rowData, setRowData] = useState<any>([]);
  const [selectedViewDocument, setSelectedViewDocument] = useState<any>('');

  const onGridReady = (params: any) => {
    const defaultSortModel = [{ colId: 'UploadedDateTime', sort: 'desc', sortIndex: 1 }];
    params.api.applyColumnState({ state: defaultSortModel });
  };

  const columnDefs: ColDef[] = [
    {
      field: 'fileName',
      headerName: 'File Name',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 540,
      tooltipField: 'fileName',
      flex: 1,
    },
    {
      field: 'uploadedDateTime',
      headerName: 'Upload Date',
      width: 250,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (cellValue == null) return 0;
          const dateParts = cellValue.split('/');
          const year = Number(dateParts[2].split(' ')[0]);
          const day = Number(dateParts[1]);
          const month = Number(dateParts[0]) - 1;
          const cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agMultiColumnFilter',
    },
    {
      field: 'View',
      headerName: '',
      width: 110,
      filter: false,
      pinned: 'right',
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      cellRenderer: (params: any) => (
        <PDFIcon fontSize="small" onClick={() => setSelectedViewDocument(params.data)} data-testid="FirmSelectSearch" />
      ),
    },
  ];

  useEffect(() => {
    executeGetPolicyUploadHistory().then();
  }, []);

  const fetchPolicyUploadHistory = async (): Promise<GetPolicyUploadHistoryResponse[]> => {
    const token = await getAccessToken();
    const payload: GetPolicyUploadHistoryRequest = {
      firmId: firmId,
    };
    return getHistory(payload, token);
  };

  const viewDocument = async (): Promise<ViewDocumentResponse> => {
    const token = await getAccessToken();
    const payload: ViewDocumentRequest = {
      FileName: selectedViewDocument.fileName,
      InRPS: selectedViewDocument.inRPS,
      FirmId: firmId,
    };
    return getDocument(payload, token);
  };

  const {
    execute: executeGetPolicyUploadHistory,
    value: getPolicyUploadHistoryValue,
    status: getPolicyUploadHistoryStatus,
    errors: getPolicyUploadHistoryError,
  } = useAsync<GetPolicyUploadHistoryResponse[]>(fetchPolicyUploadHistory, false);

  const {
    execute: executeGetDocumentData,
    value: getDocumentDataValue,
    status: getDocumentDataStatus,
    errors: getDocumentDataError,
  } = useAsync<any>(viewDocument, false);

  useEffect(() => {
    setDisplayError(getPolicyUploadHistoryStatus === 'error' || getDocumentDataStatus === 'error');
  }, [getPolicyUploadHistoryStatus, getDocumentDataStatus]);

  useEffect(() => {
    const errorMessages = getPolicyUploadHistoryError?.length
      ? getPolicyUploadHistoryError
      : getDocumentDataError?.length
      ? getDocumentDataError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    setRowData(getPolicyUploadHistoryValue);
  }, [getPolicyUploadHistoryValue]);

  useEffect(() => {
    if (selectedViewDocument !== '') {
      setSelectedViewDocument('');
      executeGetDocumentData().then();
    }
  }, [selectedViewDocument]);

  useEffect(() => {
    if (getDocumentDataValue?.DocumentData.length > 0) {
      const orderDocumentBytes = getDocumentDataValue.DocumentData;
      let docType = '';
      const documentType = getDocumentDataValue.DocumentName.split('.').pop();

      switch (documentType.toUpperCase()) {
        case 'XML':
          docType = 'application/xml';
          break;
        case 'PDF':
          docType = DocumentType;
          break;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          docType = 'image/jpeg';
          break;
        case 'GIF':
          docType = 'image/gif';
          break;
        case 'BMP':
          docType = 'image/bmp';
          break;
        case 'TIFF':
        case 'TIF':
          docType = 'image/tiff';
          break;
        case 'DOC':
          docType = 'application/msword';
          break;
        case 'RTF':
          docType = 'application/msword';
          break;
        case 'TXT':
          docType = 'text/plain';
          break;
        case 'XLS':
          docType = 'application/vnd.ms-excel';
          break;
        case 'XPS':
          docType = 'application/vnd.ms-xpsdocument';
          break;
        case 'WPS':
          docType = 'application/vnd.ms-works';
          break;
        case 'DOCX':
          docType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'XLSX':
          docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'ODT':
          docType = 'application/vnd.oasis.opendocument.formula';
          break;
        case 'WPD':
          docType = 'application/wordperfect';
          break;
        case 'ESP':
          docType = 'application/octet-stream';
          break;
        case 'HTM':
          docType = 'text/html';
          break;
        case 'MSG':
          docType = 'application/vnd.ms-outlook';
          break;
        case 'JSON':
          docType = 'application/json';
          break;
        default:
          docType = DocumentType;
          break;
      }
      openDocument(orderDocumentBytes, docType, newWindow, WindowFeatures);
    }
  }, [getDocumentDataValue]);

  const actionStatus =
    getPolicyUploadHistoryStatus === 'pending' || getDocumentDataStatus === 'pending' ? 'pending' : 'idle';

  return (
    <>
      <LoadingSpinner status={actionStatus} variant="linear" />
      <div className={classes.uploadsWrap}>
        <PageHeader
          title="History"
          subtitle="A complete record of all policies you have previously uploaded. Track the status and details of uploaded policy schedules and endorsements. Note: Policies may take up to two weeks to appear in the Back Title database."
          menuItems={[
            {
              label: 'Knowledge Base',
              link: KBLinks.policyUpload,
            },
          ]}
          disableMargin="x"
        />
        {rowData?.length >= 0 && (
          <div className={classes.tableDefault}>
            <div className="ag-theme-alpine table-grid history-grid">
              <DataTable
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{
                  cellClass: 'left-align-column',
                  headerClass: 'left-align-header',
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                  filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                  suppressMenu: true,
                  resizable: true,
                }}
                onGridReady={onGridReady}
                components={{
                  customDropdownFilter: FloatingFilterComponent,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default History;
