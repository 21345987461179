import { selectedFilesAmountType } from 'features/orderManagement/types';
import React, { createContext, useContext, useState } from 'react';

interface OrderInfoContextType {
  selectedFilesAmount: selectedFilesAmountType;
  setSelectedFilesAmount: React.Dispatch<React.SetStateAction<selectedFilesAmountType>>;
}

const defaultSelectedFilesAmount: selectedFilesAmountType = {
  calculatedGross: 0,
  calculatedNet: 0,
  netDifference: 0,
};

const OrderInfoContext = createContext<OrderInfoContextType | null>(null);

export const OrderInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedFilesAmount, setSelectedFilesAmount] = useState<selectedFilesAmountType>(defaultSelectedFilesAmount);

  return (
    <OrderInfoContext.Provider value={{ selectedFilesAmount, setSelectedFilesAmount }}>
      {children}
    </OrderInfoContext.Provider>
  );
};

export const useOrderInfo = (): OrderInfoContextType => {
  const context = useContext(OrderInfoContext);
  if (!context) {
    throw new Error('useOrderInfo must be used within an OrderInfoProvider');
  }
  return context;
};
