import { useAuth } from '@agentnet/auth';
import { Badge as MuiBadge } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, ReportProblemOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { FileDataContext } from '../../hooks/FileDataContext';
import { ProfileContext } from '../../hooks/ProfileContext';
import { NavLinkBadgeStatus, NavLinkType } from './NavLinkType';

type NavBadgeProps = { link: NavLinkType; shouldShowDot?: boolean };

const StyledBadge = withStyles((theme) => ({
  root: {
    '&.warning span.MuiBadge-badge': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.idle span.MuiBadge-badge': {
      display: 'none',
    },
  },
  badge: {
    borderRadius: '50%',
    height: '26px',
    width: '26px',
    padding: '0 6px',
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`,
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&[data-variant="dot"]': {
      height: '10px',
      minWidth: '10px',
      padding: '0',
    },
  },
  dot: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.success.main,
    padding: 0,
    transform: `scale(1) translate(-150%, -150%)`,
  },
}))(MuiBadge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: { marginLeft: 'auto' },
    checkCircle: { '& path': { fill: theme.palette.success.main } },
    warningOutlined: { '& path': { fill: theme.palette.warning.main } },
    errorOutlined: { '& path': { fill: theme.palette.error.main } },
  }),
);

const NavBadge = ({ link, shouldShowDot = false }: NavBadgeProps): JSX.Element => {
  const { notification: variant = 'standard', notificationStatus: color, badgeContentFunction } = link;
  const { userFirm } = useContext(ProfileContext) ?? {};
  const { fileId, serviceStatus, fileStatus } = useContext(FileDataContext)?.fileData || {};
  const firmId = userFirm?.firmId ?? '';
  const { getAccessToken } = useAuth();
  const [badgeContent, setBadgeContent] = useState<NavLinkBadgeStatus | number>();
  const classes = useStyles();

  useMemo(() => {
    const fetchBadgeContent = async () => {
      if (badgeContentFunction && userFirm?.firmId && sessionStorage.getItem('fileId')) {
        const accessToken = await getAccessToken();
        const content = await badgeContentFunction(accessToken, { fileId, firmId });
        setBadgeContent(content);
      }
    };
    fetchBadgeContent();
  }, [
    badgeContentFunction,
    userFirm?.firmId,
    fileId,
    serviceStatus?.hasRemittableCpl,
    serviceStatus?.hasActiveJacket,
    serviceStatus?.hasStandAloneEndorsement,
    serviceStatus?.isPricingValid,
    fileStatus,
    window.location.href,
  ]);

  if (badgeContent === 'success' && !shouldShowDot) {
    return <CheckCircleOutlineOutlined className={clsx(classes.badge, classes.checkCircle)} fontSize="small" />;
  } else if (badgeContent === 'warning' && !shouldShowDot) {
    return <ReportProblemOutlined className={clsx(classes.badge, classes.warningOutlined)} fontSize="small" />;
  } else if (badgeContent === 'error' && !shouldShowDot) {
    return <ErrorOutlineOutlined className={clsx(classes.badge, classes.errorOutlined)} fontSize="small" />;
  }

  const hasBadgeContentFn = () => !!badgeContentFunction && badgeContent !== null;

  return (
    <StyledBadge
      badgeContent={shouldShowDot ? null : badgeContent}
      variant={shouldShowDot && hasBadgeContentFn() ? 'dot' : variant}
      color={color}
      className={badgeContent as string}
    />
  );
};

export { NavBadge };
