import { Grid, Typography } from '@material-ui/core';
import SdnCard from 'ui-kit/components/card/SdnCard';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';

import { DateFormat } from 'features/constants';
import moment from 'moment';
import './SdnContent.scss';

const SdnResultComponent = (SdnResultComponentProps: {
  sdnResult: {
    partiesResult: any;
    searchDate: string;
  };
}): JSX.Element => {
  const qaAttrPrefixResult = 'SDNResult';
  const formatDate = (date: string) => {
    return moment(date).format(DateFormat);
  };

  return (
    <>
      <div className="sdn-search-container">
        <AgentNetDivider title="SDN Search Results" />
        <div className="sdn-search-content">
          <Typography variant="h5">Completed {formatDate(SdnResultComponentProps.sdnResult.searchDate)}</Typography>
          <Grid container sm={12} spacing={2} className="mb">
            {SdnResultComponentProps.sdnResult.partiesResult.map(
              (value: { isMatched: boolean; partyName: string }, index: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    key={index}
                    data-testid={
                      value.isMatched === true
                        ? qaAttrPrefixResult + [index + 1] + 'Match'
                        : qaAttrPrefixResult + [index + 1] + 'NoMatch'
                    }
                  >
                    <SdnCard isMatched={value.isMatched} partyName={value.partyName} />
                  </Grid>
                );
              },
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SdnResultComponent;
