import React from 'react';

export function AgentAdvantage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#F5B335" rx="8"></rect>
      <path
        fill="#fff"
        d="M23.52 15.208v6.625a1.6 1.6 0 01-.489 1.177c-.326.327-.718.49-1.177.49H10.188c-.459 0-.851-.163-1.178-.49a1.605 1.605 0 01-.49-1.177v-6.625a2.867 2.867 0 01-.739-1.125c-.173-.458-.177-.958-.01-1.5l.875-2.833a1.84 1.84 0 01.594-.896c.284-.236.614-.354.99-.354h11.583c.375 0 .7.115.979.344.277.229.479.531.604.906l.875 2.833c.167.542.163 1.035-.01 1.48-.174.444-.42.826-.74 1.145zm-5.666-.875c.375 0 .66-.128.854-.385.195-.257.271-.545.23-.865l-.459-2.916h-1.625v3.083c0 .292.097.545.292.76a.92.92 0 00.708.323zm-3.75 0c.32 0 .58-.107.782-.323.2-.215.302-.468.302-.76v-3.083h-1.625l-.459 2.916c-.055.334.018.625.219.875s.462.375.781.375zm-3.708 0c.25 0 .469-.09.656-.27.188-.181.302-.41.344-.688l.458-3.208H10.23l-.833 2.791c-.083.278-.038.577.135.896.174.32.462.48.865.48zm11.25 0c.403 0 .694-.16.875-.479.18-.32.222-.618.125-.896l-.875-2.791h-1.583l.458 3.208c.042.278.156.507.344.688.187.18.406.27.656.27z"
      ></path>
    </svg>
  );
}

export function AgentPrintPro() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#B46118" rx="8"></rect>
      <path
        fill="#fff"
        d="M25.41 16.58l-9-9C16.05 7.22 15.55 7 15 7H8c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM17 25.01L8 16V9h7v-.01l9 9-7 7.02z"
      ></path>
      <path fill="#fff" d="M10.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
    </svg>
  );
}

export function EagleAcademy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#71767B" rx="8"></rect>
      <path
        fill="#fff"
        d="M10.167 16.983v3.334L16 23.5l5.833-3.183v-3.334L16 20.167l-5.833-3.184zM16 8.5l-9.167 5 9.167 5 7.5-4.092v5.759h1.667V13.5L16 8.5z"
      ></path>
    </svg>
  );
}

export function RecordsCenter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#013A6F" rx="8"></rect>
      <path
        fill="#fff"
        d="M22.667 7.667H9.333c-.833 0-1.666.75-1.666 1.666v2.509c0 .6.358 1.116.833 1.408v9.417c0 .916.917 1.666 1.667 1.666h11.666c.75 0 1.667-.75 1.667-1.666V13.25c.475-.292.833-.808.833-1.408V9.333c0-.916-.833-1.666-1.666-1.666zm-4.167 10h-5V16h5v1.667zm4.167-5.834H9.333v-2.5l13.334-.016v2.516z"
      ></path>
    </svg>
  );
}

export function SoftwareIntegrationHub() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#003D66" rx="8"></rect>
      <path
        fill="#fff"
        d="M15 19.17L12.83 17 15 14.83l-1.41-1.42L10 17l3.59 3.59L15 19.17zm3.41 1.42L22 17l-3.59-3.59L17 14.83 19.17 17 17 19.17l1.41 1.42z"
      ></path>
      <path
        fill="#fff"
        d="M23 8h-4.18C18.4 6.84 17.3 6 16 6c-1.3 0-2.4.84-2.82 2H9c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM23 24H9V10h14v14z"
      ></path>
    </svg>
  );
}

export function AgentNetTitleEscrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none">
      <rect width="32" height="32" y="0.5" fill="#2E7EA8" rx="8"></rect>
      <path
        fill="#fff"
        d="M18 11V9h-4v2h4zM8 13v11h16V13H8zm16-2c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H8c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4V9c0-1.11.89-2 2-2h4c1.11 0 2 .89 2 2v2h4z"
      ></path>
    </svg>
  );
}
