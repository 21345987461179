import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import React from 'react';
import { reopenedFileInfoText } from './reportsListConfig';

interface ReopenedFileIconProps {
  iconOnly?: boolean;
}

const ReopenedFileIcon: React.FC<ReopenedFileIconProps> = ({ iconOnly }) => {
  return iconOnly ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={reopenedFileInfoText} enterDelay={300} placement="right" arrow>
        <HistoryOutlinedIcon fontSize="small" htmlColor="#EF6C00"></HistoryOutlinedIcon>
      </Tooltip>
    </div>
  ) : (
    <Grid
      item
      style={{
        margin: pxToRem(0, 24),
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        width: '130px',
        justifyContent: 'space-evenly',
      }}
    >
      <Tooltip title={reopenedFileInfoText} enterDelay={300} placement="right" arrow>
        <HistoryOutlinedIcon htmlColor="#EF6C00" fontSize="small"></HistoryOutlinedIcon>
      </Tooltip>
      Reopened File
    </Grid>
  );
};

export default ReopenedFileIcon;
