import React from 'react';
import NestedRouteLayout from '../NestedRouteLayout';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  serviceContentWrap: {
    '& .routeRoot': {
      height: '100%',
    },
  },
}));

const ServiceContent = ({ routes }: never): JSX.Element => {
  const classes = useStyles();
  return <NestedRouteLayout {...{ routes }} className={classes.serviceContentWrap} />;
};

export default ServiceContent;
