import { pxToRem } from '@fluentsms/agentnet-web-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PartyTypeValue } from 'api/file/constants';
import DomesticAddressField from 'features/files/file-create/DomesticAddress';
import ForeignAddress from 'features/files/file-create/ForeignAddress';
import PersonalIdForm from 'features/files/file-create/PersonalIdForm';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddressFields } from 'ui-kit/form/interfaces/AddressModal';
import { EinField } from 'ui-kit/inputs/EinField';
import { StateSelectField } from 'ui-kit/inputs/StateSelectField';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import { buyerSellerDometicAddressSchema, partyTypesSchema } from 'utilities/validation/schemas';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { FileDataContext, FileDataContextInterface } from '../../../../hooks/FileDataContext';
import { ICreateBuyerSellerRequest } from '../interfaces/CreateBuyerSellerRequest';
import { IPersonalIdentificationFields } from '../interfaces/PersonalInformation';
import { isSelectedEntityOrTrust } from './PartyTypeFormRules';
import './PartyTypesForm.scss';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    asterik: {
      color: theme.palette.error.main,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    ssn: {
      marginRight: theme.spacing(1),
    },
    textItem: {
      padddingLeft: pxToRem(15),
    },
    btn: {
      textTransform: 'uppercase',
      padding: pxToRem(5, 10),
      marginTop: pxToRem(5),
    },
    btnContainer: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    cancelBtn: {
      textDecoration: 'underline',
    },
  }),
);

const removeState = ['BS', 'PR', 'VI'];
export interface PartTypeProps<T> extends IAgentNetBaseFormGroupProps<T> {
  qaAttrPrefix?: string;
  setUpdateValidations?: any;
  updateValidations?: any;
}
export const PartyTypeForm = <T extends ICreateBuyerSellerRequest>({
  value,
  onChange,
  showAllValidation,
  setIsFormValid,
  validationErrors,
  resetVisibility = false,
  setResetVisibility,
  setValidationErrors,
  qaAttrPrefix,
  setUpdateValidations,
  updateValidations,
}: PartTypeProps<T>): JSX.Element => {
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const classes = useStyles();
  const isMarried = value.partyType === PartyTypeValue.married;
  const [initialValidationComplete, setInitialValidationComplete] = useState<boolean>(false);
  const [isAddressFormGroupValid, setIsAddressFormGroupValid] = useState<boolean>(false);
  const [isPersonalIdentificationFormGroupValid, setIsPersonalIdentificationFormGroupValid] = useState<boolean>(false);
  const [isSpousePersonalIdentificationFormGroupValid, setIsSpousePersonalIdentificationFormGroupValid] =
    useState<boolean>(false);
  /* Handle Form Validation */
  const getErrors = async (): Promise<FieldValidationError[]> => {
    if (value.partyType === PartyTypeValue.entity || value.partyType === PartyTypeValue.trust) {
      const nameTaxValue = value.partyType === PartyTypeValue.entity ? { ...value.entity } : { ...value?.trustEstate };

      return (await doValidate(nameTaxValue, partyTypesSchema)) ?? [];
    }
    return [];
  };

  useEffect(() => {
    if (resetVisibility) {
      setResetVisibility(false);
    }
  }, [resetVisibility]);
  const checkErrorAgainstIndividualPartyType = async () => {
    const errs: FieldValidationError[] = await getErrors();
    if (value.partyType === PartyTypeValue.individual) {
      setIsFormValid &&
        setIsFormValid(errs.length === 0 && isAddressFormGroupValid && isPersonalIdentificationFormGroupValid);
    } else {
      setIsFormValid && setIsFormValid(errs.length === 0 && isAddressFormGroupValid);
    }
  };

  const validateErrors = async () => {
    const errs: FieldValidationError[] = await getErrors();
    if (isMarried) {
      setIsFormValid &&
        setIsFormValid(
          errs.length === 0 &&
            isAddressFormGroupValid &&
            isPersonalIdentificationFormGroupValid &&
            isSpousePersonalIdentificationFormGroupValid,
        );
    } else {
      checkErrorAgainstIndividualPartyType();
    }
    setValidationErrors && setValidationErrors(errs);
  };

  const onChangeWithValidation = async (newValue: T) => {
    validateErrors();
    if (onChange) {
      if (newValue?.address?.state === '' || newValue?.address?.stateOrProvince === '') {
        delete newValue.address.state;
        delete newValue.address.stateOrProvince;
      }
      onChange(newValue);
    }
  };
  useEffect(() => {
    if (showAllValidation) onChangeWithValidation({ ...value });
  }, [showAllValidation]);

  useEffect(() => {
    if (!initialValidationComplete) {
      validateErrors();
      setInitialValidationComplete(true);
    }
  }, []);

  useEffect(() => {
    validateErrors();
  }, [
    isAddressFormGroupValid,
    isPersonalIdentificationFormGroupValid,
    isSpousePersonalIdentificationFormGroupValid,
    value,
    showAllValidation,
  ]);
  const { register } = useForm({ defaultValues: { ssn: '123456789' } });

  const renderPersonalIdForMaritalStatus = () => {
    return (
      <Grid item>
        <PersonalIdForm
          disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
          resetVisibility={resetVisibility}
          setUpdateValidations={setUpdateValidations}
          updateValidations={updateValidations}
          setIsValid={setIsPersonalIdentificationFormGroupValid}
          showAllValidation={showAllValidation}
          isCreateFile={false}
          qaAttrPrefix={qaAttrPrefix}
          isForeignAddress={!!value?.address?.isForeignAddress}
          value={
            isMarried
              ? value.couple?.spouse1 ?? ({} as IPersonalIdentificationFields)
              : value.individual ?? ({} as IPersonalIdentificationFields)
          }
          onChange={(newValue: IPersonalIdentificationFields) =>
            isMarried
              ? onChangeWithValidation({
                  ...value,
                  couple: { ...value.couple, spouse1: newValue },
                })
              : onChangeWithValidation({ ...value, individual: newValue })
          }
        />
      </Grid>
    );
  };

  const getTrustOrEntityName = () => {
    if (value.partyType === PartyTypeValue.trust) {
      return value?.trustEstate?.name ?? '';
    } else if (value.partyType === PartyTypeValue.entity) {
      return value?.entity?.name ?? '';
    }
  };

  const getTaxIdValue = () => {
    if (value.partyType === PartyTypeValue.trust) {
      return value.trustEstate?.taxId ?? '__-_______';
    } else if (value.partyType === PartyTypeValue.entity) {
      return value.entity?.taxId ?? '__-_______';
    }
  };

  return (
    <>
      <Grid item className="party-type-form-container">
        <Grid container spacing={3}>
          {value.partyType === PartyTypeValue.married || value.partyType === PartyTypeValue.individual ? (
            renderPersonalIdForMaritalStatus()
          ) : (
            <>
              {value.partyType && (
                <Grid item xs={6}>
                  <AgentNetTextInput
                    disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
                    errs={validationErrors}
                    data-testid={`${qaAttrPrefix}Name`}
                    fullWidth
                    variant="outlined"
                    required
                    id="name"
                    name={'name'}
                    label={'Name'}
                    showValidation={showAllValidation}
                    value={getTrustOrEntityName()}
                    onChange={(e) => {
                      // Only Trust and Entity have this name field
                      let newNameValue;
                      if (value.partyType === PartyTypeValue.trust) {
                        newNameValue = { ...value, trustEstate: { ...value.trustEstate, name: e.target.value } };
                      } else if (value.partyType === PartyTypeValue.entity) {
                        newNameValue = { ...value, entity: { ...value.entity, name: e.target.value } };
                      } else {
                        console.error('Unhandled change of party name');
                      }

                      if (newNameValue) {
                        onChangeWithValidation(newNameValue).then();
                      }
                    }}
                  />
                </Grid>
              )}
              {value.partyType === PartyTypeValue.entity && (
                <>
                  <Grid item xs={3}>
                    <StateSelectField
                      disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
                      label="State of Incorporation"
                      data-testid={`${qaAttrPrefix}StateofIncorporation`}
                      name="StateofIncorporation"
                      removeOption={removeState}
                      shouldDisableClearable={false}
                      value={value?.entity?.incorporatedState ?? ''}
                      onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const incorporatedState = typeof e === 'string' ? e : e.target.value;
                        onChangeWithValidation({
                          ...value,
                          entity: {
                            ...value.entity,
                            incorporatedState: incorporatedState === '' ? undefined : incorporatedState,
                          },
                        });
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid
            item
            xs={3}
            className={isSelectedEntityOrTrust(value) && !value?.address?.isForeignAddress ? 'taxid-container' : 'hide'}
          >
            <EinField
              disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
              inputRef={register}
              label={'Tax ID'}
              name={'taxId'}
              data-testid={`${qaAttrPrefix}TaxID`}
              resetVisibility={resetVisibility}
              value={getTaxIdValue()}
              fullWidth
              onChange={(e) => {
                // Only Trust and Entity have this taxId field
                const currentValue = e.target.value === '__-_______' ? '' : e.target.value;

                let newValue;
                if (value.partyType === PartyTypeValue.trust) {
                  newValue = {
                    ...value,
                    trustEstate: {
                      ...value.trustEstate,
                      taxId:
                        value.trustEstate?.taxId === '**-*******' && currentValue === ''
                          ? value.trustEstate?.taxId
                          : currentValue,
                    },
                  };
                } else if (value.partyType === PartyTypeValue.entity) {
                  newValue = {
                    ...value,
                    entity: {
                      ...value.entity,
                      taxId:
                        value.entity?.taxId === '**-*******' && currentValue === ''
                          ? value.entity?.taxId
                          : currentValue,
                    },
                  };
                } else {
                  console.error('Unhandled change of taxId');
                }
                if (newValue) {
                  onChangeWithValidation(newValue).then();
                }
              }}
              showAllValidation={showAllValidation}
              validationErrors={validationErrors}
            />
          </Grid>

          {value.partyType === PartyTypeValue.married && (
            <Grid item>
              <PersonalIdForm
                disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
                qaAttrPrefix={`${qaAttrPrefix}Spouse`}
                setUpdateValidations={setUpdateValidations}
                updateValidations={updateValidations}
                resetVisibility={resetVisibility}
                setIsValid={setIsSpousePersonalIdentificationFormGroupValid}
                showAllValidation={showAllValidation}
                isForeignAddress={!!value?.address?.isForeignAddress}
                isCreateFile={false}
                value={value.couple?.spouse2 ?? ({} as IPersonalIdentificationFields)}
                onChange={(newValue: IPersonalIdentificationFields) =>
                  onChangeWithValidation({
                    ...value,
                    couple: { ...value.couple, spouse2: newValue },
                  })
                }
              />
            </Grid>
          )}

          <Grid item style={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} className="foreign-address-switch">
                <FormControlLabel
                  control={
                    <Switch
                      data-testid={`${qaAttrPrefix}ForeignAddress`}
                      checked={!!value?.address?.isForeignAddress}
                      onChange={(e) => {
                        let newAddress: AddressFields | undefined = { ...value?.address };
                        if (value?.address?.isForeignAddress) {
                          newAddress = {};
                        } else {
                          newAddress = { ...newAddress, county: '', stateOrProvince: '' };
                        }
                        const newValue = _.cloneDeep({
                          ...value,
                          address: { ...newAddress, isForeignAddress: e.target.checked },
                        });
                        // Remove the ssns and taxids if ts foreign
                        if (e.target.checked) {
                          if (newValue.couple?.spouse1?.ssn) {
                            delete newValue.couple.spouse1.ssn;
                          }
                          if (newValue.couple?.spouse2?.ssn) {
                            delete newValue.couple.spouse2.ssn;
                          }
                          if (newValue.individual?.ssn) {
                            delete newValue.individual.ssn;
                          }
                          if (newValue.entity?.taxId) {
                            delete newValue.entity.taxId;
                          }
                          if (newValue.trustEstate?.taxId) {
                            delete newValue.trustEstate.taxId;
                          }
                        }
                        onChangeWithValidation(newValue).then();
                      }}
                      name="foreign-address"
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">Foreign Address</Typography>}
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {value?.address?.isForeignAddress ? (
                      <ForeignAddress
                        disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
                        setIsValid={setIsAddressFormGroupValid}
                        value={value?.address}
                        qaAttributePrefix={qaAttrPrefix}
                        onChange={(newValue: AddressFields) => {
                          onChangeWithValidation({
                            ...value,
                            address: {
                              ...value.address,
                              ...newValue,
                              state: newValue.stateOrProvince,
                              region: newValue.stateOrProvince,
                              country: newValue.country,
                            },
                          });
                        }}
                      />
                    ) : (
                      <Grid item sm={12}>
                        <DomesticAddressField
                          disabled={fileData?.fileStatus !== 'Open' || fileData?.serviceStatus?.isReopenedByProduct}
                          useGoogleAutocomplete={true}
                          getAllState={value?.address?.stateOrProvince ? false : true}
                          setIsValid={setIsAddressFormGroupValid}
                          schema={buyerSellerDometicAddressSchema}
                          displayRequiredAddressFields={false}
                          showAllValidation={showAllValidation}
                          value={value?.address ?? {}}
                          qaAttributePrefix={qaAttrPrefix}
                          onChange={(newValue: AddressFields) => {
                            onChangeWithValidation({
                              ...value,
                              address: { ...value.address, ...newValue, state: newValue.stateOrProvince },
                            });
                          }}
                          isOutsideJurisdiction={true}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
