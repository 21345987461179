import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AgentNetCheckBox } from 'ui-kit/inputs/Checkbox';

const useStyles = makeStyles((theme) => ({
  mb2: {
    paddingBottom: theme.spacing(1),
  },
  coveredParties: {
    paddingLeft: '12px',
  },
}));

interface UnderwritingCoveredPartiesProps {
  index: number;
  coveredParties: string[];
  cplModCoveredParties: string[];
  cplModCheckBox: string;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
  handleCoveredParties: (index: number, cplModCheckBox: string, coveredParty: string, val: boolean) => void;
}
const UnderwritingCoveredParties: React.FC<UnderwritingCoveredPartiesProps> = ({
  coveredParties,
  cplModCoveredParties,
  index,
  cplModCheckBox,
  isUwrDisabled,
  isDisabled,
  handleCoveredParties,
}: UnderwritingCoveredPartiesProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" className={classes.mb2}>
        Covered Parties{' '}
      </Typography>
      <Grid item sm={12} className={classes.coveredParties}>
        {coveredParties?.map((coveredParty) => (
          <AgentNetCheckBox
            key={coveredParty}
            label={coveredParty}
            //data-testid={qaAttrMap.get(coveredParty.coveredPartyName || '')?.replace(/\s/g, '')}
            value={cplModCoveredParties.includes(coveredParty) ? true : false}
            checkHandler={(val: boolean) => {
              handleCoveredParties(index, cplModCheckBox, coveredParty, val);
            }}
            disabled={isUwrDisabled || isDisabled}
          />
        ))}
      </Grid>
    </>
  );
};
export default UnderwritingCoveredParties;
