import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';
import { ReactNode } from 'react';

export type DrawerTitleProps = {
  title?: string;
  children?: ReactNode;
  className?: string;
  titleProps?: TypographyProps;
};

const useStyles = makeStyles((theme: Theme) => ({
  typography: {
    padding: theme.spacing(4, 4, 3, 4),
  },
}));

const DrawerTitle = ({ title, children, className, titleProps, ...props }: DrawerTitleProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={className} {...props}>
      <Typography
        id="drawer-title"
        data-testid={`drawer-title-${title?.replace(/\s+/g, '-').toLocaleLowerCase()}`}
        variant="h5"
        className={classes.typography}
        {...titleProps}
      >
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default DrawerTitle;
