import DateFnsUtils from '@date-io/date-fns';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, Grid, GridProps, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { format as formatDate } from 'date-fns';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import React, { useContext, useEffect, useState } from 'react';
import { IDomesticAddressFields } from 'ui-kit/form/interfaces/AddressModal';
import StateSelectField from 'ui-kit/inputs/StateSelectField';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import { TransactionTypeValue } from '../../../../api/file/constants';
import {
  getAccountIdByOfficeAndUnderwriterAndState,
  getOfficeOptionsByState,
  getStateOptions,
  getUnderwriterOptionsByOfficeAndState,
} from '../../../../api/profile/profile-utilities';
import AgentNetDropdownSelector, { SelectOption } from '../../../../ui-kit/inputs/AgentNetDropdownSelector';
import CurrencyField from '../../../../ui-kit/inputs/CurrencyField/CurrencyField';
import DateFieldString from '../../../../ui-kit/inputs/DateField/DateFieldString';
import { AgentNetTextInput } from '../../../../ui-kit/inputs/TextField';
import { transactionTypes } from '../../../constants';
import { IGetLenderResponse } from '../../parties/interfaces/CreateLenderRequest';
import './FileInformationForm.scss';
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    asterisk: {
      color: theme.palette.error.main,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    ssn: {
      marginRight: theme.spacing(1),
    },
    textItem: {
      paddingLeft: pxToRem(15),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    validationError: {
      color: theme.palette.error.main,
    },
  }),
);

interface FileInfoCreateFormProps extends IAgentNetBaseFormGroupProps<ICreateFileResponse> {
  ContainerComponentProps?: GridProps;
  isAdmin?: boolean;
}

const FileInfoCreateForm: React.FC<FileInfoCreateFormProps> = ({
  ContainerComponentProps,
  showAllValidation,
  value,
  validationErrors,
  onChange,
}: FileInfoCreateFormProps) => {
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, preferredOffice } = profileCtx;
  const [userFirmPreferences, setUserFirmPreferences] = useState(userFirm);

  useEffect(() => {
    let selectedPreferredOffices = preferredOffice?.filter(
      (ele: any) => ele.FirmID == userFirm?.firmId && ele.IsSelected == 1,
    );
    if (preferredOffice?.length > 0) {
      //setHasPreferredOffice(true);
      if (selectedPreferredOffices?.length === 0) {
        selectedPreferredOffices = preferredOffice?.filter((ele: any) => ele.FirmID == userFirm?.firmId);
      }
      // Filter accounts based on selectedPreferredOffices.accountId
      const selectedoffices = userFirmPreferences?.offices
        ?.map((office: any) => ({
          ...office,
          accounts: office.accounts.filter((account: any) =>
            selectedPreferredOffices?.some(
              (preferredOffice: any) => preferredOffice.FirmLocationJurisdictionID === account.accountId,
            ),
          ),
        }))
        .filter((office: any) => office.accounts.length > 0);
      const firmTemp = userFirm;
      if (firmTemp) firmTemp.offices = selectedoffices;

      setUserFirmPreferences(firmTemp);
    }
  }, []);

  useEffect(() => {
    if (value?.officeId) {
      const options = getUnderwriterOptionsByOfficeAndState(
        userFirmPreferences,
        Number(value.officeId),
        value.stateCode,
      );
      if (options?.length === 1) {
        handleUnderwriterCode(options[0].value);
      } else {
        handleUnderwriterCode('');
      }
    }
  }, [value?.officeId]);

  useEffect(() => {
    if (value?.stateCode) {
      const officeOptions = getOfficeOptionsByState(userFirmPreferences, value.stateCode);
      if (officeOptions?.length === 1) {
        handleOfficeChange(officeOptions[0].value);
      } else {
        handleOfficeChange('');
      }
    }
  }, [value?.stateCode]);

  useEffect(() => {
    if (userFirmPreferences?.offices && !value?.stateCode) {
      const stateOptions = getStateOptions(userFirmPreferences);
      if (stateOptions?.length === 1) {
        handleStateChange(stateOptions[0].value);
      }
    }
  }, [userFirmPreferences?.offices]);

  const handleUnderwriterCode = (val: string) => {
    const underwriterCode = val;
    /* Update the underwriter and the accountId */
    const accountId = getAccountIdByOfficeAndUnderwriterAndState(
      userFirmPreferences,
      Number(value.officeId),
      value.stateCode,
      underwriterCode,
    );
    onChange({ ...value, underwriterCode: underwriterCode, accountId: accountId });
  };

  const handleStateChange = (stateSelection: string) => {
    if (stateSelection) {
      value.stateCode = stateSelection;
    }
    const properties = value.properties?.map((property, index) => {
      if (index === 0) {
        return { ...property, state: stateSelection };
      }
      return property;
    });

    /* In addition to setting the state code, clear the office and underwriter selections */
    onChange({
      ...value,
      stateCode: stateSelection,
      properties: properties,
      officeId: '',
      underwriterCode: '',
    } as IDomesticAddressFields);
  };

  const handleOfficeChange = (val: string) => {
    const officeId = +val;
    /* If there is just one underwriter for this office, use it to set the accountId also */
    const underWriters: SelectOption[] = getUnderwriterOptionsByOfficeAndState(
      userFirmPreferences,
      officeId,
      value.stateCode,
    );
    if (underWriters && underWriters.length === 1) {
      const accountId = getAccountIdByOfficeAndUnderwriterAndState(
        userFirmPreferences,
        officeId,
        value.stateCode,
        underWriters[0].value,
      );
      /* Update the officeId and accountId */
      onChange({ ...value, officeId: +val, accountId: accountId });
    } else {
      /* Update the officeId only */
      onChange({ ...value, officeId: +val });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {value && onChange && (
        <Grid container {...ContainerComponentProps} spacing={3}>
          <Grid item sm={3}>
            <AgentNetTextInput
              fullWidth
              required
              variant="outlined"
              label="File Number"
              id="fileNumber"
              name={'fileNumber'}
              value={value.fileNumber}
              onChange={(e) => onChange({ ...value, fileNumber: e.target.value })}
              errs={validationErrors}
              showValidation={showAllValidation}
              data-testid="CreateFileFileNumber"
            />
          </Grid>

          <Grid item sm={3}>
            {!userFirmPreferences?.offices ? (
              <StateSelectField
                label="State"
                name="stateCode"
                id="stateCode"
                required
                showValidation={showAllValidation}
                errs={validationErrors}
                value={value.stateCode}
                onChange={(e) => {
                  const properties = value.properties?.map((property, index) => {
                    if (index === 0) {
                      return { ...property, state: e };
                    }
                    return property;
                  });
                  onChange({ ...value, stateCode: e, properties: properties } as IDomesticAddressFields);
                }}
                data-testid="CreateFileState"
              />
            ) : (
              <>
                <StateSelectField
                  label="State"
                  name="stateCode"
                  id="stateCode"
                  required
                  stateOptions={getStateOptions(userFirmPreferences).map((e: any) => {
                    return { name: e.name, abbreviation: e.value };
                  })}
                  value={value.stateCode}
                  showValidation={showAllValidation}
                  errs={validationErrors}
                  onChange={(e) => {
                    const properties = value.properties?.map((property, index) => {
                      if (index === 0) {
                        return { ...property, state: e };
                      }
                      return property;
                    });
                    onChange({
                      ...value,
                      stateCode: e,
                      properties: properties,
                      officeId: value.officeId ? value.officeId : '',
                      underwriterCode: value.underwriterCode ? value.underwriterCode : '',
                    } as IDomesticAddressFields);
                  }}
                  data-testid="CreateFileState"
                />
              </>
            )}
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              name="officeId"
              label="Office"
              disabled={!value?.stateCode}
              required
              options={getOfficeOptionsByState(userFirmPreferences, value.stateCode)}
              value={value?.officeId}
              id="officeId"
              menuOption={handleOfficeChange}
              dropdowntype="outlined"
              errs={validationErrors}
              showValidation={showAllValidation}
              data-testid="CreateFileOffice"
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              name="underwriterCode"
              label="Underwriter"
              disabled={!value?.officeId}
              required
              options={getUnderwriterOptionsByOfficeAndState(
                userFirmPreferences,
                Number(value.officeId),
                value.stateCode,
              )}
              value={value?.underwriterCode}
              id="accountId"
              menuOption={handleUnderwriterCode}
              dropdowntype="outlined"
              errs={validationErrors}
              showValidation={showAllValidation}
              data-testid="CreateFileUnderwriter"
            />
          </Grid>
          <Grid item sm={6}>
            <AgentNetDropdownSelector
              name="transactionType"
              label="Transaction Type"
              options={transactionTypes}
              value={value.transactionType}
              id="transactionType"
              menuOption={(val: string) => {
                onChange({ ...value, transactionType: val as TransactionTypeValue });
              }}
              dropdowntype="outlined"
              errs={validationErrors}
              showValidation={showAllValidation}
              data-testid="CreateFileTransactionType"
            />
          </Grid>

          <Grid item sm={3}>
            <CurrencyField
              variant="outlined"
              fullWidth
              label={'Sales Price'}
              id="salesprice"
              name={'salesPrice'}
              errs={validationErrors}
              value={value?.salesPrice}
              max={100000000000}
              allowNegative={false}
              onChange={(e) => onChange({ ...value, salesPrice: +e.target.value })}
              data-testid="CreateFileSalesPrice"
            />
          </Grid>
          <Grid item sm={3}>
            <CurrencyField
              variant="outlined"
              fullWidth
              label={'Loan Amount'}
              id="totalLoanAmount"
              defaultValue={0}
              name={'loanAmount'}
              errs={validationErrors}
              value={value?.loanAmount}
              max={100000000000}
              allowNegative={false}
              onChange={(e) => {
                const selectedAmount = e.target.value;
                // Also update the lender's field
                const lender: IGetLenderResponse = value.lenders ? value.lenders[0] : {};
                const updatedLender = { ...lender, loanAmount: selectedAmount };
                onChange({ ...value, loanAmount: selectedAmount, lenders: [updatedLender] });
              }}
              data-testid="CreateFileLoanAmount"
            />
          </Grid>
          <Grid item sm={3}>
            <DateFieldString
              label="Closing Date"
              id="calender"
              maxDate="12/31/9999"
              value={(() => {
                const dateParsed = Date.parse(value?.closingDate || '');
                return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : null;
              })()}
              onChange={(dateVal: string | null) => {
                value && onChange({ ...value, closingDate: dateVal ? dateVal : null });
              }}
              qaAttribute="CreateFileClosingDate"
              error={showAllValidation && validationErrors?.some((valError) => valError.field === 'closingDate')}
            />
          </Grid>
        </Grid>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default FileInfoCreateForm;
