import CalculatorIcon from 'ui-kit/icons/Calculator';

const CalculatorRenderer = (props: any) => (
  <div
    data-testid={`${props?.tabName}Calculator${props?.node?.id}Icon`}
    style={{ height: '20px' }}
    onClick={props?.onClick}
  >
    {CalculatorIcon({ fontSize: 'small' })}
  </div>
);
export default CalculatorRenderer;
