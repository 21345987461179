import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Divider, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Remove } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import AgentNetButton from '../button/AgentNetButton';
import './AgentNetDivider.scss';

export type CustomDividerProps = {
  title?: string;
  subtitle?: React.ReactNode;
  subtitleMuted?: boolean;
  message?: string;
  buttonName?: string;
  buttonName2?: string;
  buttonNameQaAttr?: string;
  buttonName2QaAttr?: string;
  toggleQaAttr?: string;
  primaryButtonName?: string;
  primaryButtonQaAttr?: string;
  primaryButtonDisable?: boolean;
  customClass?: string;
  onClick?: any;
  onClick2?: any;
  onClickPrimary?: any;
  disableButton?: boolean;
  status?: 'open' | 'error' | 'cancelled' | 'closed' | 'pending' | 'void' | 'reported';
  disablePaddingX?: boolean;
  disablePaddingY?: boolean;
  disablePadding?: boolean;
  typoVariant?: 'h1' | 'h2' | 'h3' | 'h4';
  onSwitch?: (e: any) => void;
  switchValue?: boolean;
  loader?: boolean;
  id?: string;
  disableExpandLogic?: boolean;
  titleAsterisk?: boolean;
  primaryButtonsize?: any;
  divider?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 100,
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.primary.contrastText,
      display: 'block',
      padding: 0,
    },
    dashed: {
      borderBottom: `2px dashed ${theme.palette.divider} `,
      backgroundColor: 'transparent',
      margin: `10px 0 0 ${theme.spacing(2)}px`,
    },
    spacing: {
      display: 'none',
    },
    dividerInset: {
      color: theme.palette.action.disabledBackground,
    },
    headerTypography: {
      ...theme.typography.h3,
      position: 'relative',
      display: 'flex',
      color: theme.palette.secondary.main,
      top: pxToRem(5),
      margin: pxToRem(0, 0, 15),
    },
    subheaderTypography: {
      display: 'flex',
      color: theme.palette.text.primary,
      padding: 0,
      margin: 0,
    },
    button: {
      margin: pxToRem(0, 0, 0, 16),
    },
    buttonPrimary: {
      margin: pxToRem(0, 0, 0, 16),
    },
    buttonNoMargin: {
      margin: pxToRem(0, 0, 0, 24),
    },
    innerWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    disablePadding: {
      padding: 0,
    },
    disablePaddingX: {
      padding: `${theme.spacing(3)}px 0`,
    },
    disablePaddingY: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    openPendingCountContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.contrastText,
      zIndex: 3,
      '& > div': {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        fontSize: pxToRem(14),
        letterSpacing: pxToRem(0.1),
        lineHeight: pxToRem(12),
      },
      '& button.disabled': {
        cursor: 'pointer',
        color: theme.palette.action.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    disabled: {
      cursor: 'pointer',
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
    required: {
      color: theme.palette.error.main,
    },
    p0: {
      padding: 0,
      fontSize: pxToRem(18),
      marginLeft: '8px',
    },
    w100: {
      width: '100%',
    },
    ml3: {
      marginLeft: '24px',
    },
    title: {},
    subtitle: {
      '& > a': {
        color: theme.palette.primary.main,
      },
    },
    subtitleActive: {
      alignItems: 'flex-start',
    },
    subtitleMuted: {
      color: theme.palette.text.secondary,
    },
    titleAsterisk: {
      color: theme.palette.error.dark,
      fontSize: pxToRem(14),
      fontWeight: 600,
      position: 'relative',
      top: '-5px',
    },
  }),
);

export const AgentNetDivider: React.FC<CustomDividerProps> = ({
  title,
  subtitle,
  subtitleMuted,
  buttonName,
  buttonName2,
  primaryButtonName,
  primaryButtonQaAttr = '',
  primaryButtonDisable,
  buttonNameQaAttr,
  buttonName2QaAttr,
  toggleQaAttr,
  customClass,
  onClick,
  onClick2,
  onClickPrimary,
  message,
  disableButton = false,
  status,
  disablePaddingX,
  disablePaddingY,
  disablePadding,
  typoVariant,
  onSwitch,
  switchValue,
  loader = false,
  id,
  disableExpandLogic,
  titleAsterisk,
  primaryButtonsize,
  divider = false,
}: CustomDividerProps) => {
  const className = useStyles();
  const formatId = (e: string) => {
    const clean = e.replace(/[^a-zA-Z0-9s]/g, '');
    const formatted = clean.replace(/\s+/g, '-').toLowerCase();
    return formatted;
  };

  const [expandDisabled, setExpandDisabled] = useState(false);
  const [collapseDisabled, setCollapseDisabled] = useState(false);

  const handleButtonClick = () => {
    if (buttonName && buttonName === 'Close All' && !disableExpandLogic) {
      setCollapseDisabled(true);
      setExpandDisabled(false);
    }
    onClick();
  };

  const handleButton2Click = () => {
    if (buttonName2 && buttonName2 === 'Expand All' && !disableExpandLogic) {
      setExpandDisabled(true);
      setCollapseDisabled(false);
    }
    onClick2();
  };

  return (
    <div className={clsx(className.w100, `<agentnetdivider2${customClass ?? ''}`)}>
      <div
        className={clsx(className.innerWrap, {
          [className.subtitleActive]: subtitle,
          [className.disablePaddingX]: disablePaddingX,
          [className.disablePaddingY]: disablePaddingY,
          [className.disablePadding]: disablePadding,
        })}
      >
        <div>
          {title && (
            <Typography
              id={id ? id : undefined}
              data-component={id ? id : formatId(title + 'dividerh1')}
              className={className.title}
              variant={typoVariant ? typoVariant : 'h2'}
            >
              {title}
              {titleAsterisk && <span className={className.titleAsterisk}>*</span>}
              {onSwitch ? (
                <Switch
                  checked={switchValue ? true : false}
                  onChange={(e) => {
                    onSwitch && onSwitch(e);
                  }}
                  value={switchValue}
                  size="small"
                  className={className.ml3}
                  {...(toggleQaAttr ? { 'data-testid': toggleQaAttr } : {})}
                />
              ) : (
                ''
              )}
              {status && (
                <span className={`status status-${status}`}>
                  {status[0].toUpperCase()}
                  {status.slice(1)}
                </span>
              )}
            </Typography>
          )}
          {subtitle && (
            <Typography
              className={clsx(className.subtitle, { [className.subtitleMuted]: subtitleMuted })}
              variant="body2"
            >
              {subtitle}
            </Typography>
          )}
        </div>

        <div className={className.openPendingCountContainer}>
          {message && (
            <div>
              {message === '*required' ? (
                <>
                  <span className={className.required}>*</span> required
                </>
              ) : (
                message
              )}
            </div>
          )}
          {buttonName && (
            <AgentNetButton
              color="primary"
              size="medium"
              variant={buttonName === 'Add Document(s)' ? 'outlined' : 'text'}
              className={className.button}
              onClick={handleButtonClick}
              disabled={disableButton || collapseDisabled}
              {...(buttonNameQaAttr ? { 'data-testid': buttonNameQaAttr } : {})}
            >
              {buttonName}
              {buttonName === 'Close All' && <Remove fontSize="small" className={className.p0} />}
            </AgentNetButton>
          )}

          {buttonName2 && (
            <AgentNetButton
              color="primary"
              size="medium"
              variant={'text'}
              className={className.button}
              onClick={handleButton2Click}
              disabled={disableButton || expandDisabled}
              {...(buttonName2QaAttr ? { 'data-testid': buttonName2QaAttr } : {})}
            >
              {buttonName2}
              {buttonName2 === 'Expand All' && <Add fontSize="small" className={className.p0} />}
            </AgentNetButton>
          )}

          {primaryButtonName && (
            <AgentNetButton
              color="primary"
              size={primaryButtonsize ? primaryButtonsize : 'medium'}
              variant="contained"
              className={className.buttonPrimary}
              onClick={onClickPrimary}
              disabled={primaryButtonDisable}
              {...(primaryButtonQaAttr ? { 'data-testid': primaryButtonQaAttr } : {})}
              loading={loader}
            >
              {primaryButtonName}
            </AgentNetButton>
          )}
        </div>
      </div>

      {divider ? (
        <div className={className.disablePaddingY}>
          <Divider />
        </div>
      ) : null}
    </div>
  );
};

export default AgentNetDivider;
