import { HashUtils } from '@agentnet/utils';
import { CellLink, DataTable } from '@fluentsms/agentnet-web-components';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { GridApi, GridReadyEvent, ICellRendererParams, RowNode, ValueFormatterParams } from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import { GetServiceOrdersResult } from 'api/dashboards/service-orders';
import { KBLinks } from 'features/constants';
import { filterParams } from 'features/Remittance/reportsListConfig';
import moment from 'moment';
import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import { dateTooltipValue } from 'utilities/utilities';

interface StatusDashboardProps {
  inProcess?: boolean;
  orders: GetServiceOrdersResult[];
  title: string;
  onBackClick: MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles(() =>
  createStyles({
    countDisplay: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    heading: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 220,
      justifyContent: 'center',
    },
    gridContainer: {
      paddingBottom: '80px',
    },
  }),
);

export const StatusDashboard: FC<StatusDashboardProps> = ({
  inProcess = false,
  orders,
  title,
  onBackClick,
}: StatusDashboardProps) => {
  const classes = useStyles();
  const gridApiRef = useRef<GridApi | null>(null);
  const [rowCountSelected, setRowCountSelected] = useState<number>(0);
  const [displayedRowCount, setDisplayedRowCount] = useState<number | undefined>(undefined);

  const onGridReady = (params: GridReadyEvent): void => {
    gridApiRef.current = params.api;
  };

  const onSelectionChanged = () => {
    if (gridApiRef.current) {
      const selectedRows = gridApiRef.current.getSelectedRows();
      setRowCountSelected(selectedRows.length);
    }
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(gridApiRef.current?.getDisplayedRowCount());
  };

  const handleExport = (): void => {
    if (gridApiRef.current) {
      const gridApi = gridApiRef.current;
      const allColumns = gridApi.getAllGridColumns();
      const columnsToExport = allColumns.filter((col) => col.getColId() !== '0');
      gridApi.exportDataAsCsv({
        onlySelected: true,
        columnKeys: columnsToExport.map((col) => col.getColId()),
      });
    }
  };

  const headerCheckboxRenderer = (): JSX.Element => {
    return <input type="checkbox" data-testid={`${'StatusDashboard'}AllCheckbox`} />;
  };
  const rowCheckboxRenderer = (params: { node: RowNode }): JSX.Element => {
    return <input type="checkbox" data-testid={`${'StatusDashboard'}File${params.node}Checkbox`} />;
  };

  const handleClearAll = (): void => {
    if (gridApiRef.current) {
      const gridApi = gridApiRef.current;
      gridApi.deselectAll();
      setRowCountSelected(0);
    }
  };

  const agGridConfig: AgGridReactProps = {
    rowData: orders ?? [],
    columnDefs: [
      {
        field: '',
        width: 50,
        maxWidth: 50,
        filter: false,
        floatingFilter: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: 'left',
        headerComponent: headerCheckboxRenderer,
        cellRenderer: rowCheckboxRenderer,
        resizable: false,
      },
      {
        headerName: 'File Number',
        field: 'FileNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'FileNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Service Order No.',
        field: 'OrderNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'OrderNumber',
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${HashUtils.encode(row.data.FileId)}/service-orders`,
        }),
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Office',
        field: 'OfficeName',
        filter: 'agTextColumnFilter',
        tooltipField: 'OfficeName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 256,
        minWidth: 256,
      },
      {
        headerName: 'Property State',
        field: 'PropertyState',
        filter: 'agTextColumnFilter',
        tooltipField: 'PropertyState',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Date Submitted',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'SubmittedDate',
        cellRenderer: (params: ICellRendererParams) => {
          return params?.data?.SubmittedDate?.length > 0 ? params?.data?.SubmittedDate.substring(0, 10) : '';
        },
        tooltipValueGetter: dateTooltipValue,
        sort: 'desc',
      },
      {
        headerName: inProcess ? 'ETA' : 'Date Delivered',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: inProcess ? 'ETA' : 'DateDelivered',
        cellRenderer: ({ value }: ICellRendererParams) => moment(value).format('MM/DD/YYYY'),
        tooltipValueGetter: dateTooltipValue,
        sort: 'desc',
      },
      {
        headerName: 'Transaction Type',
        field: 'TransactionType',
        filter: 'agSetColumnFilter',
        tooltipField: 'TransactionType',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Buyer',
        field: 'Buyer',
        filter: 'agTextColumnFilter',
        tooltipField: 'Buyer',
        suppressHeaderFilterButton: true,
        width: 256,
        minWidth: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Product Name',
        field: 'ProductName',
        filter: 'agTextColumnFilter',
        tooltipField: 'ProductName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Business Segment',
        field: 'BusinessSegment',
        filter: 'agSetColumnFilter',
        tooltipField: 'BusinessSegment',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Property Address',
        field: 'PropertyAddress',
        filter: 'agTextColumnFilter',
        tooltipField: 'PropertyAddress',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Contact Name',
        field: 'ContactName',
        filter: 'agTextColumnFilter',
        tooltipField: 'ContactName',
        suppressHeaderFilterButton: true,
        width: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Seller',
        field: 'Seller',
        filter: 'agTextColumnFilter',
        tooltipField: 'Seller',
        suppressHeaderFilterButton: true,
        width: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
    onSelectionChanged: onSelectionChanged,
    onFilterChanged: onFilterChanged,
  };

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  return (
    <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12}>
          <AgentNetButton
            variant="text"
            size="small"
            startIcon={<ArrowBack />}
            onClick={onBackClick}
            data-testid="backToUserList"
            color="primary"
          >
            Back To Dashboard
          </AgentNetButton>
        </Grid>
        <Grid item xs={12}>
          <PageHeader
            className={classes.heading}
            divider={false}
            menuItems={[
              {
                label: 'Knowledge Base',
                link: KBLinks.jacketsCreate,
              },
            ]}
            title={title}
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable {...agGridConfig} />

          <div className={classes.countDisplay}>
            <Typography variant="body2">
              Showing {displayedRowCount ?? orders.length} of {orders.length} orders
            </Typography>
            <Typography variant="body2">Selecting {rowCountSelected} orders for Export</Typography>
          </div>
        </Grid>
      </Grid>
      {rowCountSelected > 0 && (
        <BottomAppBar
          primaryActionLabel="Export"
          cancelLabel="Clear All"
          onPrimaryClick={handleExport}
          onCancel={handleClearAll}
        />
      )}
    </>
  );
};
