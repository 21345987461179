import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Container, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../icons/Close';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: pxToRem(18, 24),
      borderBottom: `1px solid #c4c4c4`,
      width: 'inherit',
      justifyContent: 'space-between',
      position: 'fixed',
      zIndex: 1201,
      backgroundColor: 'white',
    },
    drawerCloseIcon: {
      padding: '4px 0 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:disabled': {
        opacity: 0.6,
      },
      '& svg': {
        width: pxToRem(18),
        height: 'auto',
      },
    },
  }),
);

export interface FormHeaderProps {
  text: string;
  handleClose?: () => void;
  disableCrossIconClick?: boolean;
  crossQAPrefix?: string;
}

export const FormHeader = ({
  text,
  handleClose,
  disableCrossIconClick = false,
  crossQAPrefix = '',
}: FormHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Container className={`${classes.root} form-header-container`}>
      <Typography className="form-header" variant="h3" color="textSecondary">
        {text}
      </Typography>
      <IconButton
        onClick={handleClose}
        data-testid={crossQAPrefix + 'Close'}
        className={classes.drawerCloseIcon}
        disabled={disableCrossIconClick}
        disableFocusRipple
        disableRipple
      >
        <CloseIcon fontSize="small" color="secondary" />
      </IconButton>
    </Container>
  );
};
