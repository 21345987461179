import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircleOutline, DeleteOutlineTwoTone } from '@material-ui/icons';
import { ColDef } from 'ag-grid-enterprise';
import { searchFirmByDetails } from 'api/manageUsers/manage-users-api';
import clsx from 'clsx';
import useAsync from 'hooks/useAsync';
import { useEffect, useMemo, useRef, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent'; // Import the FormDrawerComponent
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { firmStatus } from '../../../features/constants';

interface OptionType {
  id?: string | number;
  name: string;
  default?: boolean;
  officeName?: string;
  isTitleEscrow?: boolean;
  status?: string;
  firmIdArray?: any;
}
interface AutocompleteChecklistProps {
  loggedUserRoleId?: number | undefined;
  options: OptionType[];
  label: string;
  onChange: (value: any) => void;
  onDelete?: (index: any) => void;
  value?: OptionType[];
  dialogText?: string;
  dialogTitle?: string;
  qaAttrPrefix?: string;
  qaBtnprefix?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '99%',
      padding: '0 0 24px',
    },
    tableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: pxToRem(14),
    },
    tableHeaderCell: {
      padding: pxToRem(12),
    },
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: pxToRem(12),
      display: 'flex',
      alignItems: 'center',
    },
    deleteRow: {
      padding: 0,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    deleteCell: {
      justifyContent: 'space-between',
    },
    ctaBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      width: '98.8%',
      alignItems: 'center',
      height: 'auto',
      whiteSpace: 'nowrap',
    },
    gridContainer: {
      height: 'calc(100vh - 200px) !important',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
      marginLeft: theme.spacing(2.5),
    },
    rowHover: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const AutocompleteChecklist = ({
  loggedUserRoleId,
  options,
  label,
  onChange,
  onDelete,
  value,
  dialogText,
  dialogTitle,
  qaAttrPrefix,
  qaBtnprefix,
}: AutocompleteChecklistProps) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(value || []);
  const [dialog, setDialog] = useState<DialogInterface>({ show: false, id: undefined });
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer visibility
  const [gridApi, setGridApi] = useState<any>(null);
  const gridRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [firmValue, setFirmValue] = useState<string>('');
  const [firmNameDropdown, setfirmNameDropdown] = useState<any>([{ name: '', value: '' }]);
  const firmSearchInput = {
    firmName: null,
    status: null,
    officeAddress: null,
    officeName: null,
  };
  const [firmSearchModel, setFirmSearchModel] = useState<any>(firmSearchInput);
  const { getAccessToken } = useAuth();
  const [firmIdArray, setFirmId] = useState<number[]>([]);

  interface DialogInterface {
    show: boolean;
    id: any;
  }

  const handleFirmNameClick = (
    firmName: string,
    firmId: number,
    office: string,
    isTitleEscrow: boolean,
    status: string,
  ) => {
    setFirmValue(firmName);
    setDrawerOpen(false);
    setFirmId((prevFirmIds) => {
      if (!prevFirmIds.includes(firmId)) {
        return [...prevFirmIds, firmId];
      }
      return prevFirmIds;
    });
    handleChange([
      {
        name: firmName,
        id: firmId,
        officeName: office,
        isTitleEscrow: isTitleEscrow,
        status: status,
        firmIdArray: firmIdArray,
      },
    ]);
    setfirmNameDropdown([{ name: firmName, value: firmName }]);
  };

  const firmNameCellRenderer = (params: any) => {
    return (
      <span
        style={{ color: '#0074CA', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() =>
          handleFirmNameClick(
            params.value,
            params.data.firmId,
            params.data.officeName,
            params.data.isTitleEscrow,
            params.data.status,
          )
        }
      >
        {params.value}
      </span>
    );
  };

  const firmColumnsConfig: ColDef[] = [
    {
      headerName: '',
      filter: false,
      minWidth: 60,
      maxWidth: 60,
      cellRenderer: (params: any) => {
        const isTrue = params.data.isTitleEscrow;
        if (isTrue) {
          return (
            <span>
              <CheckCircleOutline fontSize="small" style={{ color: 'green', paddingTop: 8 }} />
            </span>
          );
        } else {
          return <span></span>;
        }
      },
      tooltipValueGetter: () => {
        return 'TitleEscrow Firm';
      },
    },

    {
      headerName: 'Firm Name',
      field: 'firmName',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: firmNameCellRenderer,
      tooltipField: 'firmName',
    },
    {
      headerName: 'Office Name',
      field: 'officeName',
      sortable: true,
      filter: 'agTextColumnFilter',
      tooltipField: 'officeName',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filter: 'agSetColumnFilter',
      maxWidth: 200,
      tooltipField: 'status',
      filterParams: {
        values: firmStatus.map((status) => status),
      },
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: true,
      filter: 'agTextColumnFilter',
      tooltipField: 'address',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      defaultValue: '-',
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const fetchFirms = async (): Promise<any[]> => {
    const token = await getAccessToken();
    const response = await searchFirmByDetails(token, firmSearchModel);
    return response;
  };

  const {
    execute: executeGetFirms,
    value: getFirmsValue,
    status: getFirmsStatus,
    errors: getFirmsError,
  } = useAsync<any[]>(fetchFirms, false);

  useEffect(() => {
    executeGetFirms().then();
  }, [firmSearchModel]);

  const actionStatus = getFirmsStatus == 'pending' ? 'pending' : 'idle';

  useEffect(() => {
    if (Array.isArray(getFirmsValue)) {
      const filterNames = new Set(value?.map((option) => option.name));
      const filteredFirms = getFirmsValue.filter((firm: any) => !filterNames.has(firm.firmName));
      setRowData(filteredFirms);
    } else {
      setRowData([]);
    }

    if (gridRef.current?.api) {
      if (rowData.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  }, [getFirmsValue, value]);

  const modalConfirmation = (e: OptionType[]) => {
    setDialog({ show: true, id: e });
  };

  const handleDelete = (e: any) => {
    const newOptions = selectedOptions.filter((option) => option.name !== e.name);
    setSelectedOptions(newOptions);
    onDelete && onDelete(e);
    onChange && onChange(newOptions);
    setDialog({ show: false, id: undefined });
  };

  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }

    const filterModel = gridApi.getFilterModel() ?? {};
    const payload: any = {
      ...firmSearchInput,
    };
    if (filterModel?.firmName) {
      payload.firmName = filterModel?.firmName.filter;
    }
    if (filterModel?.address) {
      payload.officeAddress = filterModel?.address.filter;
    }
    if (filterModel?.officeName) {
      payload.officeName = filterModel?.officeName.filter;
    }
    if (filterModel?.status) {
      let statusFilter = '';
      filterModel?.status?.values?.map((item: any) => {
        if (item !== null) statusFilter = statusFilter + ',' + item;
      });
      statusFilter = statusFilter.slice(1);
      if (statusFilter === 'Active,Inactive' || statusFilter === 'Inactive,Active') payload.status = null;
      else if (statusFilter === 'Active') payload.status = true;
      else if (statusFilter === 'Inactive') payload.status = false;
      else if (statusFilter === 'Null') payload.status = null;
      else payload.status = null;
    }
    setFirmSearchModel(payload);
    gridRef.current?.api?.showLoadingOverlay();
  };

  const handleChange = (newValue: OptionType[]) => {
    setSelectedOptions((prevSelectedOptions) => {
      const existingOptionNames = new Set(prevSelectedOptions.map((option) => option.name));
      const filteredNewValues = newValue.filter((option) => !existingOptionNames.has(option.name));
      const mergedOptions = [...prevSelectedOptions, ...filteredNewValues];

      onChange && onChange(mergedOptions);
      return mergedOptions;
    });
    const payload: any = {
      ...firmSearchInput,
    };
    payload.firmName = '';
    setFirmSearchModel(payload);
  };

  const onGridReady = (params: any) => {
    if (actionStatus == 'pending') {
      gridRef.current?.api.showLoadingOverlay();
    } else {
      gridRef.current?.api.hideOverlay();
    }
    setGridApi(params.api);
  };

  const handleAddFirmClick = () => {
    setDrawerOpen(true); // Open the drawer when the button is clicked
    const selectedNames = new Set(selectedOptions.map((option) => option.name));
    const filteredFirms = rowData.filter((firm: any) => !selectedNames.has(firm.firmName));
    setRowData(filteredFirms);
  };

  const handleDismiss = () => {
    const payload: any = {
      ...firmSearchInput,
    };
    payload.firmName = '';
    setFirmSearchModel(payload);
    setDrawerOpen(false); // Close the drawer
  };

  return (
    <>
      {dialog.id && (
        <AgentNetConfirmationDialog
          onConfirm={() => handleDelete(dialog.id)}
          onDismissAction={() => setDialog({ show: false, id: undefined })}
          open={dialog.show}
          dialogTitle={dialogTitle}
          dialogBtnContent="Yes, Continue"
          dialogText={dialogText}
          qaAttrPrefix={qaAttrPrefix}
        />
      )}
      <div className={classes.ctaBtn}>
        {loggedUserRoleId != 100 && (
          <AgentNetButton
            variant="contained"
            color="primary"
            size="small"
            data-testid={qaBtnprefix}
            onClick={handleAddFirmClick} // Open the drawer on button click
          >
            Add {label}
          </AgentNetButton>
        )}
      </div>
      <div className={classes.table}>
        <Grid container className={classes.tableHeader}>
          <Grid item sm={1} className={classes.tableHeaderCell}></Grid>
          <Grid item sm={11} className={classes.tableHeaderCell}>
            Firm Name
          </Grid>
        </Grid>
        <Grid container>
          {value?.map((e: any, i) => (
            <Grid container key={i} className={clsx(classes.tableRow)}>
              <Grid item sm={1} className={clsx(classes.tableCell)}>
                {e.isTitleEscrow && (
                  <span>
                    <Tooltip title={'Title & Escrow Firm'}>
                      <CheckCircleOutline fontSize="small" style={{ color: 'green', paddingTop: 8 }} />
                    </Tooltip>
                  </span>
                )}
              </Grid>
              <Grid item sm={10} className={clsx(classes.tableCell)}>
                <Typography variant="body2">{e.name}</Typography>
              </Grid>
              <Grid item sm={1} className={clsx(classes.tableCell, classes.deleteCell)}>
                {e.id != 0 && i !== 0 && (
                  <IconButton
                    className={classes.deleteRow}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    aria-label="delete document"
                    onClick={() => modalConfirmation(e)}
                    data-testid={qaBtnprefix + 'Close' + i}
                  >
                    {loggedUserRoleId != 100 ? (
                      <DeleteOutlineTwoTone style={{ color: '#0074CA' }} fontSize="large" />
                    ) : null}
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
      {drawerOpen && (
        <FormDrawerComponent
          title="Add Firm"
          open={drawerOpen}
          disabled={false}
          hidePrimaryAction={true}
          onDismissAction={handleDismiss}
          onSecondaryAction={handleDismiss}
        >
          <div className={`ag-theme-alpine ${classes.gridContainer}`}>
            <DataTable
              rowData={rowData}
              columnDefs={firmColumnsConfig}
              onGridReady={onGridReady}
              ref={gridRef}
              rowClass={classes.rowHover}
              defaultColDef={defaultColDef}
              gridOptions={{ suppressRowClickSelection: true }}
              onFilterChanged={onFilterChanged}
            />
          </div>
        </FormDrawerComponent>
      )}
    </>
  );
};

export default AutocompleteChecklist;
