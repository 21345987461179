import { useAuth } from '@agentnet/auth';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { ButtonGroup, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ExpandMoreOutlined, KeyboardArrowRightTwoTone } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ColDef } from 'ag-grid-enterprise';
import { getAutoReporting, postAutoReporting, saveAutoReporting } from 'api/autoReporting-api';
import clsx from 'clsx';
import { format as formatDate } from 'date-fns';
import { KBLinks } from 'features/constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { AgentNetCheckBox, AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';
import { AutoReportingSchema, AutoReportingSchemaOffice } from 'utilities/validation/schemas/auto-reporting-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { filterParams } from './autoReportingConfig';
import {
  dayAfterPolicyDate,
  daysAfterPolicyDate,
  frequency,
  frequencyFirm,
  isCplOnly,
  isFileByFile,
  officeLevelAutoReportingStatus,
  reportDays,
} from './autoReportingConstants';
import { AutoReportingResponses } from './types';

const autoReporting = (): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      tableDefault: {
        marginTop: '0px !important',
        height: `calc(100vh - ${selectedRows?.length ? '344px' : '296px'}) !important`,
        width: 'auto !important',
      },
      divStyle: {
        top: '5px',
        fontSize: '12px',
      },
      divFileInfo: {
        fontSize: '14px',
        marginTop: theme.spacing(1),
        width: '100%',
      },
      divFileInfoNext: {
        fontSize: '14px',
        width: '100%',
      },
      officeLevelWrap: {
        paddingLeft: theme.spacing(0.1),
        paddingTop: theme.spacing(0.1),
      },
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      spaceElements: {
        marginBottom: theme.spacing(0),
      },
      paddingX: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        width: '100%',
      },
      firm: {
        marginLeft: theme.spacing(2),
      },
      lable: {
        fontSize: '10px',
      },
      office: {
        marginLeft: theme.spacing(2),
      },
      toggle: {
        marginLeft: theme.spacing(3),
      },
      toggleOffice: {
        padding: '05px -10px -10px -10px',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(-5),
      },
      formContainer: {
        marginTop: theme.spacing(1),
      },
      formContainerOffice: {
        padding: '-3px -5px -6px -10px',
        marginTop: theme.spacing(2),
      },
      rowHover: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      dateDropDown: {
        width: '97%',
      },
      invoiceType: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
      },
      expandInfoIcon: {
        '&:hover': {
          cursor: 'pointer',
        },
        color: '#4B77D1',
      },
      scrollableContainer: {
        overflow: 'auto', // Enables both horizontal and vertical scrollbars
        height: '100%',
        width: '100%',
      },
    }),
  );
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const useProfile: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, prismUserProfile, profileStatus } = useProfile;
  const Email = prismUserProfile?.email ?? '';
  const firmId = userFirm?.firmId ?? '';
  const [officeLevelAutoReporting, setOfficeLevelAutoeporting] = useState<any>(null);
  const [offices, setOffices] = useState<any>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const gridRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [daysAfterPolicyDateDropDown, setdaysAfterPolicyDate] = useState<any>();
  const [frequencyDropdown, setfrequencyDropdown] = useState<any>(null);
  const { addSnackbarMessage } = useSnackBars();
  const [reportDay, setReportDay] = useState<any>(null);
  const [email, setEmail] = useState<any>(Email);
  const [checkBoxValue, setCheckBox] = useState(false);
  const [startDate, setStartDate] = useState<string>('');
  const [fileByFileCheckBoxFirm, setFileByFileCheckBoxFirm] = useState(false);
  const [firmLevelAutoReporting, setFirmLevelAutoReporting] = useState<any>(null);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const { addGlobalMsg } = useGlobalMessages();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNotSameData, setIsNotSameData] = useState(false);
  const [autoReportingRuleId, setAutoReportingRuleId] = useState<any>(0);
  const [officeId, setOfficeId] = useState<any>(0);
  const [daysAfterPolicyDateDropDownOffice, setdaysAfterPolicyDateOffice] = useState<any>();
  const [frequencyDropdownOffice, setfrequencyDropdownOffice] = useState<any>(null);
  const [reportDayOffice, setReportDayOffice] = useState<any>(null);
  const [emailOffice, setEmailOffice] = useState<any>(Email);
  const [checkBoxValueOffice, setCheckBoxOffice] = useState(false);
  const [fileByFileCheckBoxOfficeOffice, setFileByFileCheckBoxOfficeOffice] = useState(false);
  const [startDateOffice, setStartDateOffice] = useState<string>('');
  const [shouldShowValidationOffice, setShouldShowValidationOffice] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [editButtionClicked, setEditButtionClicked] = useState(false);
  const [validationErrorsOffice, setValidationErrorsOffice] = useState<FieldValidationError[]>([]);
  const [savingAutoReporting, setSavingAutoReporting] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const firmFilters: RadioProps[] = [
    { label: 'Firm', value: 'firm', dataTestId: 'FirmSelectFirm' },
    { label: 'Office', value: 'office', dataTestId: 'FirmSelectOffice' },
  ];
  const [firmFilter, setFirmFilter] = useState<RadioProps>(firmFilters[0]);
  const [showFirm, setshowFirm] = useState(false);
  const [Activate, setActivate] = useState(true);
  const [ActivateOffice, setActivateOffice] = useState(true);
  const [allSelectedRowStatusSame, setAllSelectedRowStatusSame] = useState(false);
  const [OpenModel, setOpenModel] = useState(false);
  const [OpenModelFirm, setOpenModelFirm] = useState(false);
  const [ExpandValue, setExpandValue] = useState(false);
  const setAutoReportingDefaultValue = (isFirmLevel: boolean) => {
    const date = getNearestFuture25thDate();
    const frequency = 'Monthly (25th)';
    const daysAfterPolicyDate = '30';
    if (isFirmLevel) {
      setEmail(Email);
      setfrequencyDropdown(frequency);
      setdaysAfterPolicyDate(daysAfterPolicyDate);
      setStartDate(date);
      setCheckBox(false);
      setFileByFileCheckBoxFirm(false);
    } else {
      setEmailOffice(Email);
      setdaysAfterPolicyDateOffice(daysAfterPolicyDate);
      setfrequencyDropdownOffice(frequency);
      setStartDateOffice(date);
      setCheckBoxOffice(false);
      setFileByFileCheckBoxOfficeOffice(false);
    }
  };
  const getNearestFuture25thDate = (): string => {
    const now = moment().tz('America/Los_Angeles');
    const twentyFifth = moment().tz('America/Los_Angeles').date(25);
    let date: string;
    if (now.isSameOrAfter(twentyFifth, 'day')) {
      date = twentyFifth.add(1, 'months').format('MM/DD/YYYY');
    } else {
      date = twentyFifth.format('MM/DD/YYYY');
    }
    return date;
  };
  const getNearestFutureBiMonthlyDate = (): string => {
    let date: string;
    const now = moment().tz('America/Los_Angeles');
    const fifteenth = moment().tz('America/Los_Angeles').date(15);
    const twentyFifth = moment().tz('America/Los_Angeles').date(25);
    if (now.isSame(fifteenth, 'day')) {
      date = twentyFifth.format('MM/DD/YYYY');
    } else if (now.isSame(twentyFifth, 'day')) {
      date = fifteenth.add(1, 'months').format('MM/DD/YYYY');
    } else if (now.isBefore(fifteenth, 'day')) {
      date = fifteenth.format('MM/DD/YYYY');
    } else if (now.isBefore(twentyFifth, 'day')) {
      date = twentyFifth.format('MM/DD/YYYY');
    } else {
      date = fifteenth.add(1, 'months').format('MM/DD/YYYY');
    }
    return date;
  };
  const GetAutoReportingData = async (): Promise<AutoReportingResponses[]> => {
    const token = await getAccessToken();
    const getAutoReportingData = await getAutoReporting(token, firmId ?? '');

    return getAutoReportingData;
  };
  const {
    execute: executeGetAutoReportingData,
    value: GetAutoReportingDataValue,
    status: autoReportingStatus,
  } = useAsync(GetAutoReportingData, false);

  const actionStatus = autoReportingStatus === 'pending' ? 'pending' : 'idle';

  useEffect(() => {
    if (GetAutoReportingDataValue) {
      const officeLevelData = GetAutoReportingDataValue.filter((x: any) => x.ReportingLevel == 'Office');
      const firmLevelData = GetAutoReportingDataValue.filter((x: any) => x.ReportingLevel == 'Firm');
      setFirmFilter(firmFilters[0]);
      setDrawerOpen(false);
      setshowFirm(false);
      if (officeLevelData?.length > 0) {
        setOfficeLevelAutoeporting(officeLevelData);
        setFirmFilter(firmFilters[1]);
        setshowFirm(false);
        setActivate(false);
      } else {
        setActivateOffice(false);
        setOfficeLevelAutoeporting(null);
        if (firmLevelData?.length > 0) {
          setFirmLevelAutoReporting(firmLevelData);
        } else {
          setFirmLevelAutoReporting(null);
          setActivate(false);
          setCheckBox(false);
          setAutoReportingDefaultValue(true);
        }
        setFirmFilter(firmFilters[0]);
        setshowFirm(true);
      }
      renderAutoReportingComponents();
    }
  }, [GetAutoReportingDataValue]);

  useEffect(() => {
    setOffices(userFirm?.offices);
  }, [userFirm?.offices]);
  useEffect(() => {
    validateErrors();
  }, [email, frequencyDropdown, reportDay, startDate, daysAfterPolicyDateDropDown]);
  useEffect(() => {
    executeGetAutoReportingData().then();
  }, [firmId]);
  useEffect(() => {
    if (profileStatus === 'success' && !firmLevelAutoReporting) {
      setAutoReportingDefaultValue(true);
    }
  }, [profileStatus]);
  useEffect(() => {
    if (firmFilter?.value == 'firm') {
      setshowFirm(true);
    } else {
      setshowFirm(false);
    }
  }, [firmFilter]);
  const [updateDialog, setUpdateDialog] = React.useState<any>({
    id: undefined,
    note: '',
    emailNotification: email,
    updateType: undefined,
  });
  const handleDismiss = () => {
    setShouldShowValidationOffice(false);
    setValidationErrorsOffice([]);
    setCheckBoxOffice(false);
    setFileByFileCheckBoxOfficeOffice(false);
    setEmailOffice(null);
    setReportDayOffice(null);
    setdaysAfterPolicyDateOffice(null);
    setfrequencyDropdownOffice(null);
    setStartDateOffice('');
    setDrawerOpen(false);
    setIsNotSameData(false);
    setUpdateDialog({ emailNotification: email, id: undefined });
    setEditButtionClicked(false);
    setDrawerData(null);
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  const renderAutoReportingComponents = () => {
    if (autoReportingStatus !== 'success') {
      return <></>;
    }
    return (
      <ButtonGroup>
        {firmFilters.map((option) => {
          return (
            <AgentNetButton
              key={option.value}
              variant={firmFilter.value === option.value ? 'contained' : 'outlined'}
              color="primary"
              size="small"
              onClick={() => {
                setFirmFilter(firmFilters?.find((opt) => opt.value === option.value) ?? firmFilter);
                if (!firmLevelAutoReporting) {
                  setAutoReportingDefaultValue(true);
                }
              }}
              data-testid={option.dataTestId}
            >
              {option.label}
            </AgentNetButton>
          );
        })}
      </ButtonGroup>
    );
  };
  useEffect(() => {
    const autoReportData: AutoReportingResponses[] = [];
    const activeOffices = offices?.filter((item: any) => item.status === 'Active' || item.status === 'active');
    if (officeLevelAutoReporting) {
      const rowValue: Array<AutoReportingResponses> = officeLevelAutoReporting;
      activeOffices?.forEach((office: any) => {
        const data = rowValue.filter((item) => item.OfficeId === office.officeId);
        if (data?.length > 0) {
          const officeData: AutoReportingResponses = {
            OfficeId: office?.officeId ?? 0,
            Office: office?.name,
            Status: 'On',
            AUTOReportingRuleId: data[0]?.AUTOReportingRuleId,
            ReportingLevel: data[0]?.ReportingLevel,
            IsPerFileOrder: data[0]?.IsPerFileOrder === 'Yes' ? 'Individual' : 'Multiple',
            IsCPLOnlyReport: data[0]?.IsCPLOnlyReport,
            ReportingFrequency: data[0]?.ReportingFrequency,
            ReportDayOfTheWeek: data[0]?.ReportDayOfTheWeek,
            DaysFromPolicyDate: data[0]?.DaysFromPolicyDate,
            ReportStartDate: data[0]?.ReportStartDate,
            EmailAddress: data[0]?.EmailAddress,
          };
          autoReportData.push(officeData);
        } else {
          const officeData: AutoReportingResponses = {
            OfficeId: office?.officeId ?? 0,
            Office: office?.name,
            Status: 'Off',
          };
          autoReportData.push(officeData);
        }
      });
    } else {
      activeOffices?.forEach((office: any) => {
        const officeData: AutoReportingResponses = {
          OfficeId: office?.officeId ?? 0,
          Office: office?.name,
          Status: 'Off',
        };
        autoReportData.push(officeData);
      });
    }
    const result = autoReportData.sort((a: { Office: string }, b: { Office: string }) =>
      a.Office.localeCompare(b.Office),
    );
    setRowData(result);
  }, [officeLevelAutoReporting, firmFilter?.value == 'office']);
  useEffect(() => {
    validateErrorsOffice();
  }, [emailOffice, frequencyDropdownOffice, reportDayOffice, startDateOffice, daysAfterPolicyDateDropDownOffice]);
  useEffect(() => {
    if (firmLevelAutoReporting && firmFilter?.value == 'firm') {
      setFileByFileCheckBoxFirm(firmLevelAutoReporting[0]?.IsPerFileOrder == 'Yes' ? true : false);
      setCheckBox(firmLevelAutoReporting[0]?.IsCPLOnlyReport == 'Yes' ? true : false);
      setfrequencyDropdown(firmLevelAutoReporting[0]?.ReportingFrequency);
      setReportDay(firmLevelAutoReporting[0]?.ReportDayOfTheWeek);
      setdaysAfterPolicyDate(firmLevelAutoReporting[0]?.DaysFromPolicyDate);
      setStartDate(firmLevelAutoReporting[0]?.ReportStartDate);
      setEmail(firmLevelAutoReporting[0]?.EmailAddress);
    }
  }, [firmFilter?.value, firmLevelAutoReporting]);
  const validateErrorsOffice = async () => {
    const officeObj: any = {
      frequencyOffice: frequencyDropdownOffice,
      startDateOffice: startDateOffice,
      emailOffice: emailOffice,
      daysAfterPolicyDateOffice: daysAfterPolicyDateDropDownOffice,
    };
    if (startDateOffice === '') {
      officeObj['startDateOffice'] = null;
    }
    if (frequencyDropdownOffice == 'Weekly') {
      officeObj['reportDayOffice'] = reportDayOffice === undefined || reportDayOffice === '' ? null : reportDayOffice;
    }
    const errs: FieldValidationError[] = (await doValidate(officeObj, AutoReportingSchemaOffice)) || [];
    const updatedErrs = await errs.map((err) => {
      if (err.field == 'reportDayOffice' && frequencyDropdownOffice == 'Weekly') {
        return { ...err, message: 'Report Day is Required' };
      } else {
        return err;
      }
    });
    setValidationErrorsOffice(updatedErrs);
  };
  // saveAutoReporting
  const saveAutoReportingData = async () => {
    if (Activate) {
      validateErrors();
      setShouldShowValidation(true);
      if (validationErrors?.length > 0) {
        const validationMessage = validationErrors.filter((e) => e.field == 'CustomError');
        if (validationMessage && validationMessage?.length > 0) {
          validationMessage?.map((err) => {
            addGlobalMsg({
              message: err.message,
              type: 'error',
            });
          });
        } else {
          addSnackbarMessage({
            message: `Auto Reporting Failed`,
            type: 'error',
          });
        }
      } else {
        const reqData = {
          ReportingLevel: 'Firm',
          AutoReportingServices: [
            {
              IsAutoReport: Activate,
              IsPerFileOrder: fileByFileCheckBoxFirm,
              IsCPLOnlyReport: checkBoxValue,
              ReportFrequency: frequencyDropdown,
              ReportFrequencyDay: reportDay ? reportDay : 'Monday',
              DaysfromPolicyDate: daysAfterPolicyDateDropDown,
              ReportStartDate: startDate ? formatDate(new Date(startDate), 'yyyy-MM-dd') : null,
              EmailAddress: email ? email : Email,
            },
          ],
        };
        await saveAutoReportingDataAsync(firmId, reqData);
      }
    } else {
      const reqData = {
        ReportingLevel: 'Firm',
        AutoReportingServices: [
          {
            IsAutoReport: Activate,
            IsPerFileOrder: fileByFileCheckBoxFirm,
            IsCPLOnlyReport: checkBoxValue,
            ReportFrequency: frequencyDropdown,
            ReportFrequencyDay: reportDay ? reportDay : 'Monday',
            DaysfromPolicyDate: daysAfterPolicyDateDropDown,
            ReportStartDate: new Date().toISOString().split('T')[0],
            EmailAddress: email ? email : Email,
          },
        ],
      };
      await saveAutoReportingDataAsync(firmId, reqData);
    }
  };
  const saveAutoReportingDataAsync = async (firmId: string, reqData: any) => {
    setSavingAutoReporting(true);
    const token = await getAccessToken();
    const saveResponse = await saveAutoReporting(token, firmId, reqData);
    if (saveResponse) {
      addSnackbarMessage({
        message: 'Saving Successful',
        type: 'success',
      });
      executeGetAutoReportingData().then();
    } else {
      addSnackbarMessage({
        message: 'Auto Reporting Failed',
        type: 'error',
      });
    }
    setSavingAutoReporting(false);
  };
  const turnOffAutoReportingDataOffice = async () => {
    const token = await getAccessToken();
    const autoReportingDataPost: any = {};
    autoReportingDataPost.reportingLevel = 'Office';
    autoReportingDataPost.autoReportingServices = [];
    if (drawerData && drawerData !== null) {
      const reqData = {
        reportingLevel: 'Office',
        autoReportingServices: [
          {
            autoreportingruleId: drawerData?.AUTOReportingRuleId ?? 0,
            OfficeId: drawerData.OfficeId,
            IsAutoReport: ActivateOffice,
            IsPerFileOrder: drawerData?.IsPerFileOrder === 'Individual' ? true : false,
            IsCPLOnlyReport: drawerData?.IsCPLOnlyReport === 'Yes' ? true : false,
            ReportFrequency: drawerData?.ReportingFrequency ? drawerData?.ReportingFrequency : '',
            ReportFrequencyDay: drawerData?.ReportDayOfTheWeek ? drawerData?.ReportDayOfTheWeek : '',
            DaysfromPolicyDate: drawerData?.DaysFromPolicyDate ? drawerData?.DaysFromPolicyDate : '',
            ReportStartDate: drawerData?.ReportStartDate
              ? formatDate(new Date(drawerData?.ReportStartDate), 'yyyy-MM-dd')
              : null,
            EmailAddress: drawerData?.EmailAddress ?? '',
          },
        ],
      };
      const saveResponse = await postAutoReporting(token, firmId, reqData);
      if (saveResponse && saveResponse === true) {
        addSnackbarMessage({
          message: 'Saving Successful',
          type: 'success',
        });
        executeGetAutoReportingData().then();
      } else {
        addSnackbarMessage({
          message: 'Save Failed',
          type: 'error',
        });
        setOpenModel(false);
      }
    } else if (selectedRows && selectedRows?.length > 0) {
      const hasAutoReportingRule = selectedRows.filter((item) => item.Status === 'On');
      hasAutoReportingRule.map((item, i) => {
        const data = {
          autoreportingruleId: item?.AUTOReportingRuleId ?? 0,
          IsAutoReport: ActivateOffice,
          OfficeId: item?.OfficeId,
          IsPerFileOrder: item?.IsPerFileOrder === 'Individual' ? true : false,
          IsCPLOnlyReport: item?.IsCPLOnlyReport === 'Yes' ? true : false,
          ReportFrequency: item?.ReportingFrequency ? item?.ReportingFrequency : '',
          ReportFrequencyDay: item?.ReportDayOfTheWeek ? item?.ReportDayOfTheWeek : '',
          DaysfromPolicyDate: item?.DaysFromPolicyDate ? item?.DaysFromPolicyDate : '',
          ReportStartDate: item?.ReportStartDate ? formatDate(new Date(item?.ReportStartDate), 'yyyy-MM-dd') : null,
          EmailAddress: item?.EmailAddress ?? '',
        };
        autoReportingDataPost.autoReportingServices.push(data);
      });
      const saveResponse = await postAutoReporting(token, firmId, autoReportingDataPost);
      if (saveResponse && saveResponse === true) {
        addSnackbarMessage({
          message: 'Saving Successful',
          type: 'success',
        });
        executeGetAutoReportingData().then();
      } else {
        addSnackbarMessage({
          message: 'Save Failed',
          type: 'error',
        });
        setOpenModel(false);
      }
    }
  };
  const saveAutoReportingDataOffice = async () => {
    setValidationErrorsOffice([]);
    validateErrorsOffice();
    setShouldShowValidationOffice(true);

    if (validationErrorsOffice?.length > 0) {
      const validationMessage = validationErrorsOffice.filter((e) => e.field == 'CustomError');
      if (validationMessage && validationMessage.length > 0) {
        validationMessage?.map((err) => {
          addGlobalMsg({
            message: err.message,
            type: 'error',
          });
        });
      } else {
        addSnackbarMessage({
          message: `Auto Reporting Failed`,
          type: 'error',
        });
      }
    } else {
      const token = await getAccessToken();
      const autoReportingDataPost: any = {};
      autoReportingDataPost.reportingLevel = 'Office';
      autoReportingDataPost.autoReportingServices = [];
      if (drawerData && drawerData !== null) {
        const reqData = {
          reportingLevel: 'Office',
          autoReportingServices: [
            {
              autoreportingruleId: autoReportingRuleId ?? 0,
              OfficeId: officeId,
              IsAutoReport: ActivateOffice,
              IsPerFileOrder: fileByFileCheckBoxOfficeOffice,
              IsCPLOnlyReport: checkBoxValueOffice,
              ReportFrequency: frequencyDropdownOffice,
              ReportFrequencyDay: reportDayOffice ? reportDayOffice : '',
              DaysfromPolicyDate: daysAfterPolicyDateDropDownOffice,
              ReportStartDate: startDateOffice ? formatDate(new Date(startDateOffice), 'yyyy-MM-dd') : null,
              EmailAddress: emailOffice,
            },
          ],
        };
        const saveResponse = await postAutoReporting(token, firmId, reqData);
        if (saveResponse) {
          addSnackbarMessage({
            message: 'Saving Successful',
            type: 'success',
          });
          executeGetAutoReportingData().then();
        } else {
          addSnackbarMessage({
            message: 'Save Failed',
            type: 'error',
          });
        }
      } else if (selectedRows && selectedRows?.length > 0) {
        selectedRows.map((item, i) => {
          const data = {
            autoreportingruleId: item?.AutoReportingRuleId ?? 0,
            IsAutoReport: ActivateOffice,
            OfficeId: item?.OfficeId,
            IsPerFileOrder: fileByFileCheckBoxOfficeOffice,
            IsCPLOnlyReport: checkBoxValueOffice,
            ReportFrequency: frequencyDropdownOffice,
            ReportFrequencyDay: reportDayOffice ? reportDayOffice : '',
            DaysfromPolicyDate: daysAfterPolicyDateDropDownOffice,
            ReportStartDate: startDateOffice ? formatDate(new Date(startDateOffice), 'yyyy-MM-dd') : null,
            EmailAddress: emailOffice,
          };
          autoReportingDataPost.autoReportingServices.push(data);
        });
        const saveResponse = await postAutoReporting(token, firmId, autoReportingDataPost);
        if (saveResponse) {
          addSnackbarMessage({
            message: 'Saving Successful',
            type: 'success',
          });
          executeGetAutoReportingData().then();
        } else {
          addSnackbarMessage({
            message: 'Save Failed',
            type: 'error',
          });
        }
        setSavingAutoReporting(false);
      }
    }
  };
  const onEditClicked = () => {
    if (!isNotSameData) {
      setOfficeId(selectedRows[0]?.OfficeId);
      setAutoReportingRuleId(selectedRows[0]?.AUTOReportingRuleId);
      setfrequencyDropdownOffice(
        selectedRows[0]?.ReportingFrequency ? selectedRows[0]?.ReportingFrequency : 'Monthly (25th)',
      );
      setReportDayOffice(selectedRows[0]?.ReportDayOfTheWeek !== '' ? selectedRows[0]?.ReportDayOfTheWeek : null);
      setStartDateOffice(selectedRows[0]?.ReportStartDate);
      setdaysAfterPolicyDateOffice(selectedRows[0]?.DaysFromPolicyDate ? selectedRows[0]?.DaysFromPolicyDate : '30');
      setEmailOffice(selectedRows[0]?.EmailAddress ?? Email);
      setCheckBoxOffice(selectedRows[0]?.IsCPLOnlyReport === 'Yes' ? true : false);
      setFileByFileCheckBoxOfficeOffice(selectedRows[0]?.IsPerFileOrder === 'Individual' ? true : false);
      setActivateOffice(false);
    } else {
      setReportDayOffice(null);
      setAutoReportingDefaultValue(false);
    }
    if (allSelectedRowStatusSame == true && selectedRows[0]?.Status == 'On') {
      setActivateOffice(true);
    }
    if (!selectedRows[0]?.ReportingFrequency) {
      const date = getNearestFuture25thDate();
      setStartDateOffice(date);
    }
    setDrawerOpen(true);
    setEditButtionClicked(true);
  };
  useEffect(() => {
    if (selectedRows?.length > 0) {
      const firstRow = selectedRows[0];
      selectedRows.map((item) => {
        if (item.Status !== firstRow.Status) {
          setIsNotSameData(true);
        } else if (item?.IsPerFileOrder !== firstRow?.IsPerFileOrder) {
          setIsNotSameData(true);
        } else if (item?.IsCPLOnlyReport !== firstRow?.IsCPLOnlyReport) {
          setIsNotSameData(true);
        } else if (item?.ReportingFrequency !== firstRow?.ReportingFrequency) {
          setIsNotSameData(true);
        } else if (item?.ReportDayOfTheWeek !== firstRow?.ReportDayOfTheWeek) {
          setIsNotSameData(true);
        } else if (item?.DaysFromPolicyDate !== firstRow?.DaysFromPolicyDate) {
          setIsNotSameData(true);
        } else if (item?.ReportStartDate !== firstRow.ReportStartDate) {
          setIsNotSameData(true);
        } else if (item?.EmailAddress !== firstRow.EmailAddress) {
          setIsNotSameData(true);
        } else {
          setIsNotSameData(false);
        }
      });
      if (firstRow?.Status === 'On') {
        setAllSelectedRowStatusSame(true);
      }
    } else {
      setIsNotSameData(false);
    }
  }, [selectedRows?.length, !drawerOpen]);
  const handleDrawerOpen = (event: any) => {
    const data = event.data;
    setDrawerData({ ...data });
  };
  useEffect(() => {
    if (drawerData === null) {
      setDrawerOpen(false);
    } else {
      setActivateOffice(drawerData?.Status === 'Off' ? false : true);
      setOfficeId(drawerData?.OfficeId);
      setAutoReportingRuleId(drawerData?.AUTOReportingRuleId);
      setfrequencyDropdownOffice(drawerData?.ReportingFrequency ? drawerData?.ReportingFrequency : 'Monthly (25th)');
      setReportDayOffice(drawerData?.ReportDayOfTheWeek !== '' ? drawerData?.ReportDayOfTheWeek : null);
      setStartDateOffice(drawerData?.ReportStartDate);
      setdaysAfterPolicyDateOffice(drawerData?.DaysFromPolicyDate ? drawerData?.DaysFromPolicyDate : '30');
      setEmailOffice(drawerData?.EmailAddress ?? Email);
      setCheckBoxOffice(drawerData?.IsCPLOnlyReport === 'Yes' ? true : false);
      setFileByFileCheckBoxOfficeOffice((drawerData?.IsPerFileOrder ?? 'Multiple') === 'Individual' ? true : false);
      setDrawerOpen(true);
      if (!drawerData?.ReportingFrequency) {
        const date = getNearestFuture25thDate();
        setStartDateOffice(date);
      }
    }
  }, [drawerData]);
  const closeNotification = () => {
    setShowNotification(false);
  };
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current?.api?.getSelectedRows();
    setSelectedRows(selectedRows);
  };
  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={`${'Office'}AllCheckbox`} />;
  };
  const rowCheckboxRenderer = (params: any) => {
    return <input type="checkbox" data-testid={`${'Office'}File${params.node}Checkbox`} />;
  };
  const autoREportingOfficeLevelConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      headerName: '',
      field: 'AUTOReportingRuleId',
      hide: true,
    },
    {
      headerName: '',
      field: 'EmailAddress',
      hide: true,
    },
    {
      headerName: '',
      field: 'OfficeId',
      hide: true,
    },
    {
      headerName: '',
      field: 'ReportDayOfTheWeek',
      hide: true,
    },
    {
      headerName: 'Office',
      field: 'Office',
      tooltipField: 'Office',
      filter: 'agTextColumnFilter',
      minWidth: 300,
      cellRenderer: (params: any) => {
        return params?.data?.Office?.length > 0 ? params?.data?.Office : '';
      },
    },
    {
      headerName: 'Status',
      field: 'Status',
      tooltipField: 'Status',
      width: 50,
      filterParams: {
        filterOptions: officeLevelAutoReportingStatus.map((status) => ({
          key: status?.value,
          displayKey: status?.value,
          displayName: status?.name,
          test: (filterValue: string, cellValue: string) => {
            if (status?.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === status?.value.toLowerCase();
          },
          hideFilterInput: false,
        })),
        disabled: false,
      },
      cellRenderer: (params: any) => {
        return params?.data?.ReportingLevel?.length > 0 ? (
          <>
            <span className={'green-dot'} style={{ color: 'green' }} />
            &nbsp;
            <span style={{ color: 'green' }}>On</span>
          </>
        ) : (
          <>
            <span className={'grey-dot'} style={{ color: 'grey' }} />
            &nbsp;
            <span style={{ color: 'grey' }}>Off</span>
          </>
        );
      },
    },
    {
      headerName: 'StartDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      field: 'ReportStartDate',
      tooltipField: 'ReportStartDate',
      sort: 'desc',
      comparator: (date1: string, date2: string) => {
        const date1Parts = date1 != null ? date1.split('/') : '';
        const date2Parts = date2 != null ? date2.split('/') : '';
        const date1Obj = new Date(Number(date1Parts[2]), Number(date1Parts[0]) - 1, Number(date1Parts[1]));
        const date2Obj = new Date(Number(date2Parts[2]), Number(date2Parts[0]) - 1, Number(date2Parts[1]));
        return date1Obj.getTime() - date2Obj.getTime();
      },
      cellRenderer: (params: any) => {
        return params?.data?.ReportStartDate?.length > 0 ? params?.data?.ReportStartDate.substring(0, 10) : '';
      },
    },
    {
      headerName: 'Frequency',
      field: 'ReportingFrequency',
      tooltipField: 'ReportingFrequency',
      filterParams: {
        filterOptions: frequency.map((status) => ({
          key: status?.value,
          displayKey: status?.value,
          displayName: status?.name,
          test: (filterValue: string, cellValue: string) => {
            if (status?.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === status?.value.toLowerCase();
          },
          hideFilterInput: false,
        })),
        disabled: false,
      },
      cellRenderer: (params: any) => {
        return params?.data?.ReportingFrequency?.length > 0
          ? params?.data?.ReportingFrequency !== 'Weekly'
            ? params?.data?.ReportingFrequency
            : params?.data?.ReportDayOfTheWeek === 'Monday'
            ? 'Weekly-Mon'
            : params?.data?.ReportDayOfTheWeek === 'Tuesday'
            ? 'Weekly-Tue'
            : params?.data?.ReportDayOfTheWeek === 'Wednesday'
            ? 'Weekly-Wed'
            : params?.data?.ReportDayOfTheWeek === 'Thursday'
            ? 'Weekly-Thu'
            : params?.data?.ReportDayOfTheWeek === 'Friday'
            ? 'Weekly-Fri'
            : ''
          : '';
      },
    },
    {
      headerName: 'Days After Policy',
      field: 'DaysFromPolicyDate',
      tooltipField: 'DaysFromPolicyDate',
      filterParams: {
        filterOptions: dayAfterPolicyDate.map((status) => ({
          key: status?.value,
          displayKey: status?.value,
          displayName: status?.name,
          test: (filterValue: string, cellValue: string) => {
            if (status?.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === status?.value.toLowerCase();
          },
          hideFilterInput: false,
        })),
        disabled: false,
      },
      cellRenderer: (params: any) => {
        return params?.data?.DaysFromPolicyDate > 0 ? params?.data?.DaysFromPolicyDate : '';
      },
    },
    {
      headerName: 'Only CPL',
      field: 'IsCPLOnlyReport',
      tooltipField: 'AutoReportingOnlyCpl',
      filterParams: {
        filterOptions: isCplOnly.map((status) => ({
          key: status?.value,
          displayKey: status?.value,
          displayName: status?.name,
          test: (filterValue: string, cellValue: string) => {
            if (status?.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === status?.value.toLowerCase();
          },
          hideFilterInput: false,
        })),
        disabled: false,
      },
      cellRenderer: (params: any) => {
        return params?.data?.IsCPLOnlyReport?.length > 0 ? params?.data?.IsCPLOnlyReport : '';
      },
    },
    {
      headerName: 'Invoice Method',
      field: 'IsPerFileOrder',
      tooltipField: 'IsPerFileOrder',
      filterParams: {
        filterOptions: isFileByFile.map((status) => ({
          key: status?.value,
          displayKey: status?.value,
          displayName: status?.name,
          test: (filterValue: string, cellValue: string) => {
            if (status?.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === status?.value.toLowerCase();
          },
          hideFilterInput: false,
        })),
        disabled: false,
      },
      cellRenderer: (params: any) => {
        return params?.data?.IsPerFileOrder?.length > 0 ? params?.data?.IsPerFileOrder : '';
      },
    },
    {
      field: '',
      maxWidth: 35,
      filter: false,
      floatingFilter: false,
      tooltipField: 'View AutoReporting',
      cellRenderer: (params: any) => {
        return (
          <span style={{ position: 'relative', top: '10px', color: '#4B77D1' }}>
            <EditOutlinedIcon fontSize="large" />
          </span>
        );
      },
      onCellClicked: (event: any) => {
        handleDrawerOpen(event);
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      defaultValue: '-',
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
    };
  }, []);
  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  };
  const nearestDayOftheWeek = (dayOfWeek: string) => {
    const currentDate = moment().tz('America/Los_Angeles').startOf('day');
    const targetDate = moment().tz('America/Los_Angeles').day(dayOfWeek).startOf('day');
    // If the target day is the same as or before the current day, move to the next week.
    if (!targetDate.isAfter(currentDate)) {
      targetDate.add(7, 'days');
    }
    const date = targetDate.format('MM/DD/YYYY');
    return date;
  };
  const dismissAutoRepotingTurnOff = () => {
    setActivateOffice(true);
  };
  const dismissAutoRepotingTurnOffFirm = () => {
    setActivate(true);
    setOpenModelFirm(false);
  };
  const AutoReportingFieldsDescription = (
    <>
      <div>
        <b style={{ fontSize: '13px' }}>FIRM/OFFICE SELECTOR</b>
        <div className={classes.divFileInfo}>
          Select &apos;Firm&apos; to apply the same Auto-Reporting rules for all offices. Select &apos;Office&apos; to{' '}
        </div>
        <div className={classes.divFileInfoNext}>set individual Auto-Reporting rules for each selected office</div>
      </div>
      <br />
      <div>
        <b style={{ fontSize: '13px' }}>DAYS AFTER POLICY EFFECTIVE DATE</b>
        <div className={classes.divFileInfo}>
          Based on your selection, the system will auto-report all files based on the policy{' '}
        </div>
        <div className={classes.divFileInfoNext}>date with no remittance validation errors.</div>
      </div>
      <br />
      <div>
        <b style={{ fontSize: '13px' }}>INCLUDE CPL-ONLY FILES</b>
        <div className={classes.divFileInfo}>
          Do you want the system to auto-report the files containing CPL&apos;s only? If you select
        </div>
        <div className={classes.divFileInfoNext}>
          &quot;Yes&quot;, the system will automatically report files which only contain a priced CPL. If
        </div>
        <div className={classes.divFileInfoNext}>
          you select &quot;No&quot;, you will be required to report the CPL for each file in AgentNet{' '}
        </div>
        <div className={classes.divFileInfoNext}>unless a jacket has been priced.</div>
      </div>
    </>
  );
  const validateErrors = async () => {
    const emailObj: any = {
      frequencyFirm: frequencyDropdown,
      startDate: startDate,
      daysAfterPolicyDate: daysAfterPolicyDateDropDown,
      email: email,
    };
    if (startDate === '') {
      emailObj['startDate'] = null;
    }
    if (frequencyDropdown == 'Weekly') {
      emailObj['reportDay'] = reportDay;
    }
    const errs: FieldValidationError[] = (await doValidate(emailObj, AutoReportingSchema)) || [];
    const updatedErrs = await errs.map((ele) => {
      if (ele.field == 'reportDay' && frequencyDropdown == 'Weekly') {
        return { ...ele, message: 'Report Day is Required' };
      } else {
        return ele;
      }
    });
    setValidationErrors(updatedErrs);
  };
  if (actionStatus === 'pending') {
    return <> {CustomOverlayLoading()}</>;
  }
  function calculateStartDate(value: any, reportDay = null) {
    let date: any;
    if (value == 'Daily') {
      date = moment().tz('America/Los_Angeles').startOf('day').add(1, 'days').format('MM/DD/YYYY');
    } else if (value == 'Monthly (25th)') {
      date = getNearestFuture25thDate();
    } else if (value == 'Bi-Monthly (15th & 25th)') {
      date = getNearestFutureBiMonthlyDate();
    } else if (value == 'Weekly' && reportDay != null) {
      date = nearestDayOftheWeek(reportDay);
    } else {
      date = '';
    }
    return date;
  }
  return (
    <ContentContainer fullWidth>
      <div className={classes.scrollableContainer}>
        <div>
          <PageHeader
            title="Auto-Reporting"
            subtitle="Automatically report policy data for your eligible files through a touchless remittance process. Based upon your parameters, files that have completed pricing and no errors are reported electronically, eliminating manual steps."
            contentRight={renderAutoReportingComponents()}
            menuItems={[
              {
                label: 'Field Descriptions',
                onClick: () => setShowNotification(true),
              },
              {
                label: 'Knowledge Base',
                link: KBLinks.autoReporting,
              },
            ]}
          />
        </div>
        {showNotification && (
          <AgentNetConfirmationDialog
            qaAttrPrefix="qaFieldDescription"
            onConfirm={closeNotification}
            showSecondaryActionBtn={false}
            onDismissAction={closeNotification}
            open={showNotification}
            dialogTitle="Field Descriptions"
            dialogBtnContent="Got it!"
            dialogActionBtnJustifyContent="flex-end"
            dialogTextHTML={AutoReportingFieldsDescription}
          />
        )}
        {showFirm && autoReportingStatus === 'success' ? (
          <div className={classes.toggle}>
            <FormControlLabel
              control={
                <Switch
                  checked={Activate}
                  onChange={() => {
                    setActivate(!Activate);
                    setOpenModelFirm(Activate);
                  }}
                  value={Activate}
                  name="Activate"
                />
              }
              label={'Activate'}
              value={showFirm}
            />
            {autoReportingStatus === 'success' && (
              <>
                <Grid container spacing={3} className={classes.formContainer}>
                  <Grid item sm={3}>
                    <DateFieldString
                      required
                      name="startDate"
                      label="Start Date"
                      id="calender"
                      maxDate="12/31/9999"
                      minDate={moment().tz('America/Los_Angeles').add(1, 'days').format('MM/DD/YYYY')}
                      disabled={frequencyDropdown != 'Daily' || Activate == false}
                      value={(() => {
                        const dateParsed = Date.parse(startDate ? startDate : '');
                        return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : null;
                      })()}
                      onChange={(val: any) => {
                        setStartDate(val);
                      }}
                      errs={validationErrors}
                      showValidation={shouldShowValidation}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <AgentNetDropdownSelector
                      name="frequencyFirm"
                      label="Frequency"
                      required
                      options={frequencyFirm}
                      value={frequencyDropdown}
                      disabled={Activate == false}
                      menuOption={(value: any) => {
                        setfrequencyDropdown(value);
                        if (value == 'Weekly') {
                          setReportDay('Monday');
                          setStartDate(nearestDayOftheWeek('Monday'));
                        } else {
                          const startDate = calculateStartDate(value, reportDay);
                          setStartDate(startDate);
                        }
                      }}
                      errs={validationErrors}
                      showValidation={shouldShowValidation}
                    />
                  </Grid>
                  {frequencyDropdown == 'Weekly' && (
                    <Grid item sm={3}>
                      <AgentNetDropdownSelector
                        required={frequencyDropdown == 'Weekly' ? true : false}
                        name="reportDay"
                        label="Report Day"
                        options={reportDays}
                        value={reportDay}
                        disabled={Activate == false}
                        menuOption={(value: any) => {
                          setStartDate(nearestDayOftheWeek(value));
                          setReportDay(value);
                        }}
                        errs={validationErrors}
                        showValidation={shouldShowValidation}
                      />
                    </Grid>
                  )}
                  <Grid item sm={3} style={{ minWidth: 275 }}>
                    <AgentNetDropdownSelector
                      className={classes.dateDropDown}
                      required
                      name="daysAfterPolicyDate"
                      label="Days after Policy Effective Date"
                      options={daysAfterPolicyDate}
                      value={daysAfterPolicyDateDropDown}
                      disabled={Activate == false}
                      menuOption={(value: any) => {
                        setdaysAfterPolicyDate(value);
                      }}
                      errs={validationErrors}
                      showValidation={shouldShowValidation}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <AgentNetTextInput
                      fullWidth
                      required
                      variant="outlined"
                      label="Email Notification"
                      id="email"
                      name={'email'}
                      value={email}
                      defaultValue={email}
                      disabled={Activate == false}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateErrors();
                      }}
                      errs={validationErrors}
                      showValidation={shouldShowValidation}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetCheckBox
                      label={'Include CPL only files'}
                      className={classes.lable}
                      name={'IsCPLIncluded'}
                      value={checkBoxValue}
                      disabled={Activate == false}
                      checkHandler={(val: any) => {
                        setCheckBox(val);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetCheckBox
                      label={'Generate a separate order for each file'}
                      name={'IsFileByFile'}
                      value={fileByFileCheckBoxFirm}
                      disabled={Activate == false}
                      checkHandler={(val: any) => {
                        setFileByFileCheckBoxFirm(val);
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {showFirm && firmLevelAutoReporting?.length > 0 && (
              <AgentNetConfirmationDialog
                qaAttrPrefix="ConfirmationCancel"
                onConfirm={saveAutoReportingData}
                open={OpenModelFirm}
                onDismissAction={dismissAutoRepotingTurnOffFirm}
                dialogTitle="Confirmation ?"
                dialogBtnContent="Yes"
                secondaryActionBtnContent="No"
                dialogText="Are you sure you want to turn off Auto-Reporting?"
              />
            )}
          </div>
        ) : (
          <>
            <div className="grid-summary">
              <div className="files-list-grid">
                <div className={clsx('ag-theme-alpine table-grid', classes.tableDefault, classes.paddingX)}>
                  {rowData?.length > 0 && autoReportingStatus === 'success' ? (
                    <DataTable
                      ref={gridRef}
                      columnDefs={autoREportingOfficeLevelConfig}
                      rowData={rowData}
                      defaultColDef={defaultColDef}
                      gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
                      onFilterChanged={onFilterChanged}
                      onSelectionChanged={onSelectionChanged}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            {selectedRows?.length ? (
              <BottomAppBar primaryActionLabel="Edit" onPrimaryClick={() => onEditClicked()} hideCancel={true} />
            ) : null}
          </>
        )}
        {showFirm && (
          <div style={{ paddingTop: 50 }}>
            <BottomAppBar
              primaryActionLabel="Save"
              onPrimaryClick={() => saveAutoReportingData()}
              cancelLabel="Clear All"
              onCancel={() => executeGetAutoReportingData().then()}
              disablePrimary={savingAutoReporting || !Activate}
              disableCancel={savingAutoReporting || !Activate}
              primaryButtonLoader={savingAutoReporting}
            />
          </div>
        )}
        {drawerOpen ? (
          <FormDrawerComponent
            title="Office Auto-Reporting"
            open={drawerOpen}
            primaryActionLabel="Save"
            disabled={!ActivateOffice}
            dismissActionProps={{ 'data-testid': 'AutoReportingSaveCancel' }}
            onPrimaryAction={saveAutoReportingDataOffice}
            onDismissAction={handleDismiss}
            onSecondaryAction={handleDismiss}
          >
            <div className={classes.toggleOffice}>
              <Grid container spacing={1} className={classes.formContainerOffice}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ActivateOffice}
                      onChange={() => {
                        setActivateOffice(!ActivateOffice);
                        setOpenModel(ActivateOffice);
                      }}
                      value={Activate}
                      name="Activate"
                    />
                  }
                  label={'Activate'}
                  className="form-control-label"
                />
              </Grid>
              {autoReportingStatus === 'success' && (
                <Grid container spacing={3} className={classes.formContainerOffice}>
                  <Grid item sm={12}>
                    <DateFieldString
                      required
                      name="startDateOffice"
                      label="Start Date"
                      id="calender"
                      maxDate="12/31/9999"
                      minDate={moment().tz('America/Los_Angeles').add(1, 'days').format('MM/DD/YYYY')}
                      disabled={frequencyDropdownOffice != 'Daily' || ActivateOffice == false}
                      value={(() => {
                        const date =
                          frequencyDropdownOffice === 'Weekly' && reportDayOffice === null ? 'true' : startDateOffice;
                        const dateParsed = Date.parse(
                          (startDateOffice === '' || startDateOffice === null) && date === 'true'
                            ? ''
                            : startDateOffice
                            ? startDateOffice
                            : !isNotSameData && drawerData === null && editButtionClicked
                            ? selectedRows[0]?.ReportStartDate
                            : isNotSameData && drawerData === null && editButtionClicked
                            ? ''
                            : drawerData !== null && !editButtionClicked
                            ? drawerData?.ReportStartDate
                            : '',
                        );
                        return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : '';
                      })()}
                      onChange={(val: any) => {
                        setStartDateOffice(val !== '' ? val : null);
                      }}
                      showValidation={shouldShowValidationOffice}
                      errs={validationErrorsOffice}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetDropdownSelector
                      name="frequencyOffice"
                      label="Frequency"
                      required
                      options={frequencyFirm}
                      value={frequencyDropdownOffice}
                      disabled={ActivateOffice == false}
                      menuOption={(value: any) => {
                        setfrequencyDropdownOffice(value);
                        if (value == 'Weekly') {
                          setReportDayOffice('Monday');
                          setStartDateOffice(nearestDayOftheWeek('Monday'));
                        } else {
                          const startDate = calculateStartDate(value, reportDayOffice);
                          setStartDateOffice(startDate);
                        }
                      }}
                      showValidation={shouldShowValidationOffice}
                      errs={validationErrorsOffice}
                    />
                  </Grid>
                  {frequencyDropdownOffice == 'Weekly' && (
                    <Grid item sm={12}>
                      <AgentNetDropdownSelector
                        required
                        name="reportDayOffice"
                        label="Report Day"
                        options={reportDays}
                        value={reportDayOffice}
                        disabled={ActivateOffice == false}
                        menuOption={(value: any) => {
                          setStartDateOffice(nearestDayOftheWeek(value));
                          setReportDayOffice(value);
                        }}
                        showValidation={shouldShowValidationOffice}
                        errs={validationErrorsOffice}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <AgentNetDropdownSelector
                      required
                      name="daysAfterPolicyDateOffice"
                      label="Days after Policy Effective Date"
                      options={daysAfterPolicyDate}
                      value={daysAfterPolicyDateDropDownOffice}
                      disabled={ActivateOffice == false}
                      menuOption={(value: any) => {
                        setdaysAfterPolicyDateOffice(value);
                      }}
                      showValidation={shouldShowValidationOffice}
                      errs={validationErrorsOffice}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetTextInput
                      fullWidth
                      required
                      variant="outlined"
                      label="Email Notification"
                      id="email"
                      name={'emailOffice'}
                      value={emailOffice}
                      disabled={ActivateOffice == false}
                      onChange={(e) => {
                        setEmailOffice(e.target.value);
                      }}
                      showValidation={shouldShowValidationOffice}
                      errs={validationErrorsOffice}
                    />
                    <div className={classes.divStyle}>
                      To add multiple email addresses, please separate email addresses with a semi-colon.
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetCheckBox
                      label={'Include CPL only files'}
                      name={'IsCPLIncluded'}
                      value={checkBoxValueOffice}
                      disabled={ActivateOffice == false}
                      checkHandler={(val: any) => {
                        setCheckBoxOffice(val);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AgentNetCheckBox
                      label={'Generate a separate order for each file'}
                      name={'IsFileByFile'}
                      value={fileByFileCheckBoxOfficeOffice}
                      disabled={ActivateOffice == false}
                      checkHandler={(val: any) => {
                        setFileByFileCheckBoxOfficeOffice(val);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <span className={classes.expandInfoIcon} onClick={() => setExpandValue(!ExpandValue)}>
                      <HelpOutlineIcon fontSize="large" />
                      {ExpandValue ? (
                        <ExpandMoreOutlined fontSize="large" />
                      ) : (
                        <KeyboardArrowRightTwoTone fontSize="large" />
                      )}
                    </span>
                    {ExpandValue && (
                      <div
                        style={{
                          marginTop: 10,
                        }}
                      >
                        {AutoReportingFieldsDescription}
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              {!showFirm && !ActivateOffice && drawerData?.Status === 'On' && (
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationCancel"
                  onConfirm={turnOffAutoReportingDataOffice}
                  open={OpenModel}
                  onDismissAction={dismissAutoRepotingTurnOff}
                  dialogTitle="Confirmation ?"
                  dialogBtnContent="Yes"
                  secondaryActionBtnContent="No"
                  dialogText="Are you sure you want to turn off Auto-Reporting?"
                />
              )}
              {!showFirm && !ActivateOffice && allSelectedRowStatusSame && drawerData === null && (
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationCancel"
                  onConfirm={turnOffAutoReportingDataOffice}
                  open={OpenModel}
                  onDismissAction={dismissAutoRepotingTurnOff}
                  dialogTitle="Confirmation ?"
                  dialogBtnContent="Yes"
                  secondaryActionBtnContent="No"
                  dialogText="Are you sure you want to turn off Auto-Reporting?"
                />
              )}
            </div>
          </FormDrawerComponent>
        ) : (
          ''
        )}
      </div>
    </ContentContainer>
  );
};
export default autoReporting;
