import { useAuth } from '@agentnet/auth';
import { makeStyles } from '@material-ui/core/styles';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1, flexDirection: 'column', display: 'flex', justifyContent: 'space-between' },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Dashboard = (): JSX.Element => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, setNavigated, profile } = profileCtx;
  const isNavigated = window.sessionStorage.getItem('isNavigated') ?? false;
  useEffect(() => {
    if (auth.isAuthenticated()) {
      if (profile?.roleId === 3 || profile?.roleId === 5) {
        setNavigated && setNavigated(true);
        history.push('/searchFirms');
      } else if (profile?.roleId === 2) {
        setNavigated && setNavigated(true);
        history.push('/');
      } else if (profile?.roleId === 13) {
        setNavigated && setNavigated(true);
        history.push('/admin/users/manage');
      } else if (profile?.roleId === 14) {
        setNavigated && setNavigated(true);
        history.push('/order-management');
      }
    }
    if (!isNavigated && auth.isAuthenticated() && userFirm?.firmId && profile?.roleId !== 14) {
      setNavigated && setNavigated(true);
      history.push('/files');
    }
  }, [auth.isAuthenticated(), userFirm?.firmId, profile?.roleId]);

  return (
    <div className={classes.root}>
      <main className={`core-content-wrap`}>
        {!isNavigated && (
          <div className={classes.loader}>
            <LoadingSpinner variant="circle" status={'pending'} />
          </div>
        )}
      </main>
    </div>
  );
};

export default Dashboard;
