import { LenderClauseTypeValue } from 'api/file/constants';

export const mortgageClause = [
  { name: 'Standard', value: 'Standard' },
  { name: 'Successor Ownership', value: 'Successor Ownership' },
  { name: 'FHA', value: 'FHA' },
  { name: 'VA', value: 'VA' },
  { name: 'MERS', value: 'MERS' },
  { name: 'Other', value: 'Other' },
];

export const MortgageClauseTextOptions: Record<string, { name: string; value: string }> = {
  [LenderClauseTypeValue.standard]: {
    value: 'and each successor and/or assign that is defined as an Insured in the Conditions.',
    name: 'Standard',
  },
  [LenderClauseTypeValue.successor_ownership]: {
    value: 'and each successor and/or assign that is defined as an Insured in the Conditions of the Policy.',
    name: 'SuccessorOwnership',
  },
  [LenderClauseTypeValue.fha]: {
    value:
      'and each successor and/or assign that is defined as an Insured in the Conditions, and THE SECRETARY OF HOUSING AND URBAN DEVELOPMENT, WASHINGTON, D.C.',
    name: 'FHA',
  },
  [LenderClauseTypeValue.va]: {
    value:
      'and each successor and/or assign that is defined as an Insured in the Conditions, and THE SECRETARY OF VETERANS AFFAIRS',
    name: 'VA',
  },
  [LenderClauseTypeValue.mers]: {
    value:
      'appearing of record as MERS, as nominee for {{ COMPANY NAME }}, and each successor and/or assign that is defined as an Insured in the Conditions',
    name: 'MERS',
  },
  [LenderClauseTypeValue.other]: {
    value: '',
    name: 'Other',
  },
};
