import moment from 'moment';

const getStartOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const fileListFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
    const cellDate = getStartOfDay(cellValue);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
      return -1;
    }
    if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
  dateFormat: 'yyyy-mm-dd',
  inRangeInclusive: true,
};

export const formatDate = (date: string) => {
  if (!date) return '';
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
};

export const numberValueFormatter = function (params: any, nonAgValue = false) {
  const rawValue = nonAgValue ? params : params?.value;
  if (rawValue == null) return '';

  const isNegative = rawValue < 0;
  const absoluteValue = Math.abs(rawValue).toFixed(2);
  const formattedValue = absoluteValue.replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return (isNegative ? '-' : '') + '$' + formattedValue;
};

export const queueFilters = [
  { name: 'All Files', value: 'allFiles' },
  { name: 'Order Queue', value: 'orderQueue' },
];
