import { Grid, GridProps, TextFieldProps } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  AddressFields,
  IDomesticAddressFields,
  IForeignAddressFields,
} from '../../../ui-kit/form/interfaces/AddressModal';
import { AgentNetTextInput } from '../../../ui-kit/inputs/TextField';
import { foreignAddressSchema } from '../../../utilities/validation/schemas';
import { doValidate, FieldValidationError } from '../../../utilities/validation/validation';
import ErrorSection from './ErrorSection';

export type HiddenTextFieldBaseProps = {
  hidden?: boolean;
};

export type HiddenTextFieldProps = TextFieldProps & HiddenTextFieldBaseProps;
export type ForeignAddressFieldProps = {
  ContainerComponentProps?: GridProps;
  Address1Props?: TextFieldProps;
  Address2Props?: TextFieldProps;
  PostalCodeProps?: TextFieldProps;
  CountryProps?: TextFieldProps;
  value?: AddressFields;
  fieldOverides?: TextFieldProps;
  onChange?: (foreignAddressFields: AddressFields) => void;
  setIsValid?: (isValid: boolean) => void;
  qaAttributePrefix?: string;
  disabled?: boolean;
};

export const ForeignAddress = ({
  onChange = () => undefined,
  ContainerComponentProps,
  value,
  setIsValid,
  qaAttributePrefix = '',
  disabled = false,
}: ForeignAddressFieldProps): JSX.Element => {
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [initialValidationComplete, setInitialValidationComplete] = useState<boolean>(false);
  const validateErrors = async (addressFields: IForeignAddressFields) => {
    const errs = (await doValidate(addressFields, foreignAddressSchema)) || [];
    setValidationErrors(errs);
    setIsValid && setIsValid(errs.length === 0);
  };
  const onChangeWithValidation = async (addressFields: IForeignAddressFields) => {
    validateErrors(addressFields);
    if (onChange) onChange(addressFields);
  };

  useEffect(() => {
    if (!initialValidationComplete) {
      validateErrors({ ...value });
      setInitialValidationComplete(true);
    }
  }, []);

  return (
    <Grid container item {...ContainerComponentProps} spacing={3}>
      <Grid item xs={6}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          data-testid={qaAttributePrefix + 'Address1'}
          label="Address 1"
          onChange={(e) => {
            onChangeWithValidation &&
              onChangeWithValidation({ ...value, address1: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.address1 ?? ''}
        />
        <ErrorSection errs={validationErrors} field={'address1'} />
      </Grid>

      <Grid item xs={6}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          label="Address 2"
          data-testid={qaAttributePrefix + 'Address2'}
          onChange={(e) => {
            onChangeWithValidation &&
              onChangeWithValidation({ ...value, address2: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.address2 ?? ''}
        />
        <ErrorSection errs={validationErrors} field={'address2'} />
      </Grid>

      <Grid item xs={3}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          label="City"
          name="city"
          errs={validationErrors}
          data-testid={qaAttributePrefix + 'City'}
          value={value?.city ?? ''}
          onChange={(e) => {
            onChangeWithValidation &&
              onChangeWithValidation({ ...value, city: e.target.value } as IDomesticAddressFields);
          }}
        />
        <ErrorSection errs={validationErrors} field={'city'} />
      </Grid>
      <Grid item xs={3}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          name="stateOrProvince"
          data-testid={qaAttributePrefix + 'Region'}
          label="Region"
          onChange={(e) => {
            onChangeWithValidation({ ...value, stateOrProvince: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.stateOrProvince ?? ''}
        />
        <ErrorSection errs={validationErrors} field={'stateOrProvince'} />
      </Grid>
      <Grid item xs={3}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          name="postalCode"
          label="Postal Code"
          data-testid={qaAttributePrefix + 'Zip'}
          onChange={(e) => {
            onChangeWithValidation &&
              onChangeWithValidation({ ...value, postalCode: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.postalCode ?? ''}
          errs={validationErrors}
        />
        <ErrorSection errs={validationErrors} field={'postalCode'} />
      </Grid>
      <Grid item xs={3}>
        <AgentNetTextInput
          disabled={disabled}
          variant="outlined"
          fullWidth
          label="Country"
          errs={validationErrors}
          data-testid={`${qaAttributePrefix}Country`}
          name="country"
          onChange={(e) => {
            onChangeWithValidation &&
              onChangeWithValidation({ ...value, country: e.target.value } as IDomesticAddressFields);
          }}
          value={value?.country ?? ''}
        />
        <ErrorSection errs={validationErrors} field={'country'} />
      </Grid>
    </Grid>
  );
};

export default ForeignAddress;
