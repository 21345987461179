import { pxToRem } from '@fluentsms/agentnet-web-components';
import { makeStyles } from '@material-ui/core/styles';
import { useNavPermissions } from 'hooks/useNavPermissions';
import { FC, ReactNode } from 'react';

interface ContentContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  mTop?: string | undefined;
}

const ContentContainer: FC<ContentContainerProps> = ({ children, fullWidth, mTop }: ContentContainerProps) => {
  const { hasPermission } = useNavPermissions();
  const useStyles = makeStyles((theme) => ({
    contentContainer: {
      flexGrow: 1,
      marginTop: hasPermission.hasFirmId ? (mTop ? mTop : pxToRem(113)) : pxToRem(64),
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'none',
      background: theme.palette.common.white,
      width: fullWidth ? '100%' : 'auto',
      borderRight: fullWidth ? 'none' : `1px solid ${theme.palette.divider}`,
    },
  }));

  const classes = useStyles();
  return <div className={classes.contentContainer}>{children}</div>;
};

export default ContentContainer;
