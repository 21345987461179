import { useAuth } from '@agentnet/auth';
import { HashUtils } from '@agentnet/utils';
import {
  CellLink,
  CellMessage,
  CellStatus,
  Content,
  DashboardMetricCard,
  DataTable,
  DrawerComponent,
  DrawerContent,
  DrawerExit,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  PageHeader,
} from '@fluentsms/agentnet-web-components';
import { Button, createStyles, Grid, IconButton, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import {
  CellClickedEvent,
  FilterModel,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import {
  AuditLog,
  GetActionLogs,
  getActionLogs,
  GetActionLogsResult,
  getServiceOrders,
  GetServiceOrdersResult,
  GetServicerOrders,
  PostNotes,
  postNotes,
} from 'api/dashboards/service-orders';
import { KBLinks } from 'features/constants';
import { filterParams, formatDate } from 'features/Remittance/reportsListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { NoMessages } from 'ui-kit/components/NoMessages';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { dateTooltipValue } from 'utilities/utilities';
import { AuditLogItem } from './AuditLogItem/AuditLogItem';
import { ConversationItem } from './ConversationItem/ConversationItem';
import { StatusDashboard } from './StatusDashboard';
import { TabPanel } from './TabPanel/TabPanel';
import { TimeTracker } from './TimeTracker/TimeTracker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        minHeight: 220,
      },
    },
    inputContainer: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      display: 'flex',
    },
    inputMessage: {
      background: 'none',
      border: 0,
      padding: theme.spacing(0, 2),
      width: '100%',
    },
    inputSubmit: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      color: theme.palette.actionSecondary.active,
      padding: theme.spacing(0.8, 1),
    },
    noRows: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 220,
      justifyContent: 'center',
    },
    subHeading: {
      textTransform: 'none',
    },
    tabContent: {
      height: 'calc(100% - 64px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
  }),
);

export function ServiceOrders() {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { search } = useLocation();
  const orderParam: string | null = new URLSearchParams(search).get('order');
  const { userFirm }: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [auditAndConversations, setAuditAndConversations] = useState<GetActionLogsResult | undefined>(undefined);
  const [serviceOrderNumber, setServiceOrderNumber] = useState<string>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number | undefined>(undefined);
  const [selectedOrders, setSelectedOrders] = useState<
    { inProcess?: boolean; title: string; orders: GetServiceOrdersResult[] } | undefined
  >(undefined);
  const gridApiRef = useRef<GridApi | null>(null);
  const messageRef = useRef<HTMLInputElement>(null);
  const { addGlobalMsg } = useGlobalMessages();

  const firmId = userFirm?.firmId ?? '';

  const getServiceOrdersData = async (
    args: Omit<GetServicerOrders, 'firmId'> = {},
  ): Promise<GetServiceOrdersResult[]> => {
    const token = await getAccessToken();
    const response = await getServiceOrders(
      {
        firmId: +firmId,
        ...args,
      },
      token,
    );

    return response;
  };

  const {
    execute: serviceOrdersExecute,
    status: serviceOrdersStatus,
    value: serviceOrdersResults,
  } = useAsync<GetServiceOrdersResult[]>(getServiceOrdersData, false);

  const getActionLogsData = async ({
    FileId,
    ServiceOrderReferenceId,
  }: GetActionLogs): Promise<GetActionLogsResult> => {
    const token = await getAccessToken();
    const response = await getActionLogs(
      {
        FileId,
        ServiceOrderReferenceId,
      },
      token,
    );

    return response;
  };

  const postNotesData = async ({ fileId, serviceOrderReferenceId, notes }: PostNotes): Promise<boolean> => {
    const token = await getAccessToken();
    const response = await postNotes(
      {
        fileId,
        serviceOrderReferenceId,
        notes,
      },
      token,
    );

    return response;
  };

  const onGridReady = (params: GridReadyEvent): void => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    if (gridApiRef.current) {
      const rowCount: number = gridApiRef.current.getDisplayedRowCount();
      setDisplayedRowCount(rowCount);
    }
  };

  const onFirstDataRendered = (): void => {
    if (gridApiRef.current && orderParam) {
      const rowNode: IRowNode | undefined = gridApiRef.current.getRowNode(orderParam);

      if (rowNode) {
        const pinnedRightRowElement: Element | null = document.querySelector(
          `.ag-pinned-right-cols-container [row-id="${rowNode.id}"]`,
        );

        if (pinnedRightRowElement) {
          const cells = pinnedRightRowElement.querySelectorAll('.ag-cell');
          const lastPinnedCell: HTMLElement = cells[cells.length - 1] as HTMLElement;

          if (lastPinnedCell) {
            lastPinnedCell.click();
          }
        }
      }
    }
  };

  const onTileFilterClick = (status: string): void => {
    if (gridApiRef.current) {
      const filterModel: FilterModel = gridApiRef.current.getFilterModel();
      const isStatusFilterActive = !!filterModel['Status'];

      if (isStatusFilterActive && currentStatus === status) {
        gridApiRef.current.setFilterModel(null);
        setCurrentStatus(null);
        return;
      }

      gridApiRef.current.setFilterModel({
        Status: {
          filterType: 'set',
          values: [status],
        },
      });
      gridApiRef.current.onFilterChanged();
      setDisplayedRowCount(gridApiRef.current?.getDisplayedRowCount());
      setCurrentStatus(status);
    }
  };

  const onTabChange = (e: ChangeEvent<unknown>, newValue: number): void => {
    setTabValue(newValue);
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (messageRef.current && messageRef.current.value.length > 0) {
      setIsFormSubmitting(true);

      if (auditAndConversations) {
        const { FileId, ServiceOrderReferenceId } = auditAndConversations;

        try {
          const payload: PostNotes = {
            fileId: FileId,
            serviceOrderReferenceId: ServiceOrderReferenceId,
            notes: messageRef.current.value,
          };
          const response = await postNotesData(payload);

          if (response) {
            const getPayload: GetActionLogs = {
              FileId,
              ServiceOrderReferenceId,
            };
            const logResponse = await getActionLogsData(getPayload);
            setAuditAndConversations(logResponse);
          }

          messageRef.current.value = '';
          setIsFormSubmitting(false);
        } catch (e) {
          let message = 'An unknown error occurred';

          if (e instanceof Error) {
            message = e.message;
          }

          addGlobalMsg({
            message,
            type: 'error',
          });

          setIsFormSubmitting(false);
        }
      }
    }
  };

  const onHandleBackClick = (): void => {
    setSelectedOrders(undefined);
  };

  const onRefreshHandler = (): void => {
    serviceOrdersExecute({ isETA: true });
  };

  // TODO: This should be moved to the shared component along with the loading prop
  // Loading should be shown when the rowData is null | undefined
  const CustomOverlayLoading = (): JSX.Element => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  useEffect(() => {
    if (userFirm?.firmId) {
      serviceOrdersExecute();
    }
  }, []);

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  const ordersMTDComplete: GetServiceOrdersResult[] = [];
  const ordersYTDComplete: GetServiceOrdersResult[] = [];
  const ordersSearching: GetServiceOrdersResult[] = [];
  const ordersExamining: GetServiceOrdersResult[] = [];
  const ordersQualityReview: GetServiceOrdersResult[] = [];
  const ordersActionRequired: GetServiceOrdersResult[] = [];
  const nonCompleteOrders: GetServiceOrdersResult[] = [];

  serviceOrdersResults?.forEach((result: GetServiceOrdersResult) => {
    const deliveredDateMoment: moment.Moment = moment(result.DateDelivered);
    const isOrderInCurrentMonth: boolean = deliveredDateMoment.isSame(moment(), 'month');
    const isOrderInCurrentYear: boolean = deliveredDateMoment.isSame(moment(), 'year');

    if (isOrderInCurrentMonth && result.Status === 'OrderComplete') {
      ordersMTDComplete.push(result);
    }
    if (isOrderInCurrentYear && result.Status === 'OrderComplete') {
      ordersYTDComplete.push(result);
    }
    switch (result.Status) {
      case 'Searching':
        ordersSearching.push(result);
        break;
      case 'Examining':
        ordersExamining.push(result);
        break;
      case 'Quality Review':
        ordersQualityReview.push(result);
        break;
      case 'Action Required':
        ordersActionRequired.push(result);
        break;
    }

    if (result.Status !== 'OrderComplete') {
      nonCompleteOrders.push(result);
    }
  });

  const agGridConfig: AgGridReactProps = {
    rowData: nonCompleteOrders ?? [],
    columnDefs: [
      {
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.value} />;
        },
        field: 'Status',
        filter: 'agSetColumnFilter',
        tooltipField: 'Status',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'File Number',
        field: 'FileNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'FileNumber',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Service Order No.',
        field: 'OrderNumber',
        filter: 'agTextColumnFilter',
        tooltipField: 'OrderNumber',
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${HashUtils.encode(row.data.FileId)}/service-orders`,
        }),
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Office',
        field: 'OfficeName',
        filter: 'agTextColumnFilter',
        tooltipField: 'OfficeName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        width: 256,
        minWidth: 256,
      },
      {
        headerName: 'Property State',
        field: 'PropertyState',
        filter: 'agTextColumnFilter',
        tooltipField: 'PropertyState',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Date Submitted',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'SubmittedDate',
        cellRenderer: (params: ICellRendererParams) => {
          return params?.data?.SubmittedDate?.length > 0 ? params?.data?.SubmittedDate.substring(0, 10) : '';
        },
        tooltipValueGetter: dateTooltipValue,
        sort: 'desc',
      },
      {
        headerName: 'ETA',
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        field: 'ETA',
        cellRenderer: ({ value }: ICellRendererParams) => formatDate(value),
        tooltipValueGetter: dateTooltipValue,
      },
      {
        headerName: 'Transaction Type',
        field: 'TransactionType',
        filter: 'agSetColumnFilter',
        tooltipField: 'TransactionType',
        suppressHeaderFilterButton: true,
      },
      {
        headerName: 'Buyer',
        field: 'Buyer',
        filter: 'agTextColumnFilter',
        tooltipField: 'Buyer',
        suppressHeaderFilterButton: true,
        width: 256,
        minWidth: 256,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Product Name',
        field: 'ProductName',
        filter: 'agTextColumnFilter',
        tooltipField: 'ProductName',
        suppressHeaderFilterButton: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      {
        headerName: 'Business Segment',
        field: 'BusinessSegment',
        filter: 'agSetColumnFilter',
        tooltipField: 'BusinessSegment',
        suppressHeaderFilterButton: true,
      },
      {
        field: '',
        floatingFilter: false,
        cellRenderer: CellMessage,
        onCellClicked: async (e: CellClickedEvent) => {
          try {
            const payload: GetActionLogs = {
              FileId: e.data.FileId,
              ServiceOrderReferenceId: e.data.ServiceOrderReferenceId,
            };
            const response = await getActionLogsData(payload);

            setServiceOrderNumber(e.data.OrderNumber);
            setAuditAndConversations(response);
            setIsDrawerOpen(true);
          } catch (e) {
            let message = 'An unknown error occurred';

            if (e instanceof Error) {
              message = e.message;
            }

            addGlobalMsg({
              message,
              type: 'error',
            });
          }
        },
        width: 40,
        minWidth: 40,
        tooltipValueGetter: () => 'Open conversation panel',
        pinned: 'right',
        suppressHeaderFilterButton: true,
      },
    ],
    maintainColumnOrder: true,
    gridOptions: {
      getRowId: (params: GetRowIdParams<GetServiceOrdersResult>) => String(params.data.OrderNumber),
      suppressRowClickSelection: true,
    },
    defaultColDef: {
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
      suppressSizeToFit: false,
      width: 144,
      minWidth: 144,
    },
    components: {
      customOverlayLoading: CustomOverlayLoading,
    },
    loadingOverlayComponent: 'customOverlayLoading',
    domLayout: 'autoHeight',
    onGridReady: onGridReady,
    onFilterChanged: onFilterChanged,
    onFirstDataRendered: onFirstDataRendered,
    loading: !serviceOrdersResults,
  };

  return (
    <ContentContainer fullWidth>
      <Content className={classes.root}>
        {!selectedOrders ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader
                contentRight={
                  <TimeTracker isUpdated={serviceOrdersStatus === 'success'} onClick={onRefreshHandler}>
                    Check For Updates
                  </TimeTracker>
                }
                menuItems={[
                  {
                    label: 'Knowledge Base',
                    link: KBLinks.jacketsCreate,
                  },
                ]}
                title="Service Orders"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                heading="MTD - Orders Completed"
                value={`${ordersMTDComplete.length}`}
                onClick={() => setSelectedOrders({ title: 'MTD - Orders Complete', orders: ordersMTDComplete })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                heading="YTD - Orders Completed"
                value={`${ordersYTDComplete.length}`}
                onClick={() => setSelectedOrders({ title: 'YTD - Orders Complete', orders: ordersYTDComplete })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <DashboardMetricCard
                heading="Orders in Process"
                value={`${nonCompleteOrders.length}`}
                onClick={() =>
                  setSelectedOrders({ inProcess: true, title: 'Orders in Process', orders: nonCompleteOrders })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subHeading} variant="h3">
                Orders in Process
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Searching'}
                heading="Searching"
                status="Searching"
                value={`${ordersSearching.length}`}
                onClick={() => onTileFilterClick('Searching')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Examining'}
                heading="Examining"
                status="Examining"
                value={`${ordersExamining.length}`}
                onClick={() => onTileFilterClick('Examining')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Quality Review'}
                heading="Quality Review"
                status="Quality Review"
                value={`${ordersQualityReview.length}`}
                onClick={() => onTileFilterClick('Quality Review')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DashboardMetricCard
                active={currentStatus === 'Action Required'}
                heading="Action Required"
                status="Action Required"
                value={`${ordersActionRequired.length}`}
                onClick={() => onTileFilterClick('Action Required')}
              />
            </Grid>
            <Grid item xs={12}>
              <DataTable {...agGridConfig} />

              {serviceOrdersResults && (
                <Typography variant="body2">
                  Showing {displayedRowCount ?? nonCompleteOrders.length} of {serviceOrdersResults.length} orders
                </Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <StatusDashboard {...selectedOrders} onBackClick={onHandleBackClick} />
        )}
      </Content>
      <DrawerComponent isDrawerOpen={isDrawerOpen}>
        <DrawerHeader
          content={<DrawerTitle title={`Order No: ${serviceOrderNumber}`} />}
          contentRight={<DrawerExit onClick={() => setIsDrawerOpen(false)} />}
          divider
          subHeader={
            <Tabs
              aria-label="Audit and converation logs"
              indicatorColor="primary"
              value={tabValue}
              onChange={onTabChange}
            >
              <Tab aria-controls="conversation-panel" id="conversation-tab" label="Conversation" />
              <Tab aria-controls="audit-panel" id="audit-tab" label="Audit Logs" />
            </Tabs>
          }
        />
        <DrawerContent>
          <TabPanel
            aria-labelledby="conversation-tab"
            id="conversation-panel"
            index={0}
            value={tabValue}
            style={{ height: '100%' }}
          >
            <div className={classes.tabContent}>
              {auditAndConversations?.Notes.length ? (
                auditAndConversations?.Notes.map((note, index: number) => {
                  return <ConversationItem conversation={note} key={index} />;
                })
              ) : (
                <NoMessages />
              )}
            </div>
            <form onSubmit={onFormSubmit}>
              <div className={classes.inputContainer}>
                <input
                  className={classes.inputMessage}
                  disabled={isFormSubmitting}
                  placeholder="Type a message..."
                  ref={messageRef}
                />
                <IconButton
                  className={classes.inputSubmit}
                  disabled={isFormSubmitting}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  type="submit"
                >
                  <SendOutlined fontSize="large" />
                </IconButton>
              </div>
            </form>
          </TabPanel>
          <TabPanel aria-labelledby="audit-tab" id="audit-panel" index={1} value={tabValue}>
            {auditAndConversations?.AuditLogs.map((log: AuditLog, index: number) => {
              return <AuditLogItem key={index} log={log} />;
            })}
          </TabPanel>
        </DrawerContent>
        <DrawerFooter>
          <Button color="primary" variant="contained" onClick={() => setIsDrawerOpen(false)}>
            Done
          </Button>
        </DrawerFooter>
      </DrawerComponent>
    </ContentContainer>
  );
}
