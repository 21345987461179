import { pxToRem } from '@fluentsms/agentnet-web-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { fileReopenSchema } from 'utilities/validation/schemas/file-reopen-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import AgentNetButton from '../button/AgentNetButton';
import RadioGroup, { RadioProps } from '../radios/RadioGroup';

interface AgentNetConfirmFileReopenModalProps {
  onClose: () => void;
  onConfirm: (value: any, type: string) => void;
  isActionInProgress: boolean;
  open: boolean;
  isReopenEligible: boolean;
  hasRemitAccess: boolean;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: '6.4rem 0 0 0',
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    fontSize: pxToRem(14),
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
  },
  preferencesTextBox: {
    '& p.MuiFormHelperText-root': {
      marginLeft: '0',
    },
    '& textarea': {
      height: '56px',
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  dialogWrap: {
    minWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  endorsementList: {
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    fontSize: '14px',
    fontWeight: 400,
    padding: '0',
    paddingTop: theme.spacing(0),
    display: 'list-item',
    listStyleType: 'disc',
    color: '#000',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    padding: pxToRem(24),
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& svg': {
      width: '231px',
      height: 'auto',
    },
  },
  menuIconBtn: {
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: theme.palette.primary.dark,
    },
  },
}));

const AgentNetConfirmFileReopenModal = ({
  onClose,
  onConfirm,
  isActionInProgress,
  open,
  hasRemitAccess,
  isReopenEligible,
}: AgentNetConfirmFileReopenModalProps) => {
  const [fileReopenObj, setFileReopenObj] = useState<any>({
    fileNumber: '',
    reason: '',
  });

  const fileReopenOptions = useMemo(() => {
    const options = [];
    if (hasRemitAccess) {
      options.push({
        label: 'Full Reopen',
        value: 'reopen',
        ...(!isReopenEligible && {
          disabled: true,
          tooltipText: 'File must be invoiced',
        }),
      });
    }
    options.push({ label: 'Add Endorsements', value: 'endorsement' });
    return options;
  }, [hasRemitAccess, isReopenEligible]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [currentFileReopenOption, setFileOpenOption] = useState<RadioProps>(fileReopenOptions[0]);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setFileOpenOption(() => {
      if (fileReopenOptions?.length === 2 && (fileReopenOptions[0]?.disabled || isReopenEligible)) {
        return fileReopenOptions[1];
      }
      return fileReopenOptions[0];
    });
  }, [fileReopenOptions]);

  const handleFileReopen = async () => {
    setIsSubmitted(currentFileReopenOption.value !== 'endorsement');
    if (!validationErrors || validationErrors.length === 0 || currentFileReopenOption.value === 'endorsement') {
      await onConfirm(fileReopenObj, currentFileReopenOption.value);
    }
  };

  const handleFileReopenOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentMode = fileReopenOptions?.find((option) => option.value === e.target.value) ?? currentFileReopenOption;
    setFileOpenOption(currentMode);
  };

  useEffect(() => {
    if (!open) {
      handleCloseDialog();
    }
  }, [open]);

  useEffect(() => {
    if (currentFileReopenOption.value !== 'endorsement') {
      doValidate(fileReopenObj, fileReopenSchema()).then((errs: FieldValidationError[]) => {
        setValidationErrors(errs);
      });
    } else {
      setValidationErrors([]);
    }
  }, [fileReopenObj, currentFileReopenOption]);

  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setFileReopenObj({ fileNumber: '', reason: '' });
    onClose();
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="contact-us"
      open={open}
      maxWidth={'xs'}
      PaperProps={{ className: classes.dialogWrap }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" color="textSecondary">
            Reopen File
          </Typography>
          <IconButton color="secondary" className={classes.dialogClose} onClick={handleCloseDialog}>
            <Close fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid item sm={12}>
          <RadioGroup
            selected={currentFileReopenOption}
            options={fileReopenOptions}
            onChange={handleFileReopenOption}
          />
        </Grid>
        {currentFileReopenOption.value === 'reopen' ? (
          <>
            <div>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  Remitted products will be returned to Open status for editing.
                </ListItem>
                <ListItem className={classes.listItem}>
                  Remitted fees will be held in accounting pending resubmission or cancellation.
                </ListItem>
                <ListItem className={classes.listItem}>
                  Resubmitted files will not be eligible for payment until an invoice is generated, to ensure credits
                  are properly applied.
                </ListItem>
              </List>
            </div>
            <AgentNetTextInput
              multiline
              fullWidth
              className={classes.preferencesTextBox}
              helperText="Please indicate your preferences for managing previous payments, i.e., refund, reapply payment to file on re-remit, apply payment to other file, etc."
              variant="outlined"
              placeholder="Enter preferences..."
              rows={2}
              label=""
              name="reason"
              id="reason"
              required
              data-testid={'ReopenReason'}
              value={fileReopenObj.reason}
              showValidation={isSubmitted}
              onChange={(event: any) => setFileReopenObj({ ...fileReopenObj, reason: event.target.value })}
              errs={validationErrors}
            />
          </>
        ) : (
          <List className={classes.endorsementList}>
            <ListItem className={classes.listItem}>Allows endorsements to be added to the file.</ListItem>
            <ListItem className={classes.listItem}>Will not impact previously remitted products.</ListItem>
            <ListItem className={classes.listItem}>Immediately available to queue, report and pay.</ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions className="modal-btn-container ghost-white flex-sb p-16-24">
        <AgentNetButton
          variant="outlined"
          color="primary"
          onClick={handleCloseDialog}
          aria-label="cancel-delete"
          data-testid="FileReopenCancel"
        >
          Cancel
        </AgentNetButton>
        <AgentNetButton
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm-delete"
          data-testid={'FileReopenConfirm'}
          onClick={handleFileReopen}
          loading={isActionInProgress}
          disabled={isActionInProgress}
        >
          Yes, Reopen File
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export default AgentNetConfirmFileReopenModal;
