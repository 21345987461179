import { ButtonProps } from '@material-ui/core';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import React, { useState } from 'react';

type OrderInsightUpdateButtonProps = ButtonProps & {
  btnText: string;
  onMouseHoverBtnText: string;
  loading?: boolean;
  'data-testid'?: string;
};
const OrderInsightUpdateButton: React.FC<OrderInsightUpdateButtonProps> = (props: OrderInsightUpdateButtonProps) => {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: 'inline-block' }}>
      {hovered ? (
        <p
          style={{
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            color: '#0074CA',
            fontWeight: 500,
            fontSize: '13px',
          }}
        >
          <span data-testid={'RefreshOrderInsights'} onClick={props.onClick}>
            {props.onMouseHoverBtnText}
          </span>
          <HistoryOutlinedIcon
            style={{
              marginLeft: '6px',
              fontSize: '18px',
            }}
          ></HistoryOutlinedIcon>
        </p>
      ) : (
        <p
          style={{
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            color: '#666',
            fontSize: '13px',
          }}
        >
          {props.btnText}
          <HistoryOutlinedIcon
            style={{
              marginLeft: '6px',
              fontSize: '18px',
            }}
          ></HistoryOutlinedIcon>
        </p>
      )}
    </div>
  );
};

export default OrderInsightUpdateButton;
