import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ArrowBack, Restore } from '@material-ui/icons';
import MailOutlineOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { postUserActivationEmail } from 'api/manageUsers/manage-users-api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { UserRoleTypes } from '../constants';
import ViewHistory from './ViewHistory';

interface userBannerProps {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  userId?: number | string;
  payConnectionId?: string;
  roleId?: any;
  id?: number;
  email?: string;
  roleType?: number;
  userStatus?: string;
}

const UserBanner = ({
  firstName,
  middleName,
  lastName,
  userId,
  payConnectionId,
  roleId,
  id,
  email,
  roleType,
  userStatus,
}: userBannerProps) => {
  const formattedFirstName = firstName ? firstName[0].toUpperCase() + firstName.slice(1).toLowerCase() : '';
  const formattedLastName = lastName ? lastName[0].toUpperCase() + lastName.slice(1).toLowerCase() : '';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      contentWrap: {
        padding: pxToRem(0, 0, 32),
        maxWidth: 1200,
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      userBannerWrap: {
        padding: theme.spacing(3, 3, 0),
      },
      userBannerContainer: {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      userBannerDetails: { display: 'flex', alignItems: 'center', width: '100%' },
      userDetails: {
        marginLeft: theme.spacing(2),
      },
      userName: {
        marginBottom: theme.spacing(0.5),
        textTransform: 'none',
      },
      avatar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '46px',
        height: '46px',
        borderRadius: '46px',
        border: `1px solid #013A6F`,
        textAlign: 'center',
        color: theme.palette.primary.dark,
        '& span': {
          marginTop: '4px',
        },
      },
      captionDivider: {
        width: theme.spacing(2),
        display: 'inline-block',
      },
      fieldSection: {},
      fieldSectionLeft: {
        padding: theme.spacing(3),
      },
      fieldSectionRight: {
        padding: theme.spacing(3),
        marginTop: 0,
      },
      adornmentIcon: {
        padding: 0,
        '&:hover': {
          background: 'transparent',
        },
      },
      ctaBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),
        width: '100%',
      },
      alertContainer: {
        marginBottom: '63px',
      },
      alertMsg: {
        width: '-webkit-fill-available',
        position: 'absolute',
        bottom: '63px',
        left: 0,
        padding: '0 35px',
      },
      ml1: {
        marginLeft: theme.spacing(1),
      },
      pageHeader: {
        marginTop: theme.spacing(1),
      },
    }),
  );
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [OpenModelFirm, setOpenModelFirm] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();

  function isUserIdDateFormat() {
    const dateFormat = 'MM/DD/YYYY h:mm:ss A';
    // Use moment to check if userId matches the date format
    return userId && userStatus === 'Not Activated' && moment(userId, dateFormat, true).isValid();
  }

  useEffect(() => {
    const activeUserStatus = userStatus === 'Not Activated' && roleType === 100;
    setShowEmail(activeUserStatus);
  }, []);

  const dismissSendActivationEmail = () => {
    setOpenModelFirm(false);
  };

  const sendActivationEmail = async () => {
    const token = await getAccessToken();
    const responsedata = await postUserActivationEmail(token, id);
    if (responsedata) {
      addSnackbarMessage({
        message: 'Activation Email Successfully Sent',
        type: 'success',
      });
    } else {
      addSnackbarMessage({
        message: 'Email Could Not Be Sent. Try Again Later!',
        type: 'error',
      });
    }
    setOpenModelFirm(false);
    setSendEmail(false);
  };

  const dialogText = `Account information and login credential will be sent to ${email}. Do you want to proceed?`;

  return (
    <div className={classes.userBannerWrap}>
      <AgentNetButton
        variant="text"
        size="small"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => window.location.reload()}
        data-testid="backToUserList"
      >
        Back To User List
      </AgentNetButton>
      <PageHeader
        title={
          'Edit User - ' +
            ((formattedFirstName && formattedFirstName.trim()) ?? '') +
            (formattedFirstName && (middleName || formattedLastName) ? ' ' : '') +
            ((middleName && middleName.trim()) ?? '') +
            (middleName && formattedLastName ? ' ' : '') +
            (formattedLastName && formattedLastName.trim()) ?? ''
        }
        subtitle={userId && !isUserIdDateFormat() && `User ID: ${userId} `}
        contentRight={
          <div className="userBannerPw">
            {showEmail === true && (
              <AgentNetButton
                onClick={() => {
                  setSendEmail(true);
                  setOpenModelFirm(true);
                }}
                variant="outlined"
                color="primary"
                data-testid="sendEmail"
                startIcon={<MailOutlineOutlinedIcon fontSize="small" htmlColor="#0074CA" />}
                size="small"
              >
                Send Account Information
              </AgentNetButton>
            )}
            <AgentNetButton
              onClick={() => {
                setDrawerOpen(true);
              }}
              variant="outlined"
              color="primary"
              data-testid="openHistory"
              startIcon={<Restore fontSize="small" htmlColor="#0074CA" />}
              className={classes.ml1}
              size="small"
            >
              History
            </AgentNetButton>
          </div>
        }
        disableMargin="x"
        className={classes.pageHeader}
      />
      <div className={classes.userBannerContainer}>
        {drawerOpen && id ? (
          <ViewHistory userId={id} handleDrawerOpen={drawerOpen} closeDrawer={() => setDrawerOpen(false)} />
        ) : null}
        {roleId === UserRoleTypes.Agent ? (
          <div className="userBannerPw">
            <AgentNetButton variant="contained" color="primary" size="small" data-testid="UserProfileChangePassword">
              Change Password
            </AgentNetButton>
          </div>
        ) : null}
        {sendEmail && (
          <AgentNetConfirmationDialog
            qaAttrPrefix="ConfirmationCancel"
            onConfirm={sendActivationEmail}
            open={OpenModelFirm}
            onDismissAction={dismissSendActivationEmail}
            dialogTitle="Resend Activation Email"
            dialogBtnContent="Yes"
            secondaryActionBtnContent="No"
            dialogText={dialogText}
          />
        )}
      </div>
    </div>
  );
};

export default UserBanner;
