import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  idaasBackground: {
    backgroundColor: theme.palette.brand.sky50,
    width: '100%',
    height: '100vh',
  },
}));

const IdaasBackgroundComponent = () => {
  const classes = useStyles();
  return <div className={classes.idaasBackground}></div>;
};

export default IdaasBackgroundComponent;
