export const underwriter_acNum: any = {
  FATIC: '0699289310',
  FATGC: '657580663',
  'FATIC-LA': '790007629',
};

export const underwriter_code: any = {
  FATIC: 'FATICO',
  'FATIC-LA': 'FALA',
};

export const validateDataFields = ['wireNumber', 'checkNumber', 'email', 'memo', 'ePayId'];

export const paymentOptions = [
  { label: 'ePay', value: 'ePay', dataTestId: 'PaySumOptionePay' },
  { label: 'Check', value: 'Check', dataTestId: 'PaySumOptionCheck' },
  { label: 'Wire', value: 'Wire', dataTestId: 'PaySumOptionWire' },
];
