import { useAuth } from '@agentnet/auth';
import { getFileInfo, getFileInfoProps } from 'api/file/file-api';
import { fileInfoType } from 'api/file/interfaces/get-file';
import FileInformation from 'features/files/file-information/FileInformation';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { DefaultNewFile } from 'models/file-model';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { Notification } from 'ui-kit/components/notification/Notification';
import { useViewState } from '../../../../hooks/ViewStateContext';

const FileInfoContainer = (): JSX.Element => {
  /* get the fileId from the url */
  const { fileId } = useParams<getFileInfoProps>();
  const [propertyAdded, setPropertyAdded] = useState<boolean>(false);
  const { getAccessToken } = useAuth();
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue, fileId]);

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const {
    fileData,
    setPartyUpdated,
    setFileData,
    setCleanData,
    setfileDataUpdated,
    setOpenPendingCpl,
    setRemmittableCpl,
    setFirmAnchorEl,
  } = fileDataCtx;
  const isPropertyAdded = () => {
    setPropertyAdded(!propertyAdded);
  };
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  useEffect(() => {
    if (!userFirm?.firmId) {
      history.push('/err');
    }
  }, [userFirm]);
  /* Handle GET File DATA and its values  */
  const getFile = async (): Promise<fileInfoType> => {
    const token = await getAccessToken();
    return getFileInfo(fileId ?? '', token);
  };

  const {
    execute: executeGetFile,
    status,
    value: fileApiData,
    errors: getFileError,
  } = useAsync<fileInfoType>(getFile, false);
  /* Fetch the data for current file */
  useEffect(() => {
    sessionStorage.removeItem('fileId');
    setFileData?.(DefaultNewFile);
    setCleanData?.(DefaultNewFile);
    executeGetFile().then();
  }, [fileId]);

  /* Updating Context update File */
  useEffect(() => {
    if (fileDataCtx) {
      sessionStorage.setItem('fileId', fileApiData?.fileId ?? '');
      setFileData && setFileData(fileApiData);
      setCleanData && setCleanData(fileApiData);
      setFirmAnchorEl && setFirmAnchorEl(null);
      if (fileApiData) {
        setRemmittableCpl && setRemmittableCpl(undefined);
        setOpenPendingCpl && setOpenPendingCpl(-1);
        setfileDataUpdated && setfileDataUpdated(true);
        setPartyUpdated && setPartyUpdated(false);
        window.sessionStorage.setItem('properties', JSON.stringify(fileApiData?.properties ?? []));
      }
    }
  }, [fileApiData]);

  return (
    <div className="paper-layout contained">
      <LoadingSpinner status={status} variant="linear" />

      {status === 'error' && <Notification severity="error">Error in getting file details</Notification>}

      {fileData && (
        <FileInformation
          isAdmin={false}
          isPropertyAdded={isPropertyAdded}
          getFileApiStatus={status}
          getFileError={getFileError}
        />
      )}
    </div>
  );
};

export default FileInfoContainer;
