import { useAuth } from '@agentnet/auth';
import { CellLink, CellStatus, Content, DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { Box, ButtonGroup, Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
  RowNode,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { getFiles } from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import CalculatorRenderer from 'features/Remittance/CalculatorRenderer';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { useOrderInfo } from 'hooks/OrderInfoContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import { fileListFilterParams, formatDate, numberValueFormatter, queueFilters } from './fileListConfig';
import { fileCountsType, fileType, orderType } from './types';

const SelectFiles = () => {
  const { getAccessToken } = useAuth();

  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const location = useLocation<{ order: orderType }>();
  const orderData = location.state?.order;
  const gridApiRef = useRef<GridApi | null>(null);
  const [queue, setQueue] = useState<Array<fileType>>([]);
  const [allFiles, setAllFiles] = useState<Array<fileType>>([]);
  const [queueFilter, setQueueFilter] = useState<string>('allFiles');
  const [selectedRows, setSelectedRows] = useState<Array<fileType>>([]);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    'order-management',
  );

  const dataQaPrefix = 'OrderManagement-SelectFiles';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
      backButton: {
        margin: theme.spacing(2, 0, -3, 3),
      },
      pageHeaderTitle: {
        textTransform: 'uppercase',
      },
      tableDefault: {
        height: `calc(100vh - ${selectedRows?.length ? '367px' : '307px'}) !important`,
      },
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      selectedRows: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100% !important',
        fontSize: pxToRem(14),
        padding: theme.spacing(3),
        paddingBottom: 12,
      },
      selectedRowsActive: {
        marginBottom: '59px',
        paddingBottom: 0,
      },
      noRows: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 220,
        justifyContent: 'center',
      },
      footerWrap: {
        position: 'fixed',
        bottom: 0,
        background: '#E7F1FA',
        borderTop: `1px solid`,
        borderColor: theme.palette.divider,
        width: '100%',
        padding: '11px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 11,
        left: 0,
      },
      footerActions: {
        '& .MuiButton-root': {
          marginLeft: theme.spacing(2),
        },
      },
      cancelBtn: {
        backgroundColor: theme.palette.brand.lightGrey50,
      },
    }),
  );

  const classes = useStyles();

  const getFileApi = async (firmId: number): Promise<Array<fileType>> => {
    const token = await getAccessToken();
    const response = await getFiles(firmId, token);
    return response.result;
  };

  const getDisplayedRowCount = () => {
    if (gridApiRef.current) return gridApiRef.current.getDisplayedRowCount();
    return 0;
  };

  const { setSelectedFilesAmount } = useOrderInfo();

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApiRef.current?.getSelectedRows() ?? [];
    setSelectedRows(selectedRows);
    const grossTotal = selectedRows.reduce((acc, row) => acc + row.gross, 0);
    const netTotal = selectedRows.reduce((acc, row) => acc + row.net, 0);
    const netDifference = parseFloat((netTotal - (orderData?.reportedAmount || 0)).toFixed(2));
    setSelectedFilesAmount({ calculatedGross: grossTotal, calculatedNet: netTotal, netDifference: netDifference });
  }, [setSelectedFilesAmount]);

  const netAmountTotal = selectedRows?.reduce((accumulator: number, obj: fileType) => accumulator + obj['net'], 0);
  const selectedFilesText = `Selecting ${selectedRows?.length} file(s) for ${numberValueFormatter(
    netAmountTotal,
    true,
  )}`;

  const hasFutureDate = (val: { policyDate?: string }) => {
    return val?.policyDate && +new Date(val.policyDate) > +new Date();
  };

  const isRowSelectable = (params: { data: { policyDate?: string } }) => {
    return !hasFutureDate(params.data);
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={'OrderManagement-SelectFiles-AllCheckbox'} />;
  };

  const rowCheckboxRenderer = (params: { node: RowNode }) => {
    return <input type="checkbox" data-testid={`OrderManagement-SelectFiles-Checkbox-${params.node.id}`} />;
  };

  const policyDateRenderer = (params: { value: string }) => {
    return <div>{formatDate(params.value ?? '')}</div>;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const defaultColDef: ColDef = {
    flex: 2,
    floatingFilter: true,
    filter: true,
    sortable: true,
    resizable: true,
    editable: false,
    suppressMenu: true,
    filterParams: { closeOnApply: true, suppressAndOrCondition: true },
  };

  const fileColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 48,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      tooltipField: 'status',
      cellRenderer: (params: ICellRendererParams) => {
        return <CellStatus status={params.value} />;
      },
    },
    {
      headerName: 'File Number',
      field: 'fileNumber',
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellRenderer: CellLink,
      // temporary open in new tab until we have a proper order management navigation menu
      cellRendererParams: (row: ValueFormatterParams) => ({
        linkTemplate: `/files/${row.data.fileId}/file-info`,
        openNewTab: true,
      }),
    },
    {
      headerName: 'Account Number',
      field: 'accountNumber',
      filter: 'agTextColumnFilter',
      tooltipField: 'accountNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Underwriter',
      field: 'underwriter',
      filter: 'agSetColumnFilter',
      tooltipField: 'underwriter',
    },
    {
      headerName: 'Policy Date',
      field: 'policyDate',
      filter: 'agDateColumnFilter',
      filterParams: fileListFilterParams,
      cellRenderer: policyDateRenderer,
      colId: 'policyDateColumn',
      sort: 'asc',
      tooltipValueGetter: (params: ITooltipParams) => {
        return hasFutureDate(params.data)
          ? 'Future dated policies cannot be Queued'
          : moment(params.value).format('MM/DD/YYYY');
      },
      maxWidth: 200,
    },
    {
      headerName: 'Gross',
      field: 'gross',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      type: 'rightAligned',
      cellStyle: { 'justify-content': 'flex-end' },
    },
    {
      headerName: 'Net',
      field: 'net',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      type: 'rightAligned',
      cellStyle: { 'justify-content': 'flex-end' },
    },
    {
      field: '',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: { tabName: 'order-management' },
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'order-management');
      },
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
    },
  ];

  const statusColumn: ColDef[] = fileColumnsConfig.splice(1, 1);
  const queueColumnsConfig = [...fileColumnsConfig.slice(0, 1), ...statusColumn, ...fileColumnsConfig.slice(1)];

  const getFileListData = async (): Promise<fileType[]> => {
    const firmId = userFirm?.firmId ? Number(userFirm.firmId) : 0;
    const result = await getFileApi(firmId);
    // Set default status as Open
    result?.forEach((file) => {
      file.status = 'Open';
    });
    setAllFiles(result);
    setDisplayedRowCount(result?.length);
    return result;
  };

  const { execute: fileListExecute, value: fileListResults } = useAsync<fileType[]>(getFileListData, false);

  const totalFileCount = fileListResults?.length || 0;
  const orderQueueCount = queue?.length || 0;
  const allFilesCount = totalFileCount - orderQueueCount;
  const queueFilesCount: fileCountsType = {
    allFiles: allFilesCount,
    orderQueue: orderQueueCount,
  };

  useEffect(() => {
    fileListExecute();
  }, []);

  if (!fileListResults) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <div className={classes.backButton}>
          <AgentNetButton
            color="primary"
            variant="text"
            size="medium"
            startIcon={<ArrowBack />}
            onClick={() => {
              localStorage.removeItem('userFirm');
              history.replace('/order-management', undefined);
            }}
            data-testid={`${dataQaPrefix}-Back-To-Order-Management`}
          >
            Back To Order Management
          </AgentNetButton>
        </div>
        <Box mx={3} my={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex">
              <Typography variant="h1" component="h1" className={classes.pageHeaderTitle}>
                Select Files for Order
              </Typography>
              <Box display="flex"></Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item style={{ margin: pxToRem(16, 0, 16, 24), display: 'flex-end' }}>
                  <ButtonGroup>
                    {queueFilters.map((obj) => (
                      <AgentNetButton
                        key={obj?.value}
                        variant={queueFilter === obj?.value ? 'contained' : 'outlined'}
                        onClick={() => {
                          setQueueFilter(obj?.value);
                          setDisplayedRowCount(queueFilesCount[obj?.value as keyof fileCountsType]);
                        }}
                        data-testid={`${dataQaPrefix}-${[obj?.value]}`}
                        color="primary"
                        size="small"
                      >
                        {`${obj?.name} (${queueFilesCount[obj?.value as keyof fileCountsType]})`}
                      </AgentNetButton>
                    ))}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box marginX={3} marginTop={-3} marginBottom={3}>
          <Divider />
        </Box>
        <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
          <DataTable
            rowData={queueFilter === 'allFiles' ? allFiles : queue}
            columnDefs={queueFilter === 'allFiles' ? fileColumnsConfig : queueColumnsConfig}
            defaultColDef={defaultColDef}
            gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChanged}
            animateRows={true}
            onGridReady={onGridReady}
            {...{ isRowSelectable }}
            components={{
              customDropdownFilter: FloatingFilterComponent,
              customOverlayLoading: CustomOverlayLoading,
            }}
            loadingOverlayComponent={'customOverlayLoading'}
          />
        </div>
        <div className={clsx(classes.paddingX, classes.selectedRows)}>
          <span>
            Showing {displayedRowCount ?? getDisplayedRowCount()} of{' '}
            {queueFilesCount[queueFilter as keyof fileCountsType]} {'files'}
          </span>
          {selectedRows?.length ? selectedFilesText : null}
        </div>
        {selectedRows?.length ? (
          <BottomAppBar
            cancelLabel="Clear All"
            onCancel={() => {
              setSelectedRows([]);
              gridApiRef.current?.deselectAll();
            }}
            cancelDataQa={`${dataQaPrefix}-ClearAll`}
            primaryActionLabel={queueFilter === 'allFiles' ? 'Queue' : 'Remove from Queue'}
            onPrimaryClick={() => {
              if (queueFilter === 'allFiles') {
                const filteredFiles = allFiles.filter((file) => !selectedRows.includes(file));
                setAllFiles(filteredFiles);
                setQueue([...queue, ...selectedRows]);
                setQueueFilter('orderQueue');
                setDisplayedRowCount(queueFilesCount.orderQueue + selectedRows.length);
              } else {
                const filteredFiles = queue.filter((file) => !selectedRows.includes(file));
                setQueue(filteredFiles);
                setAllFiles([...allFiles, ...selectedRows]);
                setQueueFilter('allFiles');
                setDisplayedRowCount(queueFilesCount.allFiles + selectedRows.length);
              }
            }}
            primaryActionDataQa={
              queueFilter === 'allFiles' ? `${dataQaPrefix}-Queue` : `${dataQaPrefix}-Remove-From-Queue`
            }
          />
        ) : null}
        <ReportsAndPayDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          drawerData={drawerData}
          setDrawerData={setDrawerData}
          getUpdatedData={fileListExecute}
          rateAndFeeExecute={rateAndFeeExecute}
        />
      </div>
    </div>
  );
};

export default SelectFiles;
