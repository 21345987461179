import { useAuth } from '@agentnet/auth';
import { Grid } from '@material-ui/core';
import { format as formatDate } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import { AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import AutocompleteFirmSearch from 'ui-kit/inputs/AutocompleteFirmSearch/AutocompleteFirmSearch';
import AutocompleteSearch from 'ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';

import { getFirmDetails } from 'api/onBehalfOf/api';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { orderManagementTypeSchema } from 'utilities/validation/schemas/order-management-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

import { firmSearch, getLockboxNumber } from 'api/orderManagement/order-management-api';
import './OrderManagement.scss';
import { firmListType, lockboxNumberType, orderType } from './types';

interface OrderNewContainerProps {
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
}

const OrderCreateContainer = ({ openDrawer, setOpenDrawer }: OrderNewContainerProps): JSX.Element => {
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm } = profileCtx;

  const handleCancelCreate = () => {
    setFirmList([]);
    setFirmSearchInput('');
    setNoMatchesLabel(defaultNoMatchesMessage);
    setOrderDate(null);
    setBatchNumber('');
    setNumberOfChecks(0);
    setReportedAmount(0);
    setValidationErrors([]);
    setOpenDrawer(false);
  };

  const showValidation = true;
  const defaultNoMatchesMessage = 'Enter at least 3 characters to refine search';

  const [firmList, setFirmList] = useState<Array<firmListType>>(() => []);
  const [lockboxNumberList, setLockboxNumberList] = useState<Array<lockboxNumberType>>(() => []);

  const [firmSearchInput, setFirmSearchInput] = useState<string>('');
  const [myFirm, setMyFirm] = useState<firmListType | null>(null);
  const [orderDate, setOrderDate] = useState<string | null>(null);
  const [lockboxNumber, setLockboxNumber] = useState<lockboxNumberType | null>(null);
  const [batchNumber, setBatchNumber] = useState<string>('');
  const [numberOfChecks, setNumberOfChecks] = useState<number>(0);
  const [reportedAmount, setReportedAmount] = useState<number>(0);

  const [noMatchesLabel, setNoMatchesLabel] = useState<string>(defaultNoMatchesMessage);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  const clearSearch = () => {
    setFirmList([]);
    setFirmSearchInput('');
    setNoMatchesLabel(defaultNoMatchesMessage);
  };

  const getSelectedFirmOption = (event: number) => {
    if (firmList.length > 0) {
      const selectedFirm = firmList.find((firm) => firm.value === event);
      if (selectedFirm) {
        setMyFirm(selectedFirm);
      }
    }
  };

  const firmSearchTyping = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target?.value;
    if (input?.length > 2) {
      const result = await firmSearchApi(input);
      setFirmList(result);
    } else {
      setNoMatchesLabel(defaultNoMatchesMessage);
    }
  };

  const firmSearchApi = async (input: string): Promise<Array<firmListType>> => {
    const token = await getAccessToken();
    const response = await firmSearch({ searchText: input, isActive: true }, token);
    const result = response.result;
    const firmList: Array<firmListType> = [];
    if (Array.isArray(result)) {
      result.forEach((firm) => {
        firmList.push({
          name: firm.firmName,
          value: firm.firmId,
          display: firm.firmName + ' (' + firm.firmId + ')',
          isActive: firm.isActive,
        });
      });
    }
    // Account Number Matching
    if (firmList.length === 1 && input.length > 5 && !isNaN(Number(input))) {
      firmList[0].accountNumber = input;
    } else if (firmList.length === 0 && input.length > 2 && !isNaN(Number(input))) {
      setNoMatchesLabel('Enter complete account number to refine search');
    } else {
      setNoMatchesLabel('No matching firms found');
    }
    return firmList;
  };

  const lockboxNumberApi = async (): Promise<Array<lockboxNumberType>> => {
    const token = await getAccessToken();
    const response = await getLockboxNumber(token);
    return response.result;
  };

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const clearError = (field: string) => {
    if (validationErrors?.some((err) => err.field === field)) {
      setValidationErrors((prevErrs: FieldValidationError[]) => {
        return prevErrs.filter((err: { field: string }) => err.field !== field);
      });
    }
  };

  const setFirmName = async () => {
    const token = await getAccessToken();
    const firmDetailsResults = await getFirmDetails(token, String(myFirm?.value));
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
    }
  };

  useEffect(() => {
    const fetchLockboxNumbers = async () => {
      const result = await lockboxNumberApi();
      setLockboxNumberList(result);
    };
    fetchLockboxNumbers();
  }, []);

  return (
    <FormDrawerComponent
      title={'Create Order'}
      open={openDrawer}
      primaryActionProps={{}}
      dismissActionProps={{}}
      primaryActionLabel="Select Files"
      onPrimaryAction={() => {
        doValidate(
          {
            firmSearchInput,
            orderDate,
            batchNumber,
            numberOfChecks,
          },
          orderManagementTypeSchema,
        ).then((errs: FieldValidationError[]) => {
          getFilteredErrors(errs, ['firmSearchInput', 'orderDate', 'batchNumber', 'numberOfChecks']);
          if (!errs) {
            // Set user input to order object
            const myOrder: orderType = {
              firm: {
                firmId: myFirm?.value || 0,
                firmName: myFirm?.name || '',
                isActive: myFirm?.isActive || false,
              },
              orderDate: orderDate || '',
              lockboxNumber: {
                name: lockboxNumber?.name || '',
                value: lockboxNumber?.value || 0,
              },
              batchNumber: batchNumber,
              numberOfChecks: numberOfChecks,
              reportedAmount: reportedAmount,
            };
            // Close the drawer
            setOpenDrawer(false);
            // Set the firm name in the header
            setFirmName().then(() => {
              // Go to the select files page with the order object
              history.push('/order-management', { order: myOrder });
            });
          }
        });
      }}
      onDismissAction={handleCancelCreate}
      testId="scrollable-form-drawer"
      width={600}
    >
      <Grid container spacing={3} className="create-order-form-container">
        <Grid item xs={12} className="firm-search-form">
          <AutocompleteFirmSearch
            label="Firm Name/Account No."
            name="firmSearchInput"
            data-testid={'OrderManagement-CreateOrder-FirmSearch'}
            required={true}
            hideSearchIcon
            onChange={(event) => {
              firmSearchTyping(event);
              getSelectedFirmOption(event);
              clearError('firmSearchInput');
            }}
            onBlur={(event) => {
              clearSearch();
              setFirmSearchInput((event.target as HTMLInputElement)?.value);
            }}
            options={firmList}
            noMatchesLabel={noMatchesLabel}
            error={validationErrors?.some((err) => err.field === 'firmSearchInput') && showValidation}
            errs={validationErrors}
            helperText={
              validationErrors?.some((err) => err.field === 'firmSearchInput') && showValidation && 'Firm is required'
            }
            showValidation={showValidation}
          />
        </Grid>
        <Grid item sm={6}>
          <DateFieldString
            label="Order Date"
            id="order-date"
            required={true}
            data-testid={'OrderManagement-CreateOrder-OrderDate'}
            maxDate="12/31/2100"
            value={(() => {
              const dateParsed = Date.parse(orderDate || '');
              return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : orderDate;
            })()}
            error={validationErrors?.some((err) => err.field === 'orderDate') && showValidation}
            errs={validationErrors}
            name="orderDate"
            onChange={(dateVal) => {
              setOrderDate(dateVal !== '' ? dateVal : null);
              clearError('orderDate');
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSearch
            label="Lockbox Number"
            name="lockboxNumber"
            data-testid={'OrderManagement-CreateOrder-LockboxNumber'}
            hideSearchIcon
            options={lockboxNumberList}
            noMatchesLabel=""
            onChange={(event) => {
              const selectedLockbox = lockboxNumberList.find((lockbox) => lockbox.value === event);
              if (selectedLockbox) {
                setLockboxNumber(selectedLockbox);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AgentNetTextInput
            variant="outlined"
            fullWidth
            label="Batch/Ticket Reference Number"
            name="batchNumber"
            id="batchNumber"
            data-testid={'OrderManagement-CreateOrder-BatchNumber'}
            errs={validationErrors}
            onChange={(event) => {
              setBatchNumber(event.target.value);
              clearError('batchNumber');
            }}
            error={validationErrors?.some((err) => err.field === 'batchNumber') && showValidation}
            helperText={
              validationErrors?.some((err) => err.field === 'batchNumber') && showValidation && 'Invalid Batch Number'
            }
          ></AgentNetTextInput>
        </Grid>
        <Grid item xs={6}>
          <AgentNetTextInput
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            variant="outlined"
            fullWidth
            label="Number of Checks"
            name="numberOfChecks"
            id="numberOfChecks"
            data-testid={'OrderManagement-CreateOrder-NumberOfChecks'}
            errs={validationErrors}
            onChange={(event) => {
              setNumberOfChecks(Number(event.target.value));
              clearError('numberOfChecks');
            }}
            error={validationErrors?.some((err) => err.field === 'numberOfChecks') && showValidation}
            showValidation={showValidation}
          ></AgentNetTextInput>
        </Grid>
        <Grid item sm={6}>
          <CurrencyField
            variant="outlined"
            fullWidth
            label={'Reported Total'}
            id="reportedTotal"
            defaultValue={0}
            name={'reportedTotal'}
            errs={validationErrors}
            max={100000000000}
            allowNegative={false}
            data-testid={'OrderManagement-CreateOrder-ReportedTotal'}
            onChange={(event) => setReportedAmount(Number(event.target.value))}
          />
        </Grid>
      </Grid>
    </FormDrawerComponent>
  );
};

export default OrderCreateContainer;
