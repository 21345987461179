import { Grid } from '@material-ui/core';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { lenderSchema, partiesMethodSchema } from 'utilities/validation/schemas';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import AddLenderFormWithBaseFormGroup from '../form/AddLenderFormWithBaseFormGroup';
import AddPartyForm from '../form/AddPartyForm';
import { mortgageClause, MortgageClauseTextOptions } from '../form/MortgageClause';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';

const PartyOptions: SelectOption[] = [
  { name: 'Buyer/Borrower', value: 'buyer' },
  { name: 'Seller/Owner', value: 'seller' },
  { name: 'Lender', value: 'lender' },
];

const Form: React.FC<any> = (props: any) => {
  const {
    value,
    currentIndex,
    showAllValidation,
    updateParty,
    lenderList,
    getLenderStatus,
    lenderFirmList,
    handleSetFormValid,
    fileInfo,
    updateCurrentParties,
    onChanges,
    savedParties,
  } = props;
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  const onChangeWithValidation = async (partyFields: any) => {
    const errs = (await doValidate(partyFields, partiesMethodSchema)) || [];
    handleSetFormValid(errs.length === 0);
    setValidationErrors(errs);
  };

  useEffect(() => {
    if (showAllValidation && !value.partyMethod) onChangeWithValidation({ ...value });
  }, [showAllValidation, value.partyMethod]);

  const handlePartyMethod = (val: string) => {
    const isBuyerSeller = val !== 'lender';
    let defaultValue: any = {};
    if (val === 'lender' && (value.partyMethod === 'buyer' || value.partyMethod === 'seller')) {
      defaultValue = {
        partyMethod: val,
        loanAmount: value.loanAmount ?? 0,
        expand: value.expand,
        ...(value.lenderId ? { lenderId: value.lenderId } : {}),
        ...(value.partyId ? { partyId: value.partyId } : {}),
        lenderClauseType: mortgageClause[0].name,
        lenderClauseText: MortgageClauseTextOptions[mortgageClause[0].name].value,
      };
    } else if ((val === 'buyer' || val === 'seller') && value.partyMethod === 'lender') {
      defaultValue = {
        partyMethod: val,
        expand: value.expand,
        address: { isForeignAddress: false },
        partyType: value.partyType && value.partyType !== 'Lender' ? value.partyType : 'Individual',
        ...(value.lenderId ? { lenderId: value.lenderId } : {}),
        ...(value.partyId ? { partyId: value.partyId } : {}),
      };
    } else {
      defaultValue = {
        ...value,
        partyMethod: val,
        ...(isBuyerSeller
          ? {
              partyType: value.partyType && value.partyType !== 'Lender' ? value.partyType : 'Individual',
              address: { ...value.address, isForeignAddress: value?.address?.isForeignAddress ?? false },
            }
          : {
              loanAmount: value.loanAmount ?? 0,
              lenderClauseType: mortgageClause[0].name,
              lenderClauseText: MortgageClauseTextOptions[mortgageClause[0].name].value,
            }),
      };
    }

    if ((val === 'buyer' && value.partyMethod === 'seller') || (val === 'seller' && value.partyMethod === 'buyer')) {
      if (!value.uuid) {
        defaultValue = { ...defaultValue, uuid: value.partyId };
        delete defaultValue.partyId;
      } else {
        const partyId = savedParties.filter((item: any) => item.partyId === value.uuid)?.[0]?.partyId;
        if (partyId) {
          defaultValue = { ...defaultValue, partyId };
          delete defaultValue.uuid;
        }
      }
    }
    if (!isBuyerSeller && ((fileInfo?.lenders && fileInfo?.lenders.length === 0) || fileInfo?.lenders === undefined)) {
      defaultValue = { ...defaultValue, loanAmount: fileInfo?.loanAmount ?? 0 };
    }

    updateParty(defaultValue, currentIndex);
    onChangeWithValidation(defaultValue);
  };

  const updateParties = (index: number, data: any) => {
    const update = _.isEqual(value, data);
    if (!update) {
      updateCurrentParties((prevParties: any) => {
        const tempParty = [...prevParties];
        data.expand = tempParty[index].expand;
        tempParty[index] = data;
        onChanges(tempParty);
        return tempParty;
      });
    }
  };
  const onBuyerSellerChange = useCallback(
    (data: any) => {
      updateParties(currentIndex, data);
    },
    [currentIndex, value],
  );

  const onLenderChange = React.useCallback(
    (data: any) => {
      if (userFirm?.firmId) {
        const currentData = {
          ...data,
          firmId: !isNaN(userFirm.firmId as unknown as number) ? parseInt(userFirm.firmId) : userFirm.firmId,
        };
        updateParties(currentIndex, currentData);
      }
    },
    [currentIndex, value],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <AgentNetDropdownSelector
            name="partyMethod"
            label="Party"
            data-testid={`FileInfoParty${(currentIndex ?? 0) + 1}Party`}
            required
            defaultValue=""
            options={PartyOptions}
            value={value.partyMethod}
            menuOption={handlePartyMethod}
            dropdowntype="outlined"
            errs={validationErrors}
            showValidation={showAllValidation}
            disabled={fileInfo?.status !== 'Open' || fileInfo.isReopenedByProduct}
          />
        </Grid>
        <Grid item sm={6}></Grid>
        {value.partyMethod && (
          <Grid item sm={12}>
            {value.partyMethod !== 'lender' ? (
              <AddPartyForm
                value={value}
                onChange={onBuyerSellerChange}
                setIsValid={handleSetFormValid}
                showValidation={showAllValidation}
                qaAttrPrefix={`FileInfoParty${(currentIndex ?? 0) + 1}`}
              />
            ) : (
              <AddLenderFormWithBaseFormGroup
                qaAttrPrefix={`FileInfoParty${(currentIndex ?? 0) + 1}`}
                value={value ?? ({} as IGetLenderResponse)}
                onChange={onLenderChange}
                schema={lenderSchema}
                lenderList={lenderList}
                getLenderStatus={getLenderStatus}
                lenderFirmDetails={lenderFirmList}
                setIsFormValid={handleSetFormValid}
                showAllValidation={showAllValidation}
                editMode={false}
                myLendersRequired={true}
                displayDefaultLenderOption={false}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
const PartiesForm = React.memo(Form);
export default PartiesForm;
