import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
    },
    hidden: {
      display: 'none',
    },
  }),
);

type TabPanelProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
  index: number;
  value: number;
};

export function TabPanel({ children, value, index, ...props }: TabPanelProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.hidden]: value !== index })}
      role="tabpanel"
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...props}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
