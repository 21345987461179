import { Grid, GridProps, GridSize } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PhoneField from 'ui-kit/inputs/PhoneField/PhoneField';
import '../../../ui-kit/form/PersonalIdForm.scss';
import SsnField from '../../../ui-kit/inputs/SsnField';
import { AgentNetTextInput } from '../../../ui-kit/inputs/TextField';
import { buyerAndSellerSchema } from '../../../utilities/validation/schemas';
import { doValidate, FieldValidationError } from '../../../utilities/validation/validation';
import '../file-information/FileInformation.scss';
import { IPersonalIdentificationFields } from '../parties/interfaces/PersonalInformation';

export interface PersonalIdFormProps {
  value: IPersonalIdentificationFields;
  onChange: (personalIdentificationFields: IPersonalIdentificationFields) => void;
  ContainerComponentProps?: GridProps;
  isCreateFile: boolean;
  isForeignAddress?: boolean;
  showAllValidation?: boolean;
  setIsValid?: (isValid: boolean) => void;
  resetVisibility?: boolean;
  qaAttrPrefix?: string;
  disabled?: boolean;
  setUpdateValidations?: any;
  updateValidations?: any;
  showSSN?: boolean;
}

const PersonalIdForm: React.FC<PersonalIdFormProps> = ({
  value: personalInformation,
  onChange,
  isCreateFile,
  showAllValidation,
  isForeignAddress,
  setIsValid,
  resetVisibility = false,
  qaAttrPrefix = '',
  disabled = false,
  updateValidations = false,
  setUpdateValidations,
  showSSN = true,
}: PersonalIdFormProps) => {
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [initialValidationComplete, setInitialValidationComplete] = useState<boolean>(false);
  const validateErrors = async (personalIdentificationFields: IPersonalIdentificationFields) => {
    const errs = (await doValidate(personalIdentificationFields, buyerAndSellerSchema)) || [];
    setValidationErrors(errs);
    setIsValid && setIsValid(errs.length === 0);
  };
  const onChangeWithValidation = async (personalIdentificationFields: IPersonalIdentificationFields) => {
    validateErrors(personalIdentificationFields);
    onChange(personalIdentificationFields);
  };

  useEffect(() => {
    if (showAllValidation) onChangeWithValidation({ ...personalInformation });
  }, [showAllValidation]);

  useEffect(() => {
    if (!isCreateFile && updateValidations) {
      onChangeWithValidation({ ...personalInformation });
      setUpdateValidations(false);
    }
  }, [isCreateFile, updateValidations, personalInformation]);

  useEffect(() => {
    if (!initialValidationComplete) {
      validateErrors({ ...personalInformation });
      setInitialValidationComplete(true);
    }
  }, []);
  const configurePersonalLayout = (arr: GridSize[]) => {
    return (
      <>
        <Grid item xs={arr[0]}>
          <AgentNetTextInput
            disabled={disabled}
            fullWidth
            variant="outlined"
            required
            label={'First Name'}
            name="firstName"
            value={personalInformation?.firstName ?? ''}
            errs={validationErrors}
            showValidation={showAllValidation}
            onChange={(e) => {
              onChangeWithValidation({ ...personalInformation, firstName: e.target.value });
            }}
            {...(qaAttrPrefix ? { 'data-testid': qaAttrPrefix + 'FirstName' } : {})}
          />
        </Grid>
        <Grid item xs={arr[1]}>
          <AgentNetTextInput
            disabled={disabled}
            fullWidth
            variant="outlined"
            name="middleName"
            label={'Middle Name'}
            errs={validationErrors}
            showValidation={showAllValidation}
            value={personalInformation?.middleName ?? ''}
            onChange={(e) => {
              onChangeWithValidation({ ...personalInformation, middleName: e.target.value });
            }}
            {...(qaAttrPrefix ? { 'data-testid': qaAttrPrefix + 'MiddleName' } : {})}
          />
        </Grid>
        <Grid item xs={arr[2]}>
          <AgentNetTextInput
            disabled={disabled}
            fullWidth
            variant="outlined"
            required
            name="lastName"
            value={personalInformation?.lastName ?? ''}
            errs={validationErrors}
            showValidation={showAllValidation}
            label={'Last Name'}
            onChange={(e) => {
              onChangeWithValidation({ ...personalInformation, lastName: e.target.value });
            }}
            {...(qaAttrPrefix ? { 'data-testid': qaAttrPrefix + 'LastName' } : {})}
          />
        </Grid>
        <Grid item xs={arr[3]}>
          <AgentNetTextInput
            disabled={disabled}
            fullWidth
            variant="outlined"
            label={'Suffix'}
            name="suffix"
            errs={validationErrors}
            showValidation={showAllValidation}
            value={personalInformation?.suffix ?? ''}
            onChange={(e) => {
              onChangeWithValidation({ ...personalInformation, suffix: e.target.value });
            }}
            {...(qaAttrPrefix ? { 'data-testid': qaAttrPrefix + 'Suffix' } : {})}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={3}>
      {isCreateFile ? configurePersonalLayout([3, 3, 3, 3]) : configurePersonalLayout([3, 3, 3, 3])}
      {!isCreateFile && (
        <>
          <Grid item xs={6}>
            <AgentNetTextInput
              disabled={disabled}
              variant="outlined"
              fullWidth
              name="emailAddress"
              showValidation={showAllValidation}
              errs={validationErrors}
              label={'Email'}
              value={personalInformation?.emailAddress?.trim() ?? ''}
              onChange={(e) => {
                onChangeWithValidation({ ...personalInformation, emailAddress: e.target.value.trim() ?? '' });
              }}
              {...(qaAttrPrefix ? { 'data-testid': `${qaAttrPrefix}Email` } : {})}
            />
          </Grid>
          <Grid item xs={isForeignAddress ? 6 : 3}>
            <PhoneField
              disabled={disabled}
              variant="outlined"
              name={'phoneNumber'}
              label={'Phone Number'}
              value={personalInformation?.phoneNumber ?? ''}
              showValidation={showAllValidation}
              errs={validationErrors}
              fullWidth
              onChange={(e: any) => {
                onChangeWithValidation({ ...personalInformation, phoneNumber: e?.target?.value });
              }}
              {...(qaAttrPrefix ? { 'data-testid': `${qaAttrPrefix}PhoneNumber` } : {})}
            />
          </Grid>
          {showSSN && (
            <Grid item xs={3} className={isForeignAddress ? 'hide' : 'ssn-container'}>
              <SsnField
                disabled={disabled}
                label="SSN"
                {...(qaAttrPrefix ? { 'data-testid': qaAttrPrefix + 'SSN' } : {})}
                resetVisibility={resetVisibility}
                id="ssn-input"
                value={personalInformation?.ssn ?? '___-__-____'}
                onChange={(e) => {
                  const currentValue = e.target.value === '___-__-____' ? '' : e.target.value;

                  initialValidationComplete &&
                    onChangeWithValidation({
                      ...personalInformation,
                      ssn:
                        personalInformation?.ssn === '***-**-****' && currentValue === ''
                          ? personalInformation?.ssn
                          : currentValue,
                    });
                }}
                showAllValidation={showAllValidation}
                validationErrors={validationErrors}
                fullWidth
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default PersonalIdForm;
