import { getAgencyApiHost, getApiHost } from 'api/host-by-env';
import { GetSignature } from 'features/admin/e-signatures/types';
import { handleError } from 'utilities/utilities';

export async function getSignatureList(firmId: string | undefined, token: string): Promise<GetSignature[]> {
  const url = `${getAgencyApiHost()}firm-service/v1/signatories?firmId=${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function postSignature(signatureRequest: any, token: string): Promise<any> {
  const url = `${getAgencyApiHost()}firm-service/v1/signatories/signature`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(signatureRequest),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  handleError(json);

  let apiResponse;
  if (json['result'] !== undefined) {
    apiResponse = json.result;
  } else if (json['Result'] !== undefined) {
    apiResponse = json.Result;
  }

  return apiResponse;
}

export async function putSignature(signatureRequest: any, token: string): Promise<any> {
  const url = `${getAgencyApiHost()}firm-service/v1/signatories/signature`;

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(signatureRequest),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  handleError(json);

  let apiResponse;
  if (json['result'] !== undefined) {
    apiResponse = json.result;
  } else if (json['Result'] !== undefined) {
    apiResponse = json.Result;
  }

  return apiResponse;
}

export async function deleteEsignatureApi(signaturedata: any, token: string): Promise<any> {
  const url = `${getApiHost()}firms/signature/${signaturedata.firmId}/${signaturedata.firmESignatureId}`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);

  let apiResponse;
  if (json['result'] !== undefined) {
    apiResponse = json.result;
  } else if (json['Result'] !== undefined) {
    apiResponse = json.Result;
  }

  return apiResponse;
}
