import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { coveredParty, CPLStatusType } from 'features/cpl/types';
import React from 'react';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { AgentNetCheckBox } from 'ui-kit/inputs/Checkbox';

const useStyles = makeStyles((theme) => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

interface CoveredPartiesProps {
  index?: number;
  coveredParties: coveredParty[];
  handleCoveredParties: (party: coveredParty, bool: boolean) => void;
  disabled?: boolean;
  status?: CPLStatusType;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  remainingCPLs: number;
  setRemainingCPLs: (count: any) => void;
  hideSubtitle?: boolean;
}
const CoveredParties: React.FC<CoveredPartiesProps> = ({
  index,
  coveredParties,
  handleCoveredParties,
  disabled = false,
  status = '',
  isStarsStatusActive,
  noStarsStatusActive,
  remainingCPLs,
  setRemainingCPLs,
  hideSubtitle,
}: CoveredPartiesProps) => {
  const i = index ? index : '';
  const qaAttrMap = new Map<string, string>([
    ['Lender', `CPL${i}CoveredParty-Lender`],
    ['Lender / Borrower(s)', `CPL${i}CoveredParty-Lender/Borrower`],
    ['Borrower(s) / Buyer(s)', `CPL${i}CoveredParty-Borrower/Buyer`],
    ['Borrower(s)', `CPL${i}CoveredParty-Borrower`],
    ['Buyer(s)', `CPL${i}CoveredParty-Buyer`],
    ['Seller(s)', `CPL${i}CoveredParty-Seller`],
    ['', 'PARTY NAME MISMATCH - See CoveredParties component'],
  ]);
  const classes = useStyles();
  const completeDisable = (disabled && status === 'Void') || isStarsStatusActive || noStarsStatusActive;

  return (
    <>
      <Box my={3}>
        <AgentNetDivider
          typoVariant="h2"
          title="Covered Parties"
          subtitle={!hideSubtitle && 'Please select up to 3 covered parties via the checkboxes below.'}
          subtitleMuted
          titleAsterisk
          disablePadding
          customClass={classes.mb2}
        />
      </Box>
      <Grid container>
        <Grid item sm={12} alignItems="center">
          <Box mt={3}>
            {coveredParties.map((coveredParty) => (
              <AgentNetCheckBox
                key={coveredParty.coveredPartyName}
                label={coveredParty.coveredPartyName}
                data-testid={qaAttrMap.get(coveredParty.coveredPartyName || '')?.replace(/\s/g, '')}
                value={coveredParty.checked ?? false}
                disabled={
                  completeDisable ||
                  (remainingCPLs <= 0 && !coveredParty?.checked) ||
                  (disabled && !coveredParty.checked)
                }
                checkHandler={(val: boolean) => {
                  if (disabled) return;
                  if (val) {
                    setRemainingCPLs((prevCount: number) => prevCount - 1);
                  } else {
                    setRemainingCPLs((prevCount: number) => prevCount + 1);
                  }
                  handleCoveredParties(coveredParty, val);
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CoveredParties;
