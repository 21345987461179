// TODO: Refactor file into smaller components. Will complete with - https://dev.azure.com/FirstAmCorp/AgentNetPortal/_workitems/edit/2268573/
import { useAuth } from '@agentnet/auth';
import { AppBar, AppHeader, BaseLink, CategoryType, NavigationLink, TopNav } from '@fluentsms/agentnet-web-components';
import { AgentNetServices, Comprehensive, Discownts, Knowledge } from '@fluentsms/agentnet-web-icons';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getAgentNetKnowledgeUrl, getAnteUrl, isEnableAllFeatures } from 'api/host-by-env';
import { getFirmDetails } from 'api/onBehalfOf/api';
import { getDefaultFirm } from 'api/profile/profile-utilities';
import { Firm } from 'api/profile/types';
import { AgentNetTitleEscrow, AgentPrintPro, SoftwareIntegrationHub } from 'core/navbar/AppSwitcherIcons';
import FileCreateContainer from 'features/files/file-create/FileCreateContainer';
import OrderCreateContainer from 'features/orderManagement/OrderCreateContainer';
import { FileCreateProvider } from 'hooks/FileCreateContext';
import { OrderCreateProvider } from 'hooks/OrderCreateContext';
import { useUser } from 'hooks/SignUpContext';
import useAsync from 'hooks/useAsync';
import { MouseEvent, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isProductFruitsReady } from 'react-product-fruits';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import AboutAppDialog from 'ui-kit/components/modal/AboutAppDialog';
import ContactUsDialog from 'ui-kit/components/modal/ContactUsDialog';
import { FileDataContext, FileDataContextInterface } from '../../hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from '../../hooks/ProfileContext';
import { AccountMenu } from './AccountMenu/AccountMenu';
import { FirmSwitcher } from './FirmSwitcher/FirmSwitcher';

interface GlobalHeaderProps {
  onViewSwitch?: () => void;
  currentView?: string;
  onPortalSwitch?: () => void;
  hideNav?: boolean;
  hasPermission?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      flexShrink: 0,
      position: 'fixed',
      width: '100%',
      zIndex: 15,
    },
    signInBtn: {
      marginLeft: theme.spacing(4),
    },
    sectionDesktop: {
      display: 'flex',
    },
    accountButton: {
      alignItems: 'center',
      padding: 0,
      height: '64px',
      display: 'flex',
      minWidth: 'auto',
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
      },
    },
    accountButtonTxt: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '40px',
      border: `1px solid rgba(255, 255, 255, 0.23)`,
      textAlign: 'center',
      '& span': {
        marginTop: '4px',
      },
    },
    createNewFileBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const GlobalHeader = ({ onViewSwitch, currentView, hideNav, hasPermission }: GlobalHeaderProps): JSX.Element => {
  const classes = useStyles();
  const { signOut, getAccessToken, account, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, setUserFirm, profile, latestProfile, pfNotifications, setPfNotifications, reloadProfile } =
    profileCtx;
  const params: { firmId?: string; action?: string } = useParams();
  const { setUserId, setPreviousPage, previousPage, setLoginUserId } = useUser();

  const {
    setOpenSaveModal,
    isFormDirty,
    firmAnchorEl,
    setFirmAnchorEl,
    setIsCreateDrawerClicked,
    openCreateDrawer,
    setOpenCreateDrawer,
  }: FileDataContextInterface = useContext(FileDataContext) ?? {};

  const isFirmMenuOpen = Boolean(firmAnchorEl);

  const defaultFirm = getDefaultFirm(profile?.firms);
  const [firm, setFirm] = useState<{ name: string; value: string }>(
    userFirm?.firmId
      ? { name: userFirm.name, value: userFirm.firmId }
      : { name: defaultFirm?.name ?? '', value: defaultFirm?.firmId ?? '' },
  );
  const claims = account?.idTokenClaims as any;
  const isEmployee = claims?.onpremADusername != null;
  const agentNetKnowledgeUrl = isEmployee ? getAgentNetKnowledgeUrl() + '?type=b2e' : getAgentNetKnowledgeUrl();
  const agentPrintProUrl = isEmployee
    ? 'https://agentprintpro.com/sso/initiate/fc550000-5dcb-0015-d363-08d864b6da16'
    : 'https://agency.myfirstam.com/Authentication/SignIn?_ref=8oj%2fhK0Qn4svaymJ43DtytcrjRgSiU%2f2ZXglgmp%2flT0%3d';
  const anteUrl = `${getAnteUrl()}TitleEscrow//FileService/FileService.html?v1=${userFirm?.firmId}`;

  const navigationLinks: NavigationLink[] = [
    {
      category: CategoryType.OWNED,
      description: 'Forms, Bulletins, Education and UW Guidance',
      icon: <Knowledge height={32} width={32} />,
      text: 'AgentNet Knowledge',
      url: agentNetKnowledgeUrl,
    },
    ...(userFirm?.isTitleEscrow
      ? [
          {
            category: CategoryType.OWNED,
            description: 'First American Title & Escrow Production Solution',
            icon: <AgentNetTitleEscrow />,
            text: 'AgentNet Production',
            url: anteUrl,
          },
        ]
      : []),
    {
      category: CategoryType.OTHER,
      description: 'Special Offers From Our Favorite Suppliers',
      icon: <Discownts height={32} width={32} />,
      text: 'discownts.com®',
      url: 'https://agency.myfirstam.com/Authentication/SignIn?_ref=aAIeiC4aziWCMzFaBiMc6fYBQp%2b3wjYWvKgsM6SB%2frU%3d',
    },
    {
      category: CategoryType.OTHER,
      description: 'Title Rates & Recording Fee Estimates',
      icon: <Comprehensive height={32} width={32} />,
      text: 'First American Comprehensive Calculator',
      url: 'https://agency.facc.firstam.com/',
    },
    {
      category: CategoryType.OTHER,
      description: 'High-Quality Content to Build Your Brand',
      icon: <AgentPrintPro />,
      text: 'Marketing & Print Services',
      url: agentPrintProUrl,
    },
    {
      category: CategoryType.OTHER,
      description: 'Master AgentNet® Integrations with Ease',
      icon: <SoftwareIntegrationHub />,
      text: 'Software Integration Hub',
      url: 'https://agency.myfirstam.com/ApplicationTabGUI/IntegrationTraining.html',
    },
  ];

  const { isStaffAdmin, isFSC } = useMemo(() => {
    let isStaffAdminUser = false;
    let isFSCUser = false;
    if (profile) {
      isStaffAdminUser = profile?.roleId === 13;
      isFSCUser = profile?.roleId === 14;
    }
    return {
      isStaffAdmin: isStaffAdminUser,
      isFSC: isFSCUser,
    };
  }, [profile]);

  useEffect(() => {
    if (profile) {
      if (
        profile?.roleTypeId === 100 &&
        profile?.eulaAcceptedDate == null &&
        !(previousPage && previousPage == '/authorization/eula')
      ) {
        setUserId(profile?.userId?.toString() ?? null);
        setLoginUserId(profile?.login?.toString() ?? null);
        setPreviousPage('/files');
        history.push('/authorization/eula');
      }
    }
  }, [profile]);

  useEffect(() => {
    if (profile && previousPage && previousPage == '/authorization/eula') {
      reloadProfile && reloadProfile();
      setPreviousPage(null);
    }
  }, []);

  const previousFirm = useRef(firm).current;
  const history = useHistory();
  const location = useLocation();
  const isOrderManagementPage = location.pathname === '/order-management';

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  let name = latestProfile?.FirstName ? latestProfile?.FirstName + ' ' : '';
  name += latestProfile?.LastName ? latestProfile?.LastName : '';

  const email = latestProfile?.EmailAddress ? latestProfile?.EmailAddress : '';

  function nameTag() {
    const fname = latestProfile?.FirstName ? latestProfile.FirstName[0].toUpperCase() : '';
    const lname = latestProfile?.LastName ? latestProfile.LastName[0].toUpperCase() : '';
    return `${fname}${lname}`;
  }

  useEffect(() => {
    setFirmAnchorEl(null);
  }, [location.pathname]);

  function outsideClickPopper(ref: RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFirmAnchorEl(null);
          if (firm.value === '') {
            setFirm(previousFirm);
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const popperRef = useRef(null);
  outsideClickPopper(popperRef);

  const [aboutModal, setAboutModal] = useState<boolean>(false);
  const [contactUsModal, setContactUsModal] = useState<boolean>(false);

  useEffect(() => {
    window.productFruitsReady = () => {
      window.productFruits?.api?.announcementsV2.listen('newsfeed-unread-count-changed', (data: any) => {
        setPfNotifications && setPfNotifications(data.count);
      });
      attachNewsFeed();
    };
    attachNewsFeed();
  });

  const attachNewsFeed = () => {
    if (isProductFruitsReady()) {
      const customLauncher = document.getElementById('notificationIcon');
      if (customLauncher) {
        window.productFruits?.api?.announcementsV2.attachNewsWidgetToElement(customLauncher);
      }
    }
  };

  const handleFirmChange = (newValue: string | undefined, logout = false, firmDetailsResults?: any) => {
    let matchedFirmData;

    if (firmDetailsResults?.firmId === newValue && (profile?.roleId === 3 || profile?.roleId === 5)) {
      matchedFirmData = firmDetailsResults;
    } else {
      matchedFirmData = profile?.firms?.find((firm: Firm) => String(firm.firmId) === String(newValue));
    }
    if (matchedFirmData) {
      setFirm({ name: matchedFirmData.name, value: matchedFirmData.firmId });
      window.localStorage.setItem('userFirm', JSON.stringify(matchedFirmData));
      setUserFirm && setUserFirm(matchedFirmData);
      setFirmAnchorEl(null);
      if (location.pathname.indexOf('/files') > -1) history.push('/files');
      if (location.pathname.includes('/invoice-summary') || params?.action === 'pay')
        history.push('/accounting/remittance/pay');
      if (location.pathname.includes('/accounting/service-order-invoices/view-invoices')) {
        history.push('/accounting/service-order-invoices/view-invoices');
      }
    } else if (logout) {
      signOut().then();
      sessionStorage.clear();
      localStorage.clear();
    }
  };

  const getFirmDetail = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmDetails(token, params?.firmId ?? '');
  };

  const {
    execute: executeFirmDetails,
    status: executeFirmDetailsStatus,
    value: firmDetailsResults,
  } = useAsync(getFirmDetail, false);

  useEffect(() => {
    if (params?.firmId && params?.firmId !== 'invoice-summary') {
      if (profile?.roleId === 3 || profile?.roleId === 5) {
        executeFirmDetails().then();
      } else if (profile?.firms) {
        handleFirmChange(params?.firmId, true);
      }
    }
  }, [params?.firmId, profile?.firms]);

  useEffect(() => {
    if (firmDetailsResults) {
      handleFirmChange(params?.firmId, true, firmDetailsResults);
    }
  }, [firmDetailsResults]);

  useEffect(() => {
    if (executeFirmDetailsStatus === 'error') {
      handleFirmChange('', true);
    }
  }, [executeFirmDetailsStatus]);

  const isDashboardEnabled = isEnableAllFeatures();

  const getAccountingUrl = (): string => {
    if (hasPermission?.orderManagement) return '/accounting/service-order-invoices/view-invoices';
    if (hasPermission?.remit) return '/accounting/remittance/report';
    if (hasPermission?.pay) return '/accounting/remittance/pay';

    return '/accounting/service-order-invoices/view-invoices';
  };

  const getServicesUrl = (): string => {
    if (hasPermission?.orderManagement) return '/services/sdn-search';
    if (hasPermission?.backtitle) return '/services/back-title-search';
    if (hasPermission?.canRunPolicyUpload) return '/services/policy-upload/upload';

    return '/reporting';
  };

  const filesUrl = hasPermission?.orderManagement ? '/searchFirms' : '/files';
  const orderManagementUrl = '/order-management';
  const accountingUrl = getAccountingUrl();
  const servicesUrl = getServicesUrl();
  const dashboardUrl = '/dashboards/service-orders';
  const reportsUrl = '/reporting';
  const adminUrl = '/admin/user/profile';

  const topNonOrderManagementLinks: BaseLink[] = [
    ...(hasPermission?.filesList ? [{ text: 'Files', testId: 'TopNavFiles', url: filesUrl }] : []),
    { text: 'Accounting', testId: 'TopNavAccounting', url: accountingUrl },
    { text: 'Services', testId: 'TopNavServices', url: servicesUrl },
    ...(isDashboardEnabled ? [{ text: 'Dashboards', testId: 'TopNavDashboards', url: dashboardUrl }] : []),
    { text: 'Reports', testId: 'TopNavReports', url: reportsUrl },
    { text: 'Admin', testId: 'TopNavAdmin', url: adminUrl },
  ];

  const topOrderManagementLinks: BaseLink[] = [
    { text: 'Files', testId: 'TopNavFiles', url: filesUrl },
    { text: 'Order Management', testId: 'TopNavOrderManagement', url: orderManagementUrl },
    ...(hasPermission?.hasFirmId && !location.pathname.includes(orderManagementUrl)
      ? [
          { text: 'Accounting', testId: 'TopNavAccounting', url: accountingUrl },
          { text: 'Services', testId: 'TopNavServices', url: servicesUrl },
          ...(isDashboardEnabled ? [{ text: 'Dashboards', testId: 'TopNavDashboards', url: dashboardUrl }] : []),
          { text: 'Reports', testId: 'TopNavReports', url: reportsUrl },
          { text: 'Admin', testId: 'TopNavAdmin', url: adminUrl },
        ]
      : []),
  ];

  const getTopNavLinks = (): BaseLink[] => {
    if (!hideNav && !isStaffAdmin && !hasPermission?.orderManagement) {
      return topNonOrderManagementLinks;
    }
    if (isFSC) {
      return topOrderManagementLinks;
    }
    return [];
  };

  const getButtonDataQa = (): string => {
    if (!hideNav && !isStaffAdmin && !hasPermission?.orderManagement) {
      return 'GlobalNavCreateNewFile';
    }
    if (isFSC) {
      return 'GlobalNavCreateNewOrder';
    }
    return '';
  };

  const getButtonText = (): string => {
    if (isFSC && isOrderManagementPage) {
      return 'Create New Order';
    }
    return 'Create New File';
  };

  const shouldRenderTopNav = (): boolean => {
    return (!hideNav && !isStaffAdmin) || isFSC;
  };

  const noBehalfOfUser = profile?.roleId === 2 || profile?.roleId === 13;

  const [openCreateFileDrawer, setOpenCreateFileDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (openCreateDrawer) {
      // based on file context open the create drawer
      setOpenCreateFileDrawer(true);
      setOpenCreateDrawer?.(false);
    }
  }, [openCreateDrawer]);

  const isOrderManagementDashboard = location.pathname === orderManagementUrl && !location.state;

  return (
    <>
      {location.pathname.includes('/Authentication/ForgotPassword') ||
      location.pathname.includes('/Authentication/ForgotUserID') ? null : (
        // TODO: Refactor app css to remove flex on container
        <div className={classes.headerWrapper}>
          <AppHeader
            links={navigationLinks}
            appBar={
              <AppBar
                logo={
                  <Link to="/">
                    <AgentNetServices width={149} height={36} />
                  </Link>
                }
                componentRight={
                  <div className={classes.sectionDesktop}>
                    {currentView === 'public' && (
                      <>
                        {window.location.pathname === '/account-registration' ? (
                          <AgentNetButton
                            className="white-text"
                            onClick={() => {
                              window.location.href = '/contact-us';
                            }}
                          >
                            Contact Us
                          </AgentNetButton>
                        ) : (
                          <AgentNetButton
                            className="white-text"
                            onClick={() => {
                              window.location.href = '/account-registration';
                            }}
                          >
                            Request Access
                          </AgentNetButton>
                        )}
                        <AgentNetButton
                          className={classes.signInBtn}
                          color="primary"
                          size="medium"
                          variant="contained"
                          onClick={() => {
                            logout().then();
                          }}
                        >
                          Sign In
                        </AgentNetButton>
                      </>
                    )}
                    {currentView !== 'public' && !isOrderManagementDashboard && (
                      <FirmSwitcher
                        firm={firm}
                        firmAnchorEl={firmAnchorEl}
                        isFirmMenuOpen={isFirmMenuOpen}
                        pfNotifications={pfNotifications}
                        popperRef={popperRef}
                        profile={profile}
                        setFirm={setFirm}
                        setFirmAnchorEl={setFirmAnchorEl}
                        userFirm={userFirm}
                        handleFirmChange={handleFirmChange}
                        handleMenuClose={handleMenuClose}
                      />
                    )}

                    {currentView !== 'public' && (
                      <Button
                        aria-label="account of current user"
                        aria-controls={'account-menu'}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        variant="text"
                        size="large"
                        className={classes.accountButton}
                        data-testid="HeaderUserAccount"
                      >
                        <div className={classes.accountButtonTxt}>
                          <Typography variant="h2">{name ? nameTag() : 'U'} </Typography>
                        </div>
                      </Button>
                    )}
                  </div>
                }
              />
            }
          />

          <AccountMenu
            anchorEl={anchorEl}
            email={email}
            isStaffAdmin={isStaffAdmin}
            name={name}
            profile={profile}
            userFirm={userFirm}
            handleMenuClose={handleMenuClose}
            onViewSwitch={onViewSwitch}
            setAboutModal={setAboutModal}
            setContactUsModal={setContactUsModal}
          />

          {shouldRenderTopNav() && (
            <TopNav
              links={getTopNavLinks()}
              componentRight={
                <>
                  {noBehalfOfUser || currentView === 'admin' ? null : (
                    <div className={classes.createNewFileBtn}>
                      <AgentNetButton
                        color="primary"
                        size="small"
                        variant="contained"
                        data-testid={getButtonDataQa()}
                        disabled={false}
                        onClick={() => {
                          // If the Edit File form has unsaved changes, open the modal
                          if (isFormDirty) {
                            setOpenSaveModal && setOpenSaveModal(true);
                            setIsCreateDrawerClicked && setIsCreateDrawerClicked(true);
                          }
                          // otherwise, just open the drawer
                          else {
                            setOpenCreateFileDrawer(true);
                            setFirmAnchorEl && setFirmAnchorEl(null);
                          }
                        }}
                        fullWidth
                        plusIcon
                      >
                        {getButtonText()}
                      </AgentNetButton>
                    </div>
                  )}
                </>
              }
            />
          )}
        </div>
      )}
      <AboutAppDialog open={aboutModal} onClose={() => setAboutModal(false)} />
      <ContactUsDialog open={contactUsModal} onClose={() => setContactUsModal(false)} />
      {isFSC && isOrderManagementPage ? (
        <OrderCreateProvider>
          <OrderCreateContainer {...{ openDrawer: openCreateFileDrawer, setOpenDrawer: setOpenCreateFileDrawer }} />
        </OrderCreateProvider>
      ) : (
        <FileCreateProvider>
          <FileCreateContainer {...{ openDrawer: openCreateFileDrawer, setOpenDrawer: setOpenCreateFileDrawer }} />
        </FileCreateProvider>
      )}
    </>
  );
};

export default GlobalHeader;
