import { useAuth } from '@agentnet/auth';
import { DataTable } from '@fluentsms/agentnet-web-components';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-enterprise';
import { getDocument } from 'api/myReports/report-api';
import clsx from 'clsx';
import { DocumentType, newWindow, WindowFeatures, xlsDocType } from 'features/constants';
import { StatusRenderer } from 'features/files/files-summary/files-list/filesListConfigs';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';
import { dateTooltipValue, openDocument } from 'utilities/utilities';
import { ReportDocumentRequest } from './types';

export interface MyReportsListInterface {
  getHistoryResults?: [];
}
const MyReportsList = ({ getHistoryResults }: MyReportsListInterface) => {
  const { getAccessToken } = useAuth();
  const [historyRowData, setHistoryRowData] = useState<any>([]);
  const [displayedRowCount, setDisplayedRowCount] = useState<number | undefined>(undefined);
  const [reportDocumentRequest, setReportDocumentRequest] = useState<ReportDocumentRequest>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { addGlobalMsg } = useGlobalMessages();
  const [searchFlag, setSearchFlag] = useState(false);
  const gridApiRef = useRef<GridApi | null>(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - 296px) !important`,
        fontFamily: theme.typography.fontFamily,

        '& .ag-center-cols-viewport': {
          minHeight: 220,
        },
      },
      docButton: {
        paddingBottom: 1,
      },
    }),
  );
  const classes = useStyles();

  const getDocumentData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getDocument(reportDocumentRequest?.reportQueueId ?? 0, token);
  };

  const {
    execute: executeGetDocument,
    status: getDocumentStatus,
    value: getDocumentResults,
    errors: getDocuumentErrors,
  } = useAsync<any>(getDocumentData, false);

  useEffect(() => {
    setDisplayError(getDocumentStatus === 'error');
  }, [getDocumentStatus]);

  useEffect(() => {
    const errorMessages = getDocuumentErrors?.length ? getDocuumentErrors : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (getHistoryResults) {
      getHistoryResults?.map((x: any) => {
        const date = ConvertDateFormat(x.CreatedDate);
        x.CreatedDate = date;
      });
      setHistoryRowData(getHistoryResults);
    }
  }, [getHistoryResults]);

  useEffect(() => {
    if (getDocumentStatus === 'success') {
      if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'PDF') {
        openDocument(getDocumentResults?.DocumentData, DocumentType, newWindow, WindowFeatures);
      } else if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'XLS') {
        openDocument(getDocumentResults?.DocumentData, xlsDocType, newWindow, WindowFeatures);
      }
      setSearchFlag(false);
    }
  }, [getDocumentStatus]);

  const onViewDocument = (reportQueueId: number) => {
    setReportDocumentRequest({ reportQueueId: reportQueueId });
  };

  const ConvertDateFormat = (dateToBeFormatted: string) => {
    const date_arr = dateToBeFormatted.split('T');
    const new_date_arr = date_arr[0].split('-');
    const date = new_date_arr[1] + '/' + new_date_arr[2] + '/' + new_date_arr[0];
    const time_arr = date_arr[1].split(':');
    const time = time_arr[0] + ':' + time_arr[1] + ':' + time_arr[2].split('.')[0];

    const formattedDate = date + ' ' + time + ' PT';
    return formattedDate;
  };

  useEffect(() => {
    if (reportDocumentRequest) executeGetDocument().then();
  }, [reportDocumentRequest]);

  useEffect(() => {
    if (gridApiRef.current) {
      if (displayedRowCount === 0) {
        gridApiRef.current.showNoRowsOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [displayedRowCount]);

  function generateIcon(fileType?: any) {
    if (fileType === 'PDF') {
      return <DocumentPDF />;
    }
    if (fileType === 'EXCEL') {
      return <DocumentXLS />;
    }
  }

  const getStartOfDay = (date: string) => {
    const newDate = moment(date, 'MM/DD/YYYY').toDate();
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const historyColumnsConfig: ColDef[] = [
    {
      headerName: 'Created Date',
      field: 'CreatedDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const cellDate = getStartOfDay(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
            return -1;
          }
          if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
            return 1;
          }
          return 0;
        },
        browserDatePicker: true,
        dateFormat: 'yyyy-mm-dd',
        inRangeInclusive: true,
      },
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (!value) return '';
        return moment(value, 'MM/DD/YYYY HH:mm:ss ZZ').format('MM/DD/YYYY');
      },
      tooltipValueGetter: dateTooltipValue,
    },
    {
      headerName: 'Report Name',
      field: 'ReportName',
      tooltipField: 'ReportName',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Report Description',
      field: 'ReportDescription',
      tooltipField: 'ReportDescription',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Status',
      field: 'QueueStatus',
      tooltipField: 'QueueStatus',
      cellRenderer: StatusRenderer,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
    },
    {
      field: '',
      maxWidth: 38,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      tooltipField: 'View Document',
      cellRenderer: (params: ICellRendererParams) => {
        {
          return (
            <div className={classes.docButton}>
              {params?.data?.QueueStatus == 'Processed' && (
                <AgentNetButton
                  onClick={() => onViewDocument(params?.data?.ReportQueueId)}
                  data-testid="ServiceOrderDocumentView"
                >
                  {generateIcon(params.data.ReportFormat)}
                </AgentNetButton>
              )}
            </div>
          );
        }
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: false,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const onFilterChanged = (): void => {
    setDisplayedRowCount(gridApiRef.current?.getDisplayedRowCount());
  };

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };

  return (
    <>
      <div className="paper-layout contained">
        <LoadingSpinner status={searchFlag ? 'pending' : 'success'} variant="linear" />
      </div>
      <div>
        <AgentNetDivider
          typoVariant="h1"
          title="Search Results"
          subtitle="Reports will be sent to your profile email address. Retained in the log for 24 hours. All dates and times are in Pacific Time."
          subtitleMuted
        />
      </div>
      <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
        <DataTable
          rowData={historyRowData}
          columnDefs={historyColumnsConfig}
          defaultColDef={defaultColDef}
          gridOptions={{ suppressRowClickSelection: true, domLayout: 'autoHeight' }}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
        />
      </div>
    </>
  );
};
export default MyReportsList;
