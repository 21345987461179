import { useAuth } from '@agentnet/auth';
import { PartyTypeValue } from 'api/file/constants';
import { getFileInfoProps } from 'api/file/file-api';
import { deleteLenderType, deletPartyType } from 'api/file/file-party-api';
import { SdnParty } from 'api/sdn/interfaces/sdn-search';
import { updateFileSdn } from 'api/sdn/sdn-api';
import useAsync from 'hooks/useAsync';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { SelectPartyType } from '../enums/PartyTypeCategory';
import { IGetBuyerSellerResponse } from '../interfaces/CreateBuyerSellerRequest';
import { IGetLenderResponse } from '../interfaces/CreateLenderRequest';
import { ILenderFirm } from '../interfaces/LenderModels';
import { displayPartyNameToCard } from '../PartyGroup/DisplayPartyCardRules';
import PartiesForm from './PartiesForm';

interface Props {
  currentIndex: number;
  value: any;
  onChanges: any;
  setFileInfoError: (val?: any) => void;
  setIsFormValid: any;
  keyValue: any;
  updateCurrentParties: any;
  handleDeleteSuccess: any;
  fileInfo: any;
  handleDeleteConfirmation: any;
  updateParty: any;
  showAllValidation: any;
  lenderList?: {
    name: string;
    value: string;
    createdDate: number;
  }[];
  getLenderStatus: string;
  lenderFirmList: ILenderFirm[];
  updateSavedParties: any;
  savedParties: any;
  showPartiesDeletionMsg: any;
  partiesRefs: any;
  openSdnPDF?: () => void;
  onShiftClickExpand?: () => void;
}
const Accordian: React.FC<any> = (props: Props) => {
  const { fileId } = useParams<getFileInfoProps>();
  const {
    value,
    lenderList,
    getLenderStatus,
    lenderFirmList,
    currentIndex,
    onChanges,
    setFileInfoError,
    showAllValidation,
    setIsFormValid,
    keyValue,
    updateCurrentParties,
    handleDeleteConfirmation,
    handleDeleteSuccess,
    fileInfo,
    updateParty,
    updateSavedParties,
    savedParties,
    showPartiesDeletionMsg,
    partiesRefs,
    openSdnPDF,
    onShiftClickExpand,
  } = props;

  const partyRef = useRef<HTMLDivElement>(null);

  const handleSetFormValid = useCallback(
    (valid) => {
      setIsFormValid((prev: any) => {
        const tempForm = { ...prev, [keyValue]: valid };
        return Object.fromEntries(Object.entries(tempForm).filter(([k, v]) => !v));
      });
    },
    [keyValue],
  );

  useEffect(() => {
    if (keyValue) partiesRefs.current[keyValue] = partyRef.current;
  }, [keyValue]);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const generateTitle = () => displayPartyNameToCard(value);

  const generateSubTitle = () => {
    const subtitle = [];
    const address = `${value?.address?.address1 ? `${value?.address?.address1},` : ''} ${
      value?.address?.address2 ? `${value?.address?.address2},` : ''
    } ${value?.address?.city ? `${value?.address?.city},` : ''} ${
      value?.address?.stateOrProvince ?? value?.address?.state ?? ''
    } ${value?.address?.postalCode ?? ''}`;

    if (value?.partyMethod === 'buyer') {
      subtitle.push('Buyer/Borrower');
    } else if (value?.partyMethod === 'seller') {
      subtitle.push('Seller/Owner');
    }

    if (value?.partyMethod !== 'lender' && value?.partyType) {
      subtitle.push(value?.partyType);
    }

    if (address) {
      subtitle.push(address);
    }

    if (value?.partyMethod === 'lender') {
      if (value?.loanAmount)
        subtitle.push(
          `Loan Amount: ${Number(value.loanAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`,
        );
      if (value?.loanNumber) subtitle.push(`Loan Number: ${value.loanNumber}`);
    }
    return subtitle;
  };

  const onExpandChange = (isExpand: boolean) => {
    updateCurrentParties((prevParties: any) => {
      const tempParties =
        prevParties?.map((partiesValue: any, partiesIndex: number) => {
          if (partiesIndex === currentIndex) {
            return { ...partiesValue, expand: isExpand };
          }
          return partiesValue;
        }) ?? [];
      return tempParties;
    });
  };

  const { getAccessToken } = useAuth();

  const addLenderSdnParties = (party: IGetLenderResponse[]): SdnParty[] => {
    const sdnParties: SdnParty[] = [];
    if (party === undefined) {
      return sdnParties;
    }
    party.forEach((element) => {
      element.emailAddress = element.emailAddress?.trim() ?? '';
      sdnParties.push({
        partyId: parseInt(element.lenderId ?? '0'),
        partyName: element.name,
      });
    });
    return sdnParties;
  };

  const addBuyerSellerSdnParties = (party: IGetBuyerSellerResponse[]): SdnParty[] => {
    const sdnParties: SdnParty[] = [];
    if (party == undefined) {
      return sdnParties;
    }
    party.forEach((element) => {
      switch (element.partyType) {
        case PartyTypeValue.individual:
          sdnParties.push({
            partyId: parseInt(element.partyId ?? '0'),
            partyName: [
              element?.individual?.firstName,
              element?.individual?.middleName,
              element?.individual?.lastName,
              element?.individual?.suffix,
            ]
              .filter((val) => val)
              .join(' '),
          });
          break;
        case PartyTypeValue.trust:
          sdnParties.push({
            partyId: parseInt(element.partyId ?? '0'),
            partyName: element?.trustEstate?.name ?? '',
          });
          break;
        case PartyTypeValue.entity:
          sdnParties.push({
            partyId: parseInt(element.partyId ?? '0'),
            partyName: element?.entity?.name ?? '',
          });
          break;
        case PartyTypeValue.married:
          sdnParties.push({
            partyId: parseInt(element.partyId ?? '0'),
            partyName: [
              element?.couple?.spouse1?.firstName,
              element?.couple?.spouse1?.middleName,
              element?.couple?.spouse1?.lastName,
              element?.couple?.spouse1?.suffix,
            ]
              .filter((val) => val)
              .join(' '),
          });
          sdnParties.push({
            partyId: parseInt(element.partyId ?? '0'),
            partyName: [
              element?.couple?.spouse2?.firstName,
              element?.couple?.spouse2?.middleName,
              element?.couple?.spouse2?.lastName,
              element?.couple?.spouse2?.suffix,
            ]
              .filter((val) => val)
              .join(' '),
          });
          break;
      }
    });
    return sdnParties;
  };

  const deleteLender = async (): Promise<IGetLenderResponse> => {
    const token = await getAccessToken();
    let sdnParties = [
      ...addBuyerSellerSdnParties(fileInfo?.buyers ?? []),
      ...addBuyerSellerSdnParties(fileInfo?.sellers ?? []),
      ...addLenderSdnParties(fileInfo?.lenders ?? []),
    ];

    sdnParties = sdnParties.filter((party) => {
      return party.partyId !== parseInt(value.partyId ?? '0');
    });

    await updateFileSdn(
      fileId as string,
      fileInfo?.fileNumber as string,
      fileInfo?.officeId as number,
      sdnParties,
      0,
      token,
    );

    return deleteLenderType(fileId ?? '', value.lenderId ?? '', token);
  };

  const deleteParty = async (): Promise<IGetBuyerSellerResponse> => {
    const token = await getAccessToken();
    let sdnParties = [
      ...addBuyerSellerSdnParties(fileInfo?.buyers ?? []),
      ...addBuyerSellerSdnParties(fileInfo?.sellers ?? []),
      ...addLenderSdnParties(fileInfo?.lenders ?? []),
    ];

    sdnParties = sdnParties.filter((party) => {
      return party.partyId !== parseInt(savedParties[currentIndex]?.partyId ?? '0');
    });

    await updateFileSdn(
      fileId as string,
      fileInfo?.fileNumber as string,
      fileInfo?.officeId as number,
      sdnParties,
      0,
      token,
    );
    return deletPartyType(
      fileId as string,
      savedParties[currentIndex]?.partyMethod as SelectPartyType,
      value.partyId ?? savedParties[currentIndex]?.partyId ?? '',
      token,
    );
  };

  const {
    execute: executeDeletePartytype,
    status: deletePartyTypeStatus,
    value: deletedBuyerSellerResponse,
    errors: deletedBuyerSellerErrors,
  } = useAsync<IGetBuyerSellerResponse>(deleteParty, false);

  const {
    execute: executeDeleteLender,
    status: deleteLenderStatus,
    value: deletedLender,
    errors: lendersErrors,
  } = useAsync<IGetLenderResponse>(deleteLender, false);

  useEffect(() => {
    if (
      Array.isArray(deletedBuyerSellerErrors) &&
      deletedBuyerSellerErrors.length > 0 &&
      deletePartyTypeStatus === 'error'
    ) {
      setFileInfoError([...deletedBuyerSellerErrors]);
      setIsSubmitted(false);
    }
  }, [deletedBuyerSellerErrors, deletePartyTypeStatus]);

  useEffect(() => {
    if (Array.isArray(lendersErrors) && lendersErrors.length > 0 && deleteLenderStatus === 'error') {
      setFileInfoError([...lendersErrors]);
      setIsSubmitted(false);
    }
  }, [lendersErrors, deleteLenderStatus]);

  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setOpenDeleteModal(false);
  };

  const getUpdatedParties = (arr: any, value: any) => {
    let tempPartyItem = _.cloneDeep(arr);
    if (value.lenderId) {
      tempPartyItem = tempPartyItem.filter((party: any) => party.lenderId !== value.lenderId);
    } else {
      const partyId = value.partyId ?? value.uuid;
      tempPartyItem = tempPartyItem.filter((party: any) => (party.partyId ?? party.uuid) !== partyId);
    }
    return tempPartyItem;
  };

  useEffect(() => {
    if (deletedBuyerSellerResponse || deletedLender) {
      const tempParty = handleDeleteSuccess(getUpdatedParties, value);
      const tempDefaultParty = getUpdatedParties(savedParties, value);
      updateSavedParties(tempDefaultParty);
      onChanges(_.cloneDeep(tempParty), _.cloneDeep(tempDefaultParty));
      handleCloseDialog();
      handleSetFormValid(true);
      showPartiesDeletionMsg();
    } else if (deletedBuyerSellerResponse !== null || deletedLender !== null) {
      setIsSubmitted(false);
    }
  }, [deletedBuyerSellerResponse, deletedLender]);

  return (
    <>
      <div ref={partyRef}>
        <AccordionContent
          id={keyValue ?? getRandomArbitrary(0, 1000)}
          accordianQAAttribute={`FileInfoPartySummary${(currentIndex ?? 0) + 1}`}
          deleteQAAttribute={`FileInfoParty${(currentIndex ?? 0) + 1}Delete`}
          title={generateTitle() ?? '-'}
          subtitle={generateSubTitle()}
          type={value.partyMethod ? value.partyMethod : 'unknown'}
          expanded={value.expand}
          icon={value.partyMethod === 'lender' ? 'Lender' : !value.partyType ? 'unknown' : value.partyType}
          sdnMatch={value?.isSdnMatched}
          openSdnPDF={openSdnPDF && openSdnPDF}
          sdnQAAttribute={`FileInfoSDNResult${(currentIndex ?? 0) + 1}`}
          onExpandChange={onExpandChange}
          onDelete={() => setOpenDeleteModal(true)}
          isDeleteDisabled={fileInfo?.status !== 'Open' || fileInfo.isReopenedByProduct}
          onShiftClickExpand={onShiftClickExpand}
          {...(currentIndex &&
            currentIndex > 0 && {
              className: 'newParty',
            })}
        >
          <PartiesForm
            value={value}
            currentIndex={currentIndex}
            showAllValidation={showAllValidation}
            updateParty={updateParty}
            lenderList={lenderList}
            getLenderStatus={getLenderStatus}
            lenderFirmList={lenderFirmList}
            handleSetFormValid={handleSetFormValid}
            fileInfo={fileInfo}
            updateCurrentParties={updateCurrentParties}
            onChanges={onChanges}
            savedParties={savedParties}
          />
        </AccordionContent>
      </div>

      <AgentNetConfirmationDialog
        onConfirm={() => {
          const isSameParties = savedParties.filter((item: any) => item.partyId === value.uuid)?.[0]?.partyId;
          const id = value.partyId ?? value.lenderId;

          handleDeleteConfirmation({
            id: isSameParties ? value.uuid : id,
            lenderId: value.lenderId,
            executeDeleteLender,
            setOpenDeleteModal,
            setIsSubmitted,
            handleSetFormValid,
            currentIndex,
            executeDeletePartytype,
          });
        }}
        disabled={isSubmitted}
        open={openDeleteModal}
        disableCrossIconClick={isSubmitted}
        onDismissAction={handleCloseDialog}
        dialogTitle="Delete Party"
        dialogBtnContent="Delete Party"
        qaAttrPrefix="ConfirmationParty"
        dialogText="Are you sure you want to delete this party? You will not be able to recover it."
      />
    </>
  );
};

const PartiesAccordian = React.memo(Accordian);
export default PartiesAccordian;
