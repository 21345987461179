import { useAuth } from '@agentnet/auth';
import { DataTable } from '@fluentsms/agentnet-web-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ColDef, FilterChangedEvent, ValueFormatterParams } from 'ag-grid-enterprise';
import { getAvlAgents } from 'api/Avl/avl-api';
import { AvlAgentData } from 'api/Avl/types';
import { KBLinks } from 'features/constants';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import StateSelectField from 'ui-kit/inputs/StateSelectField';
import LoadingSpinner from '../../../ui-kit/components/LoadingSpinner';
import AvlAction from './AvlAction';
import './AvlList.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  tableStyles: {
    marginTop: '0px !important',
    height: 'calc(100vh - 349px) !important',
    width: '100% !important',
  },
}));

const AvlList: React.FC = () => {
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const history = useHistory();
  const [rows, setRows] = React.useState<AvlAgentData[]>([]);
  const [propertyStates, setPropertyStates] = useState<{ name: string; value: string }[]>([]);
  const [propertyState, setPropertyState] = useState<string>('');
  const [isActionPending, setActionPendingFlag] = useState(false);
  const { getAccessToken } = useAuth();

  useEffect(() => {
    if (!userFirm?.firmId) {
      history.push('/err');
    }
  }, [userFirm]);

  //Get Avl agents
  const getAvlAgentData = async (): Promise<{ result: AvlAgentData[]; errorCode?: string | number }> => {
    const token = await getAccessToken();
    //await new Promise((resolve) => setTimeout(resolve, 5000));
    return await getAvlAgents(userFirm?.firmId, propertyState, token);
  };
  const {
    execute,
    status,
    value: agents,
  } = useAsync<{ result: AvlAgentData[]; errorCode?: string | number; message?: string }>(getAvlAgentData, false);

  useEffect(() => {
    if (userFirm?.offices?.length ?? 0 > 0) {
      const accounts = userFirm?.offices?.flatMap((office) =>
        office.accounts.map((account) => ({
          id: office.officeId,
          officeStatus: office.status,
          ...account,
        })),
      );
      const accountsInfo =
        accounts
          ?.filter((acc) => acc.status?.toLowerCase() === 'active' && acc.officeStatus?.toLowerCase() === 'active')
          .map((acc) => ({
            accountStatus: acc.status || null,
            accountStateCode: acc.stateCode,
          })) ?? [];

      const sortedPropertyStates: { name: string; value: string }[] = Array.from(
        new Set(accountsInfo?.map((a) => a.accountStateCode).filter((code) => code !== null)),
      )
        .sort()
        .map((code) => ({ name: code || '', value: code || '' }));

      if (sortedPropertyStates && sortedPropertyStates.length > 0) {
        setPropertyStates(sortedPropertyStates);
        setPropertyState(sortedPropertyStates[0].value);
      }
    }
  }, [userFirm]);

  useEffect(() => {
    if (propertyState) {
      execute().then();
    }
  }, [propertyState]);

  useEffect(() => {
    if (status === 'success') {
      const updatedAgents = agents?.result.map((agent) => ({
        ...agent,
        clientType: agent.clientTypeId === 1 || agent.clientTypeId === 6 ? 'Approved Attorney' : 'Agent',
      }));
      if (updatedAgents) {
        setRows(updatedAgents);
      }
      if (!agents?.result.length) gridRef.current?.api.showNoRowsOverlay();
      else gridRef.current?.api.hideOverlay();
    } else if (status === 'pending') {
      gridRef.current?.api.showLoadingOverlay();
    } else if (status === 'error') {
      gridRef.current?.api.showNoRowsOverlay();
    }
  }, [status, agents]);

  const gridRef = useRef<any>(null);
  const CustomOverlayLoading = () => {
    return (
      <div className="no-rows">
        <LoadingSpinner status="pending" />
      </div>
    );
  };

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    if (!event.api.getDisplayedRowCount()) gridRef.current?.api.showNoRowsOverlay();
    else gridRef.current?.api.hideOverlay();
  }, []);

  function onPropertyStateChange(e: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const searchStr = typeof e === 'string' ? e : e.target.value;
    if (propertyStates.some((x) => x.value === searchStr)) {
      setPropertyState(searchStr);
      gridRef.current?.api.setFilterModel(null);
    }
  }

  const colDefs: ColDef[] = [
    {
      field: 'clientType',
      headerName: 'Type',
      tooltipField: 'clientType',
      width: 160,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span ref="eFilter" data-testid="AvlListTypeIcon" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                        <span ref="eSortOrder" data-testid="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" data-testid="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                        <span ref="eSortDesc" data-testid="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                        <span ref="eSortNone" data-testid="AvlListTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                    </div>
                </div>`;
        },
      },
    },
    {
      field: 'agentName',
      width: 300,
      headerName: 'Agent Name',
      tooltipField: 'agentName',
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListAgentNameSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'accountNumber',
      headerName: 'Account #',
      tooltipField: 'accountNumber',
      width: 150,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListAccountNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'underwriterName',
      headerName: 'Underwriter',
      tooltipField: 'underwriterName',
      width: 300,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListUnderwriterSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'agentAddress',
      headerName: 'Office Address',
      tooltipField: 'agentAddress',
      width: 300,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListOfficeAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'city',
      headerName: 'City',
      tooltipField: 'city',
      width: 185,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                        <span ref="eSortOrder" data-testid="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" data-testid="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                        <span ref="eSortDesc" data-testid="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                        <span ref="eSortNone" data-testid="AvlListCitySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                    </div>
                </div>`;
        },
      },
    },
    {
      field: 'state',
      headerName: 'State',
      tooltipField: 'state',
      width: 105,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListStateSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      tooltipField: 'zip',
      width: 125,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="AvlListZipCodeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      pinned: 'right',
      cellClass: 'center-align-column jacket-actions-cell',
      cellRenderer: 'avlAction',
      cellRendererParams: (row: ValueFormatterParams) => ({
        agentAddress: [row.data.agentAddress, row.data.city, row.data.state, row.data.zip].join(', ') + ' ',
        clientTypeId: row.data.clientTypeId,
        propertyState: propertyState,
        setActionPendingFlag: setActionPendingFlag,
        isActionPending: isActionPending,
      }),
      width: 120,
      minWidth: 120,
      filter: false,
      floatingFilter: false,
      headerComponentParams: {
        templateFunction: () => {
          return `<div class="ag-cell-label-container" role="presentation">
                      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                          <span ref="eSortOrder" data-testid="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                          <span ref="eSortAsc" data-testid="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                          <span ref="eSortDesc" data-testid="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                          <span ref="eSortNone" data-testid="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                      </div>
                  </div>`;
        },
      },
    },
  ];

  return (
    <ContentContainer fullWidth>
      <Grid className={classes.paper}>
        <PageHeader
          title="Agency Verification Letters"
          subtitle="Agency Verification Letters formally confirm that a title agency is authorized by a particular underwriter to generate title insurance policies within a specific area. They do not provide any closing protection coverage. Easily access and download verification letters for compliance and agency validation."
          menuItems={[
            {
              label: 'Knowledge Base',
              link: KBLinks.avl,
            },
          ]}
          disableMargin="x"
        />
        {propertyStates?.length > 0 && (
          <div className={`${classes.row} dd-container`}>
            <StateSelectField
              label="Property State"
              required={true}
              data-testid="qaPropertyState"
              name="State"
              id="State"
              shouldDisableClearable={true}
              stateOptions={propertyStates.map((state) => ({ ...state, abbreviation: state.value }))}
              value={propertyState}
              onChange={onPropertyStateChange}
            />
          </div>
        )}
        {agents?.result?.length === 0 ? (
          <h3>Error in retrieving Agents - No Active Office</h3>
        ) : (
          <>
            <LoadingSpinner status={isActionPending ? 'pending' : 'success'} variant="linear" />
            <div className="grid-summary">
              <div className="avl-files-list-grid">
                <div id="avl-grid" className={`ag-theme-alpine table-grid ${classes.tableStyles}`}>
                  <DataTable
                    ref={gridRef}
                    rowData={rows}
                    columnDefs={colDefs}
                    components={{
                      avlAction: AvlAction,
                      customOverlayLoading: CustomOverlayLoading,
                      customFloatingFilter: FloatingFilterComponent,
                    }}
                    loadingOverlayComponent={'customOverlayLoading'}
                    defaultColDef={{
                      cellClass: 'left-align-column',
                      headerClass: 'left-align-header',
                      sortable: true,
                      filter: 'agTextColumnFilter',
                      floatingFilter: true,
                      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                      suppressMenu: true,
                      minWidth: 100,
                      resizable: true,
                      suppressSizeToFit: false,
                    }}
                    onFilterChanged={onFilterChanged}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Grid>
    </ContentContainer>
  );
};

export default AvlList;
