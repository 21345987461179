import Dashboard from 'features/orderManagement/Dashboard';
import SelectFiles from 'features/orderManagement/SelectFiles';
import { useLocation } from 'react-router-dom';

const OrderManagementContent = () => {
  const location = useLocation();
  return location.state ? <SelectFiles /> : <Dashboard />;
};

export default OrderManagementContent;
